import React, { useState } from 'react';
import {Nav, OverlayTrigger, Tooltip} from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Icon from '@/components/elements/Icon';

const SidebarMenuItem = ({ path, icon, label, subItems = [] }) => {
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const theme = useSelector((state) => state.theme);
    const isCompact = theme.layout === 'compact';
    const hasSubItems = subItems && subItems.length > 0;

    const isActive = location.pathname === path ||
        (hasSubItems && subItems.some(item => location.pathname === item.path));

    const handleClick = (e) => {
        if (hasSubItems) {
            e.preventDefault();
            setIsOpen(!isOpen);
        }
    };

    const renderIcon = () => (
        <div className="menu-icon">
            <Icon name={icon} />
        </div>
    );

    if (hasSubItems) {
        return (
            <Nav.Item className="sidebar-item">
                <Nav.Link
                    className={`menu-link ${isActive ? "active" : ""}`}
                    onClick={handleClick}
                    title={isCompact ? label : undefined}
                >
                    {renderIcon()}
                    {!isCompact && (
                        <>
                            <span className="menu-text">{label}</span>
                            <Icon
                                name={isOpen ? "chevron-down" : "chevron-right"}
                                className="menu-arrow"
                            />
                        </>
                    )}
                </Nav.Link>
                {(isOpen || isCompact) && (
                    <div className="submenu">
                        <Nav className="">
                            {subItems.map((item, index) => (
                                <Nav.Item key={index}>
                                    <Nav.Link
                                        as={Link}
                                        to={item.path}
                                        className={`submenu-link ${location.pathname === item.path ? "active" : ""}`}
                                        title={isCompact ? item.label : undefined}
                                    >
                                        <Icon name={item.icon} className="menu-icon" />
                                        {!isCompact && <span className="menu-text">{item.label}</span>}
                                    </Nav.Link>
                                </Nav.Item>
                            ))}
                        </Nav>
                    </div>
                )}
            </Nav.Item>
        );
    }

    return (
        <Nav.Item className="sidebar-item">
            <Nav.Link
                as={Link}
                to={path}
                className={`menu-link ${isActive ? "active" : ""}`}
                title={isCompact ? label : undefined}
            >
                {renderIcon()}
                {!isCompact &&
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>{label}</Tooltip>}
                >

                <span className="menu-text">{label}</span>
                </OverlayTrigger>
                }
            </Nav.Link>
        </Nav.Item>
    );
};

export default SidebarMenuItem;



