import { createSlice } from '@reduxjs/toolkit';

const LOCAL_STORAGE_KEY = 'themeConfig';

// Constantes de colores y configuraciones
const THEME_COLORS = {
    primary: {
        blue: '#60A5FA',
        indigo: '#818CF8',
        purple: '#A78BFA',
        pink: '#F472B6',
        red: '#F87171'
    },
    secondary: {
        gray100: '#F3F4F6',
        gray200: '#E5E7EB',
        gray800: '#1F2937',
        gray900: '#111827'
    }
};

const BACKGROUNDS = {
    images: [
        { value: '/assets/img/background/space/page.jpg', label: 'Space' },
        { value: '/assets/img/background/dust/page.jpg', label: 'Dust' },
        { value: '/assets/img/background/carbon/page.jpg', label: 'Carbon' },
        { value: '/assets/img/background/noise/page.jpg', label: 'Noise' },
        { value: '/assets/img/background/grunge/page.jpg', label: 'Grunge' },



        { value: '/assets/img/background/deg/deg-1.png', label: 'Degradado 1' },
        { value: '/assets/img/background/deg/deg-2.png', label: 'Degradado 2' },
        { value: '/assets/img/background/deg/deg-3.png', label: 'Degradado 3' },
        { value: '/assets/img/background/deg/deg-4.png', label: 'Degradado 4' },
        { value: '/assets/img/background/deg/deg-5.png', label: 'Degradado 5' },

        { value: '/assets/img/background/tech/tech-1.jpg', label: 'Tecnologia 1' },
        { value: '/assets/img/background/tech/tech-2.jpg', label: 'Tecnologia 2' },
        { value: '/assets/img/background/tech/tech-3.jpg', label: 'Tecnologia 3' },
        { value: '/assets/img/background/tech/tech-4.jpg', label: 'Tecnologia 4' },

        { value: '/assets/img/background/tech/technology-1.jpg', label: 'Tecnologia 1' },
        { value: '/assets/img/background/tech/technology-2.jpg', label: 'Tecnologia 2' },
        { value: '/assets/img/background/tech/technology-3.jpg', label: 'Tecnologia 3' },
        { value: '/assets/img/background/tech/technology-4.jpg', label: 'Tecnologia 4' },

    ],
    gradients: [
        { value: 'linear-gradient(to right, #1a1a1a, #434343)', label: 'Dark Gradient' },
        { value: 'linear-gradient(45deg, #1e272e, #2C3A47)', label: 'Deep Ocean' },
        { value: 'linear-gradient(to right, #0f0c29, #302b63, #24243e)', label: 'Royal' },
        { value: 'linear-gradient(60deg, #29323c 0%, #485563 100%)', label: 'Steel Gray' }
    ],
    solid: [
        { value: '#1a1a1a', label: 'Pure Dark' },
        { value: '#2d3436', label: 'Dracula' },
        { value: '#2f3640', label: 'Deep Asphalt' },
        { value: '#2C3A47', label: 'Navy Dark' }
    ]
};

const DEFAULT_STATE = {
    mode: 'dark',
    primaryColor: THEME_COLORS.primary.blue,
    secondaryColor: THEME_COLORS.secondary.gray800,
    background: {
        type: 'image',
        value: BACKGROUNDS.images[15].value,
        opacity: 0.50
    },
    cardsBackground: {
        type: 'color',
        value: 'rgba(30, 41, 59, 0.95)',
        opacity: 0.95,
        blur: 0
    },
    cardsOpacity: 0.95,
    cardStyle: 'default',
    layout: 'default'
};

const loadState = () => {
    try {
        const serializedState = localStorage.getItem(LOCAL_STORAGE_KEY);
        return serializedState ? { ...DEFAULT_STATE, ...JSON.parse(serializedState) } : DEFAULT_STATE;
    } catch {
        return DEFAULT_STATE;
    }
};

const updateBodyAttributes = (state) => {
    document.body.setAttribute('data-theme', state.mode);
    document.body.setAttribute('data-layout', state.layout);
    document.body.setAttribute('data-card-style', state.cardStyle);
    document.documentElement.style.setProperty('--card-opacity', state.cardsOpacity);
};

const saveState = (state) => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
    updateBodyAttributes(state);
};

export const themeSlice = createSlice({
    name: 'theme',
    initialState: loadState(),
    reducers: {
        toggleThemeMode: (state) => {
            state.mode = state.mode === 'dark' ? 'light' : 'dark';
            saveState(state);
        },
        updatePrimaryColor: (state, action) => {
            state.primaryColor = action.payload;
            saveState(state);
        },
        updateSecondaryColor: (state, action) => {
            state.secondaryColor = action.payload;
            saveState(state);
        },
        updateBackground: (state, action) => {
            state.background = { ...state.background, ...action.payload };
            saveState(state);
        },
        updateCardsBackground: (state, action) => {
            state.cardsBackground = { ...state.cardsBackground, ...action.payload };
            saveState(state);
        },
        updateCardsOpacity: (state, action) => {
            state.cardsOpacity = action.payload;
            saveState(state);
        },
        updateCardStyle: (state, action) => {
            state.cardStyle = action.payload;
            saveState(state);
        },
        updateLayout: (state, action) => {
            state.layout = action.payload;
            saveState(state);
        },
        resetTheme: (state) => {
            Object.assign(state, DEFAULT_STATE);
            saveState(state);
        }
    }
});

export const {
    toggleThemeMode,
    updatePrimaryColor,
    updateSecondaryColor,
    updateBackground,
    updateCardsBackground,
    updateCardsOpacity,
    updateCardStyle,
    updateLayout,
    resetTheme
} = themeSlice.actions;

export { THEME_COLORS, BACKGROUNDS };
export default themeSlice.reducer;