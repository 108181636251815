import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button, InputGroup } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
    Person,
    Envelope,
    Lock,
    Eye,
    EyeSlash,
    CheckSquare,
    ExclamationTriangle,
    ExclamationCircle
} from 'react-bootstrap-icons';

const ValidatedForm = () => {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const ValidationSchema = Yup.object().shape({
        username: Yup.string()
            .min(3, t('validation.username.min'))
            .max(50, t('validation.username.max'))
            .required(t('validation.username.required')),
        email: Yup.string()
            .email(t('validation.email.invalid'))
            .required(t('validation.email.required')),
        password: Yup.string()
            .min(8, t('validation.password.min'))
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
                t('validation.password.pattern')
            )
            .required(t('validation.password.required')),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], t('validation.confirmPassword.match'))
            .required(t('validation.confirmPassword.required')),
        terms: Yup.boolean()
            .oneOf([true], t('validation.terms.required'))
    });

    return (
        <Container fluid className="py-4">
            <Row className="g-4">
                <Col md={12}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('forms.validation.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Formik
                                initialValues={{
                                    username: '',
                                    email: '',
                                    password: '',
                                    confirmPassword: '',
                                    terms: false
                                }}
                                validationSchema={ValidationSchema}
                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        console.log(values);
                                        setSubmitting(false);
                                    }, 400);
                                }}
                            >
                                {({ handleSubmit, handleChange, values, touched, errors, isSubmitting }) => (
                                    <Form noValidate onSubmit={handleSubmit}>


                                        <Row className="g-3">
                                            <Col md={12} className="mb-3">
                                                {Object.keys(errors).length > 0 && touched && (
                                                    <div className="alert alert-danger">
                                                        <h6 className="alert-heading mb-1">
                                                            <ExclamationTriangle className="me-2" />
                                                            Por favor corrige los siguientes errores:
                                                        </h6>
                                                        <ul className="list-unstyled mb-0 mt-2">
                                                            {Object.entries(errors).map(([key, value]) => (
                                                                <li key={key} className="d-flex align-items-center mb-1">
                                                                    <ExclamationCircle className="me-2 text-danger" size={14} />
                                                                    <span>{value}</span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </Col>

                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>{t('forms.validation.username')}</Form.Label>
                                                    <InputGroup hasValidation>
                                                        <InputGroup.Text>
                                                            <Person />
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            type="text"
                                                            name="username"
                                                            value={values.username}
                                                            onChange={handleChange}
                                                            isInvalid={touched.username && errors.username}
                                                            placeholder={t('forms.validation.usernamePlaceholder')}
                                                        />

                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>

                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>{t('forms.validation.email')}</Form.Label>
                                                    <InputGroup hasValidation>
                                                        <InputGroup.Text>
                                                            <Envelope />
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            type="email"
                                                            name="email"
                                                            value={values.email}
                                                            onChange={handleChange}
                                                            isInvalid={touched.email && errors.email}
                                                            placeholder={t('forms.validation.emailPlaceholder')}
                                                        />

                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>

                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>{t('forms.validation.password')}</Form.Label>
                                                    <InputGroup hasValidation>
                                                        <InputGroup.Text>
                                                            <Lock />
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            type={showPassword ? "text" : "password"}
                                                            name="password"
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            isInvalid={touched.password && errors.password}
                                                            placeholder={t('forms.validation.passwordPlaceholder')}
                                                        />
                                                        <Button
                                                            variant="outline-secondary"
                                                            onClick={() => setShowPassword(!showPassword)}
                                                        >
                                                            {showPassword ? <EyeSlash /> : <Eye />}
                                                        </Button>

                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>

                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Label>{t('forms.validation.confirmPassword')}</Form.Label>
                                                    <InputGroup hasValidation>
                                                        <InputGroup.Text>
                                                            <Lock />
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            type={showConfirmPassword ? "text" : "password"}
                                                            name="confirmPassword"
                                                            value={values.confirmPassword}
                                                            onChange={handleChange}
                                                            isInvalid={touched.confirmPassword && errors.confirmPassword}
                                                            placeholder={t('forms.validation.confirmPasswordPlaceholder')}
                                                        />
                                                        <Button
                                                            variant="outline-secondary"
                                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                        >
                                                            {showConfirmPassword ? <EyeSlash /> : <Eye />}
                                                        </Button>

                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>

                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Check
                                                        type="checkbox"
                                                        id="terms"
                                                        name="terms"
                                                        label={t('forms.validation.terms')}
                                                        onChange={handleChange}
                                                        isInvalid={touched.terms && errors.terms}
                                                        feedback={errors.terms}
                                                        feedbackType="invalid"
                                                    />
                                                </Form.Group>
                                            </Col>

                                            <Col md={12}>
                                                <Button
                                                    type="submit"
                                                    variant="primary"
                                                    disabled={isSubmitting}
                                                >
                                                    {isSubmitting ?
                                                        t('forms.validation.submitting') :
                                                        t('forms.validation.submit')
                                                    }
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default ValidatedForm;