import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ThemeControl from "@components/layouts/auth/components/ThemeControl";

function AuthLayout() {
    const { t, i18n } = useTranslation();
    const [quote, setQuote] = useState(null);
    const navigate = useNavigate();

    // Obtener la configuración del tema del store
    const theme = useSelector((state) => state.theme);

    // Efectos para cargar la cita
    useEffect(() => {
        const quoteKeys = Object.keys(t('quotes', { returnObjects: true }) || {});
        if (quoteKeys.length > 0) {
            const randomKey = quoteKeys[Math.floor(Math.random() * quoteKeys.length)];
            const selectedQuote = t(`quotes.${randomKey}`, { returnObjects: true });
            setQuote(selectedQuote);
        }
    }, [i18n.language, t]);

    // Estilos dinámicos basados en la configuración del tema
    const layoutStyles = {
        background: theme.background.type === 'image'
            ? `url(${theme.background.value})`
            : theme.background.value,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
    };

    const overlayStyles = {
        backgroundColor: `rgba(0, 0, 0, ${theme.background.opacity})`,
        backdropFilter: theme.cardsBackground.blur ? `blur(${theme.cardsBackground.blur}px)` : undefined
    };

    const cardStyles = {
        backgroundColor: theme.cardsBackground.value,
        opacity: theme.cardsOpacity
    };

    const darkPanelStyles = {
        backgroundColor: theme.mode === 'dark' ? theme.secondaryColor : 'rgba(0, 0, 0, 0.9)',
        borderRight: `1px solid ${theme.mode === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'}`
    };

    return (
        <Container fluid className="min-vh-100 p-0" style={layoutStyles}>
            {/* Overlay de fondo */}
            <div
                className="position-fixed w-100 h-100"
                style={overlayStyles}
            />

            <div className="min-vh-100 position-relative" style={{ zIndex: 2 }}>
                <Row className="g-0 min-vh-100">


                    {/* Panel Derecho - Formulario */}
                    <Col xs={12} lg={12} xl={12}>
                        <div className="d-flex min-vh-100 position-relative p-4">
                            {/* Logo Móvil */}
                            <div className="position-absolute top-0 start-50 translate-middle-x pt-5 mt-4 d-lg-none">
                                <Image
                                    src="/assets/logo/secundary_sm.png"
                                    alt="Logo"
                                    className="h-auto"
                                    style={{
                                        maxWidth: '120px',
                                        filter: `drop-shadow(0 0 10px rgba(0,0,0,${theme.mode === 'dark' ? '0.2' : '0.1'}))`
                                    }}
                                />
                            </div>

                            {/* Contenedor del Formulario */}
                            <div className="w-100 m-auto" style={{ maxWidth: '450px' }}>
                                <Outlet />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <ThemeControl/>
        </Container>
    );
}

export default AuthLayout;