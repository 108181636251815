import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const FavoriteApps = ({ includeDescription = false, colSize = 'col-md-4', textAlign = true }) => {
    const apps = [
        {
            id: 1,
            name: "Figma",
            icon: "https://img.icons8.com/?size=96&id=zfHRZ6i1Wg0U&format=png",
            description: 'Genera plantillas e integralas a xhof.ia para tu proyecto',
            path: '/apps/figma'
        },
        {
            id: 2,
            name: "Maquetador",
            icon: "https://img.icons8.com/?size=96&id=33435&format=png",
            description: 'Genera estructuras de diferentes componentes',
            path: '/apps/maquetador'
        },
        {
            id: 3,
            name: "Colorimetria",
            icon: "https://img.icons8.com/?size=96&id=hylX6EPAOYOQ&format=png",
            description: 'Describre herramientas sobre colorimetria desde generadores de paletas hasta convinadores',
            path: '/apps/colorimetria'
        },
        {
            id: 4,
            name: "Diagrams",
            description: 'Genera diagramas de flujo',
            icon: "https://img.icons8.com/?size=96&id=KJaWClVI1AMv&format=png",
            path: '/apps/diagrams'
        }
    ];

    return (
        <Row>
            {apps.map((app) => (
                <Col key={app.id} className={`${colSize} bg-soft-dark ${textAlign ? 'p-1' : 'p-0'}`}>
                    <div className="border-1 border-dark h-100">
                        <div className={`m-1 ${textAlign ? 'p-3' : 'p-2 text-center'}`}>
                            <Link
                                to={app.path}
                                className="text-decoration-none text-white d-flex flex-column h-100"
                            >
                                <div className="d-flex align-items-center mb-2">
                                    <img
                                        src={app.icon}
                                        alt={app.name}
                                        className="rounded-3"
                                        width={30}
                                        height={30}
                                    />
                                    <span className={`small text-white fw-bold ${textAlign ? 'ms-2' : ''}`}>
                                        {app.name}
                                    </span>
                                </div>

                                {includeDescription && (
                                    <p className="fs-6 text-muted small mb-0 flex-grow-1">
                                        {app.description}
                                    </p>
                                )}
                            </Link>
                        </div>
                    </div>
                </Col>
            ))}
        </Row>
    );
};


export default FavoriteApps;