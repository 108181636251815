import React from 'react';

const InitialAvatar = ({ name, size = 160 }) => {
    // Mapa de colores usando colores Bootstrap
    const colorMap = {
        'A-E': '#0d6efd', // Bootstrap primary blue
        'F-J': '#198754', // Bootstrap success green
        'K-O': '#6f42c1', // Bootstrap purple
        'P-T': '#ffc107', // Bootstrap warning yellow
        'U-Z': '#dc3545'  // Bootstrap danger red
    };

    // Función para obtener el color basado en la inicial
    const getColorForInitial = (initial) => {
        const upperInitial = initial.toUpperCase();
        const range = Object.keys(colorMap).find(range => {
            const [start, end] = range.split('-');
            return upperInitial >= start && upperInitial <= end;
        });
        return colorMap[range] || '#6c757d'; // Color gris por defecto
    };

    // Obtener la inicial y el color
    const initial = name ? name.charAt(0).toUpperCase() : '?';
    const bgColor = getColorForInitial(initial);

    // Determinar el color del texto basado en el color de fondo
    const textColor = bgColor === '#ffc107' ? '#000' : '#fff';

    return (
        <div
            className="img-fluid img-thumbnail"
            style={{
                width:size,
                height: size,
                backgroundColor: bgColor,
                color: textColor,
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: `${size/2}px`,
                fontWeight: 'bold',
                userSelect: 'none',
                transition: 'all 0.3s ease'
            }}
        >
            {initial}
        </div>
    );
};

export default InitialAvatar;