import React, { useState } from 'react';
import { Card, Row, Col, ProgressBar, Badge, ListGroup, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    ScatterChart, Scatter, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis,
    Radar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
    Treemap, Cell
} from 'recharts';
import {
    Kanban, ClockHistory, PeopleFill, Calendar2Check,
    CheckCircleFill, XCircleFill, ExclamationTriangleFill
} from 'react-bootstrap-icons';

const ProjectManagement = () => {
    const { t } = useTranslation();
    const [selectedProject, setSelectedProject] = useState('all');

    // Datos de rendimiento del equipo para el gráfico de radar
    const teamPerformanceData = [
        { subject: 'Productivity', A: 120, B: 110, fullMark: 150 },
        { subject: 'Collaboration', A: 98, B: 130, fullMark: 150 },
        { subject: 'Quality', A: 86, B: 130, fullMark: 150 },
        { subject: 'Delivery', A: 99, B: 100, fullMark: 150 },
        { subject: 'Innovation', A: 85, B: 90, fullMark: 150 },
        { subject: 'Planning', A: 65, B: 85, fullMark: 150 },
    ];

    // Datos para el gráfico de dispersión de tareas
    const taskScatterData = [
        { x: 'Research', y: 8, z: 80 },
        { x: 'Design', y: 12, z: 100 },
        { x: 'Development', y: 16, z: 120 },
        { x: 'Testing', y: 6, z: 60 },
        { x: 'Deployment', y: 4, z: 40 }
    ];

    // Datos para el treemap de recursos
    const resourceAllocationData = [
        {
            name: 'Frontend',
            children: [
                { name: 'UI Development', size: 3000 },
                { name: 'Integration', size: 2000 },
                { name: 'Testing', size: 1000 }
            ]
        },
        {
            name: 'Backend',
            children: [
                { name: 'API Development', size: 4000 },
                { name: 'Database', size: 2500 },
                { name: 'Security', size: 1500 }
            ]
        },
        {
            name: 'DevOps',
            children: [
                { name: 'Deployment', size: 2000 },
                { name: 'Monitoring', size: 1000 }
            ]
        }
    ];

    return (
        <div className="p-4">
            <Row className="g-4">
                {/* Sprint Progress Tracker */}
                <Col lg={6}>
                    <Card>
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <h5 className="mb-0">Sprint Progress</h5>
                            <Badge bg="primary">Sprint #24</Badge>
                        </Card.Header>
                        <Card.Body>
                            <div className="mb-4">
                                <div className="d-flex justify-content-between mb-2">
                                    <span>Overall Progress</span>
                                    <span>72%</span>
                                </div>
                                <ProgressBar>
                                    <ProgressBar variant="success" now={45} key={1} />
                                    <ProgressBar variant="warning" now={27} key={2} />
                                    <ProgressBar variant="danger" now={28} key={3} />
                                </ProgressBar>
                            </div>
                            <ListGroup variant="flush">
                                {[
                                    { status: 'Completed', count: 18, icon: CheckCircleFill, color: 'success' },
                                    { status: 'In Progress', count: 12, icon: ClockHistory, color: 'warning' },
                                    { status: 'Blocked', count: 5, icon: ExclamationTriangleFill, color: 'danger' }
                                ].map((item, index) => (
                                    <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                            <item.icon className={`text-${item.color} me-2`} />
                                            <span>{item.status}</span>
                                        </div>
                                        <Badge bg={item.color} pill>{item.count}</Badge>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Team Performance Radar */}
                <Col lg={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">Team Performance Metrics</h5>
                        </Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width="100%" height={350}>
                                <RadarChart data={teamPerformanceData}>
                                    <PolarGrid />
                                    <PolarAngleAxis dataKey="subject" />
                                    <PolarRadiusAxis angle={30} domain={[0, 150]} />
                                    <Radar name="Current Sprint" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
                                    <Radar name="Previous Sprint" dataKey="B" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
                                    <Legend />
                                    <Tooltip />
                                </RadarChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Task Distribution */}
                <Col lg={8}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">Task Complexity vs Time</h5>
                        </Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width="100%" height={350}>
                                <ScatterChart margin={{ top: 20, right: 20, bottom: 20, left: 20 }}>
                                    <CartesianGrid />
                                    <XAxis type="category" dataKey="x" name="Category" />
                                    <YAxis type="number" dataKey="y" name="Time (days)" />
                                    <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                                    <Scatter name="Tasks" data={taskScatterData} fill="#8884d8">
                                        {taskScatterData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={`hsl(${index * 45}, 70%, 60%)`} />
                                        ))}
                                    </Scatter>
                                </ScatterChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Resource Allocation */}
                <Col lg={4}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">Resource Allocation</h5>
                        </Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width="100%" height={350}>
                                <Treemap
                                    data={resourceAllocationData}
                                    dataKey="size"
                                    stroke="#fff"
                                    fill="#8884d8"
                                    content={<CustomizedContent />}
                                />
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Milestone Timeline */}
                <Col lg={6}>
                    <Card>
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <h5 className="mb-0">Upcoming Milestones</h5>
                            <Button variant="outline-primary" size="sm">View All</Button>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <ListGroup variant="flush">
                                {[
                                    { name: 'Beta Release', date: '2024-03-15', status: 'On Track', color: 'success' },
                                    { name: 'User Testing', date: '2024-03-20', status: 'At Risk', color: 'warning' },
                                    { name: 'Final Review', date: '2024-03-25', status: 'Delayed', color: 'danger' },
                                    { name: 'Production Deploy', date: '2024-03-30', status: 'On Track', color: 'success' }
                                ].map((milestone, index) => (
                                    <ListGroup.Item key={index} className="py-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <h6 className="mb-1">{milestone.name}</h6>
                                                <small className="text-muted">
                                                    <Calendar2Check className="me-1" />
                                                    {milestone.date}
                                                </small>
                                            </div>
                                            <Badge bg={milestone.color}>{milestone.status}</Badge>
                                        </div>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Team Workload */}
                <Col lg={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">Team Workload</h5>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <ListGroup variant="flush">
                                {[
                                    { member: 'John Doe', role: 'Frontend Dev', tasks: 8, capacity: 85 },
                                    { member: 'Jane Smith', role: 'Backend Dev', tasks: 6, capacity: 65 },
                                    { member: 'Mike Johnson', role: 'Designer', tasks: 4, capacity: 45 },
                                    { member: 'Sarah Wilson', role: 'QA Engineer', tasks: 7, capacity: 75 }
                                ].map((member, index) => (
                                    <ListGroup.Item key={index} className="py-3">
                                        <div className="d-flex justify-content-between align-items-center mb-2">
                                            <div>
                                                <h6 className="mb-1">{member.member}</h6>
                                                <small className="text-muted">{member.role}</small>
                                            </div>
                                            <Badge bg="info" pill>{member.tasks} tasks</Badge>
                                        </div>
                                        <ProgressBar
                                            now={member.capacity}
                                            variant={member.capacity > 80 ? 'danger' : member.capacity > 60 ? 'warning' : 'success'}
                                            style={{ height: '6px' }}
                                        />
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};
const CustomizedContent = (props) => {
    const { x, y, width, height, depth, name } = props;
    const colors = ['#8884d8', '#83a6ed', '#8dd1e1', '#82ca9d', '#a4de6c', '#d0ed57'];

    return (
        <g>
            <rect
                x={x}
                y={y}
                width={width}
                height={height}
                style={{
                    fill: depth < 2 ? colors[Math.floor(Math.random() * 6)] : '#ffffff',
                    stroke: '#fff',
                    strokeWidth: 2 / (depth + 1e-10),
                    strokeOpacity: 1 / (depth + 1e-10),
                }}
            />
            {depth === 1 && (
                <text
                    x={x + width / 2}
                    y={y + height / 2}
                    textAnchor="middle"
                    fill="#fff"
                    fontSize={14}
                >
                    {name}
                </text>
            )}
        </g>
    );
};

export default ProjectManagement;