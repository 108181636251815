import React, { useState, useRef } from 'react';
import { Container, Row, Col, Card, Button, Form, InputGroup, Dropdown, Badge } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import {
    Grid1x2,
    Grid3x3,
    FileEarmarkSpreadsheet,
    Funnel,
    Search,
    ArrowRepeat,
    Plus,
    ThreeDotsVertical,
    CheckCircleFill,
    XCircleFill,
    ClockFill
} from 'react-bootstrap-icons';

const DataTables = () => {
    const { t } = useTranslation();
    const gridRef = useRef();
    const [gridView, setGridView] = useState('comfortable');
    const [quickFilter, setQuickFilter] = useState('');

    const defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
        suppressMenu: true,
        floatingFilter: true
    };

    const columnDefs = [
        {
            headerCheckboxSelection: true,
            checkboxSelection: true,
            width: 48,
            pinned: 'left'
        },
        {
            field: 'orderNo',
            headerName: t('datatables.orderNo'),
            pinned: 'left',
            width: 130,
            cellRenderer: params => {
                return (
                    <div className="fw-semibold text-primary">
                        #{params.value}
                    </div>
                );
            }
        },
        {
            field: 'customer',
            headerName: t('datatables.customer'),
            flex: 1,
            cellRenderer: params => {
                return (
                    <div className="d-flex align-items-center">
                        <img
                            src={`/api/placeholder/32/32`}
                            className="rounded-circle me-2"
                            width="32"
                            height="32"
                            alt={params.value}
                        />
                        <div>
                            <div className="fw-semibold">{params.value}</div>
                            <div className="text-muted small">{params.data.email}</div>
                        </div>
                    </div>
                );
            }
        },
        {
            field: 'amount',
            headerName: t('datatables.amount'),
            width: 130,
            cellRenderer: params => {
                return (
                    <div className="fw-semibold">
                        ${params.value.toLocaleString()}
                    </div>
                );
            }
        },
        {
            field: 'status',
            headerName: t('datatables.status'),
            width: 140,
            cellRenderer: params => {
                const statusConfig = {
                    completed: {
                        icon: CheckCircleFill,
                        variant: 'success'
                    },
                    pending: {
                        icon: ClockFill,
                        variant: 'warning'
                    },
                    failed: {
                        icon: XCircleFill,
                        variant: 'danger'
                    }
                };

                const config = statusConfig[params.value.toLowerCase()];
                const Icon = config.icon;

                return (
                    <Badge
                        bg={config.variant}
                        className="d-inline-flex align-items-center gap-1 px-2 py-1"
                    >
                        <Icon size={12} />
                        {params.value}
                    </Badge>
                );
            }
        },
        {
            field: 'date',
            headerName: t('datatables.date'),
            width: 130
        },
        {
            field: 'actions',
            headerName: '',
            width: 70,
            cellRenderer: (params) => {
                const ActionCell = () => {
                    return (
                        <div className="actions-cell">
                            <Dropdown>
                                <Dropdown.Toggle variant="link" className="text-secondary p-0">
                                    <ThreeDotsVertical />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item>
                                        {t('datatables.dropdown.view')}
                                    </Dropdown.Item>
                                    <Dropdown.Item>
                                        {t('datatables.dropdown.edit')}
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                    <Dropdown.Item className="text-danger">
                                        {t('datatables.dropdown.delete')}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    );
                };

                return <ActionCell />;
            },
            sortable: false,
            filter: false,
            suppressMovable: true
        }
    ];

    const mockData = Array.from({ length: 100 }, (_, i) => ({
        orderNo: (1000 + i).toString(),
        customer: `Cliente ${i + 1}`,
        email: `cliente${i + 1}@ejemplo.com`,
        amount: Math.floor(Math.random() * 10000),
        status: ['Completed', 'Pending', 'Failed'][Math.floor(Math.random() * 3)],
        date: new Date(Date.now() - Math.floor(Math.random() * 10000000000))
            .toISOString()
            .split('T')[0]
    }));

    const onGridReady = (params) => {
        params.api.sizeColumnsToFit();
    };

    const onQuickFilterChanged = (e) => {
        const value = e.target.value;
        setQuickFilter(value);
        gridRef.current.api.setQuickFilter(value);
    };

    return (
        <Container fluid className="py-4">
            {/* Summary Cards */}
            <Row className="g-3 mb-4">
                <Col md={3}>
                    <Card className="border-0 shadow-sm">
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="text-muted">{t('datatables.totalOrders')}</div>
                                    <h3 className="mb-0">1,234</h3>
                                </div>
                                <div className="bg-primary bg-opacity-10 rounded-circle p-3">
                                    <Grid3x3 className="" size={24} />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="border-0 shadow-sm">
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="text-muted">{t('datatables.completed')}</div>
                                    <h3 className="mb-0">845</h3>
                                </div>
                                <div className="bg-success bg-opacity-10 rounded-circle p-3">
                                    <CheckCircleFill className="" size={24} />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="border-0 shadow-sm">
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="text-muted">{t('datatables.pending')}</div>
                                    <h3 className="mb-0">284</h3>
                                </div>
                                <div className="bg-warning bg-opacity-10 rounded-circle p-3">
                                    <ClockFill className="" size={24} />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={3}>
                    <Card className="border-0 shadow-sm">
                        <Card.Body>
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <div className="text-muted">{t('datatables.failed')}</div>
                                    <h3 className="mb-0">105</h3>
                                </div>
                                <div className="bg-danger bg-opacity-10 rounded-circle p-3">
                                    <XCircleFill className="" size={24} />
                                </div>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Main Table Card */}
            <Card className="border-0 shadow-sm">
                <Card.Header className="bg-transparent border-0 py-3">
                    <Row className="align-items-center">
                        <Col>
                            <h5 className="mb-0">{t('datatables.title')}</h5>
                        </Col>
                    </Row>
                </Card.Header>

                {/* Table Toolbar */}
                <div className="border-bottom px-4 py-3">
                    <Row className="align-items-center">
                        <Col lg={8} className="d-flex gap-2">
                            <InputGroup>
                                <InputGroup.Text className="bg-transparent">
                                    <Search />
                                </InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder={t('datatables.search')}
                                    value={quickFilter}
                                    onChange={onQuickFilterChanged}
                                />
                            </InputGroup>

                            <Button variant="outline-secondary">
                                <Funnel className="me-1" />
                                {t('datatables.filter')}
                            </Button>

                            <Button variant="outline-secondary">
                                <FileEarmarkSpreadsheet className="me-1" />
                                {t('datatables.export')}
                            </Button>
                        </Col>
                        <Col lg={4} className="d-flex justify-content-lg-end gap-2">
                            <div className="btn-group">
                                <Button
                                    variant={gridView === 'comfortable' ? 'primary' : 'outline-primary'}
                                    onClick={() => setGridView('comfortable')}
                                >
                                    <Grid1x2 />
                                </Button>
                                <Button
                                    variant={gridView === 'compact' ? 'primary' : 'outline-primary'}
                                    onClick={() => setGridView('compact')}
                                >
                                    <Grid3x3 />
                                </Button>
                            </div>

                            <Button variant="primary">
                                <Plus className="me-1" />
                                {t('datatables.newOrder')}
                            </Button>
                        </Col>
                    </Row>
                </div>

                <Card.Body className="p-0">
                    <div
                        className="ag-theme-alpine"
                        style={{
                            height: 600,
                            width: '100%'
                        }}
                    >
                        <AgGridReact
                            ref={gridRef}
                            columnDefs={columnDefs}
                            rowData={mockData}
                            defaultColDef={defaultColDef}
                            onGridReady={onGridReady}
                            pagination={true}
                            paginationPageSize={10}
                            rowSelection="multiple"
                            rowHeight={gridView === 'comfortable' ? 60 : 40}
                            headerHeight={48}
                            className="ag-theme-custom"
                        />
                    </div>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default DataTables;