import React, { useState } from 'react';
import { Button, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@/components/elements/Icon';
import {
    toggleThemeMode,
    updatePrimaryColor,
    updateBackground,
    THEME_COLORS,
    BACKGROUNDS
} from '@/store/slice/themeSlice';

// Secciones simplificadas para Auth
const THEME_SECTIONS = [
    {
        id: 'theme',
        label: 'Tema',
        icon: 'palette2',
        description: 'Color primario y modo'
    },
    {
        id: 'background',
        label: 'Background',
        icon: 'image',
        description: 'Fondos y patrones'
    }
];

const ThemeControl = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentSection, setCurrentSection] = useState(null);
    const [backgroundTab, setBackgroundTab] = useState('images');
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.theme);

    // Render de la sección de tema
    const renderThemeSection = () => (
        <div className="p-3">
            {/* Theme Mode Toggle */}
            <div className="mb-4">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <label className="form-label mb-0">Modo del Tema</label>
                    <Button
                        variant={theme.mode === 'dark' ? 'light' : 'dark'}
                        size="sm"
                        onClick={() => dispatch(toggleThemeMode())}
                    >
                        <Icon name={theme.mode === 'dark' ? 'sun' : 'moon'} className="me-2" />
                        {theme.mode === 'dark' ? 'Modo Claro' : 'Modo Oscuro'}
                    </Button>
                </div>
            </div>

            {/* Color Selection */}
            <div className="mb-4">
                <label className="form-label d-block mb-2">Color Primario</label>
                <div className="row g-2">
                    {Object.entries(THEME_COLORS.primary).map(([name, color]) => (
                        <div key={name} className="col-3">
                            <OverlayTrigger placement="top" overlay={<Tooltip>{name}</Tooltip>}>
                                <button
                                    className={`color-option rounded-circle border-0 ${
                                        theme.primaryColor === color ? 'active' : ''
                                    }`}
                                    style={{
                                        backgroundColor: color,
                                        width: '40px',
                                        height: '40px',
                                        cursor: 'pointer',
                                        position: 'relative'
                                    }}
                                    onClick={() => dispatch(updatePrimaryColor(color))}
                                >
                                    {theme.primaryColor === color && (
                                        <Icon
                                            name="check"
                                            className="text-white position-absolute"
                                            style={{
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)'
                                            }}
                                        />
                                    )}
                                </button>
                            </OverlayTrigger>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    // Render de la sección de background
    const renderBackgroundSection = () => {
        const renderBackgroundOptions = (options, type) => (
            <div className="row g-2">
                {options.map((option, index) => (
                    <div key={index} className="col-4">
                        <div
                            className={`theme-option-card ${theme.background.value === option.value ? 'active' : ''}`}
                            style={{
                                height: '60px',
                                cursor: 'pointer',
                                ...(type === 'image'
                                    ? {
                                        backgroundImage: `url(${option.value})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center'
                                    }
                                    : { background: option.value })
                            }}
                            onClick={() => dispatch(updateBackground({ type, value: option.value }))}
                            title={option.label}
                        >
                            {theme.background.value === option.value && (
                                <div className="position-absolute top-0 end-0 p-1">
                                    <Icon name="check-circle-fill" className="text-primary" />
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        );

        return (
            <div className="p-3">
                <div className="mb-3">
                    <div className="btn-group w-100 mb-3">
                        <Button
                            variant={backgroundTab === 'images' ? 'primary' : 'dark'}
                            onClick={() => setBackgroundTab('images')}
                        >
                            <Icon name="image" className="me-2" />
                            Imágenes
                        </Button>
                        <Button
                            variant={backgroundTab === 'gradients' ? 'primary' : 'dark'}
                            onClick={() => setBackgroundTab('gradients')}
                        >
                            <Icon name="brush" className="me-2" />
                            Degradados
                        </Button>
                        <Button
                            variant={backgroundTab === 'solid' ? 'primary' : 'dark'}
                            onClick={() => setBackgroundTab('solid')}
                        >
                            <Icon name="circle-fill" className="me-2" />
                            Sólidos
                        </Button>
                    </div>

                    {backgroundTab === 'images' && renderBackgroundOptions(BACKGROUNDS.images, 'image')}
                    {backgroundTab === 'gradients' && renderBackgroundOptions(BACKGROUNDS.gradients, 'gradient')}
                    {backgroundTab === 'solid' && renderBackgroundOptions(BACKGROUNDS.solid, 'solid')}
                </div>

                <Form.Group className="mb-3">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <Form.Label className="mb-0">Opacidad del Fondo</Form.Label>
                        <span className="text-muted small">
                            {Math.round(theme.background.opacity * 100)}%
                        </span>
                    </div>
                    <Form.Range
                        min={0}
                        max={1}
                        step={0.01}
                        value={theme.background.opacity}
                        onChange={(e) => dispatch(updateBackground({ opacity: parseFloat(e.target.value) }))}
                    />
                </Form.Group>
            </div>
        );
    };

    // Render del contenido principal
    const renderContent = () => {
        if (!currentSection) {
            return (
                <div className="p-3">
                    <h6 className="text-muted small mb-3">SELECCIONA UNA SECCIÓN</h6>
                    <div className="row g-2">
                        {THEME_SECTIONS.map((section) => (
                            <div key={section.id} className="col-12">
                                <Button
                                    variant="dark"
                                    className="w-100 text-start p-3 border-0 bg-opacity-25 hover-opacity-75"
                                    onClick={() => setCurrentSection(section.id)}
                                >
                                    <div className="d-flex align-items-center">
                                        <div className="theme-section-icon me-3">
                                            <Icon name={section.icon} size={24} />
                                        </div>
                                        <div>
                                            <h6 className="mb-1">{section.label}</h6>
                                            <small className="text-muted">{section.description}</small>
                                        </div>
                                        <Icon name="chevron-right" className="ms-auto" />
                                    </div>
                                </Button>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

        const currentSectionData = THEME_SECTIONS.find(s => s.id === currentSection);

        return (
            <>
                <div className="p-3 border-bottom border-secondary d-flex align-items-center">
                    <Button
                        variant="link"
                        className="text-white p-0 me-3"
                        onClick={() => setCurrentSection(null)}
                    >
                        <Icon name="arrow-left" size={20} />
                    </Button>
                    <h6 className="mb-0">{currentSectionData?.label}</h6>
                </div>

                {currentSection === 'theme' && renderThemeSection()}
                {currentSection === 'background' && renderBackgroundSection()}
            </>
        );
    };

    return (
        <div className="theme-control position-fixed bottom-0 end-0 mb-4 me-4" style={{ zIndex: 1050 }}>
            <Button
                variant="primary"
                className="rounded-circle p-3 shadow-lg d-flex align-items-center justify-content-center"
                onClick={() => setIsOpen(!isOpen)}
                style={{ width: '56px', height: '56px' }}
            >
                <Icon name={isOpen ? "x-lg" : "palette-fill"} size={24} />
            </Button>

            {isOpen && (
                <>
                    <div
                        className="position-absolute bottom-100 end-0 mb-3 bg-dark rounded-3 shadow-lg text-white"
                        style={{
                            width: '320px',
                            maxHeight: '80vh',
                            overflowY: 'auto'
                        }}
                    >
                        {renderContent()}
                    </div>
                    <div
                        className="position-fixed top-0 start-0 w-100 h-100"
                        style={{
                            backgroundColor: 'rgba(0,0,0,0.2)',
                            backdropFilter: 'blur(2px)',
                            zIndex: -1
                        }}
                        onClick={() => setIsOpen(false)}
                    />
                </>
            )}
        </div>
    );
};

export default ThemeControl;