import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Dropdown, Button, ListGroup, Badge } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';
import {
    Line,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LineChart,
    AreaChart,
    BarChart,
    Bar,
    PieChart,
    Pie,
    Cell
} from 'recharts';
import {
    CurrencyDollar,
    People,
    Cart,
    GraphUp,
    ArrowUp,
    ArrowDown,
    CheckCircle,
    ExclamationTriangle,
    XCircle,
    Clock
} from 'react-bootstrap-icons';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

function Analytics() {
    const { t } = useTranslation();
    const [period, setPeriod] = useState('weekly');
    const [salesData, setSalesData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [rowData, setRowData] = useState([]);

    const revenueData = [
        { name: 'Jan', value: 4000, target: 3800 },
        { name: 'Feb', value: 3000, target: 3200 },
        { name: 'Mar', value: 2000, target: 2400 },
        { name: 'Apr', value: 2780, target: 2600 },
        { name: 'May', value: 1890, target: 2000 },
        { name: 'Jun', value: 2390, target: 2200 },
        { name: 'Jul', value: 3490, target: 3000 },
        { name: 'Aug', value: 2490, target: 2800 },
        { name: 'Sep', value: 4000, target: 3600 },
        { name: 'Oct', value: 3890, target: 3400 },
        { name: 'Nov', value: 2780, target: 3000 },
        { name: 'Dec', value: 4890, target: 4200 }
    ];

    const visitsData = [
        { name: t('pages.panel.dashboard.analytics.days.mon'), visits: 3500, uniqueVisitors: 2300, bounceRate: 45 },
        { name: t('pages.panel.dashboard.analytics.days.tue'), visits: 4200, uniqueVisitors: 2800, bounceRate: 38 },
        { name: t('pages.panel.dashboard.analytics.days.wed'), visits: 4000, uniqueVisitors: 2650, bounceRate: 42 },
        { name: t('pages.panel.dashboard.analytics.days.thu'), visits: 4500, uniqueVisitors: 3000, bounceRate: 35 },
        { name: t('pages.panel.dashboard.analytics.days.fri'), visits: 4800, uniqueVisitors: 3200, bounceRate: 32 },
        { name: t('pages.panel.dashboard.analytics.days.sat'), visits: 3800, uniqueVisitors: 2500, bounceRate: 48 },
        { name: t('pages.panel.dashboard.analytics.days.sun'), visits: 3200, uniqueVisitors: 2100, bounceRate: 52 }
    ];

    const pieData = [
        {
            name: t('pages.panel.dashboard.analytics.traffic.desktop'),
            value: 400,
            color: '#0088FE'
        },
        {
            name: t('pages.panel.dashboard.analytics.traffic.mobile'),
            value: 300,
            color: '#00C49F'
        },
        {
            name: t('pages.panel.dashboard.analytics.traffic.tablet'),
            value: 100,
            color: '#FFBB28'
        },
        {
            name: t('pages.panel.dashboard.analytics.traffic.others'),
            value: 50,
            color: '#FF8042'
        }
    ];

    const statsCards = [
        {
            title: t('pages.panel.dashboard.analytics.stats.revenue.title'),
            value: "$24,880",
            change: "+12.5%",
            isPositive: true,
            icon: CurrencyDollar,
            color: "primary"
        },
        {
            title: t('pages.panel.dashboard.analytics.stats.users.title'),
            value: "1,234",
            change: "+18.2%",
            isPositive: true,
            icon: People,
            color: "success"
        },
        {
            title: t('pages.panel.dashboard.analytics.stats.sales.title'),
            value: "456",
            change: "-4.5%",
            isPositive: false,
            icon: Cart,
            color: "warning"
        },
        {
            title: t('pages.panel.dashboard.analytics.stats.conversion.title'),
            value: "2.8%",
            change: "+3.1%",
            isPositive: true,
            icon: GraphUp,
            color: "info"
        }
    ];

    const timelineData = [
        {
            id: 1,
            time: '09:45 AM',
            type: 'success',
            title: t('pages.panel.dashboard.analytics.timeline.order.title'),
            description: t('pages.panel.dashboard.analytics.timeline.order.description', { id: '12345', amount: '$230.00' }),
            icon: CheckCircle
        },
        {
            id: 2,
            time: '10:30 AM',
            type: 'warning',
            title: t('pages.panel.dashboard.analytics.timeline.warning.title'),
            description: t('pages.panel.dashboard.analytics.timeline.warning.description'),
            icon: ExclamationTriangle
        },
        {
            id: 3,
            time: '11:15 AM',
            type: 'error',
            title: t('pages.panel.dashboard.analytics.timeline.error.title'),
            description: t('pages.panel.dashboard.analytics.timeline.error.description', { id: '67890' }),
            icon: XCircle
        },
        {
            id: 4,
            time: '12:00 PM',
            type: 'info',
            title: t('pages.panel.dashboard.analytics.timeline.meeting.title'),
            description: t('pages.panel.dashboard.analytics.timeline.meeting.description'),
            icon: Clock
        }
    ];

    const columnDefs = [
        {
            field: 'orderID',
            headerName: t('pages.panel.dashboard.analytics.table.headers.orderId'),
            sortable: true,
            filter: true
        },
        {
            field: 'customer',
            headerName: t('pages.panel.dashboard.analytics.table.headers.customer'),
            sortable: true,
            filter: true
        },
        {
            field: 'product',
            headerName: t('pages.panel.dashboard.analytics.table.headers.product'),
            sortable: true,
            filter: true
        },
        {
            field: 'status',
            headerName: t('pages.panel.dashboard.analytics.table.headers.status'),
            sortable: true,
            filter: true,
            cellRenderer: (params) => {
                const statusClasses = {
                    'Completed': 'success',
                    'Pending': 'warning',
                    'Cancelled': 'danger'
                };
                return <Badge bg={statusClasses[params.value]}>
                    {t(`pages.panel.dashboard.analytics.table.status.${params.value.toLowerCase()}`)}
                </Badge>;
            }
        },
        {
            field: 'amount',
            headerName: t('pages.panel.dashboard.analytics.table.headers.amount'),
            sortable: true,
            filter: true,
            valueFormatter: (params) => `$${params.value.toFixed(2)}`
        }
    ];

    const tableData = [
        {
            orderID: '#12345',
            customer: 'John Doe',
            product: t('pages.panel.dashboard.analytics.table.products.a'),
            status: 'Completed',
            amount: 230.00
        },
        {
            orderID: '#12346',
            customer: 'Jane Smith',
            product: t('pages.panel.dashboard.analytics.table.products.b'),
            status: 'Pending',
            amount: 150.50
        },
        {
            orderID: '#12347',
            customer: 'Bob Johnson',
            product: t('pages.panel.dashboard.analytics.table.products.c'),
            status: 'Cancelled',
            amount: 99.99
        },
        {
            orderID: '#12348',
            customer: 'Alice Brown',
            product: t('pages.panel.dashboard.analytics.table.products.d'),
            status: 'Completed',
            amount: 445.00
        },
        {
            orderID: '#12349',
            customer: 'Charlie Wilson',
            product: t('pages.panel.dashboard.analytics.table.products.e'),
            status: 'Pending',
            amount: 175.25
        }
    ];

    useEffect(() => {
        setRowData(tableData);
        setIsLoading(false);
    }, []);

    return (
        <div >
            {/* Stats Cards */}
            <Row className="g-3 mb-4">
                {statsCards.map((stat, index) => (
                    <Col key={index} xs={12} md={6} lg={3}>
                        <Card className="h-100">
                            <Card.Body>
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <p className="text-muted mb-1 small">{stat.title}</p>
                                        <h3 className="mb-0">{stat.value}</h3>
                                        <span className={`text-${stat.isPositive ? 'success' : 'danger'} d-flex align-items-center`}>
                                            {stat.isPositive ?
                                                <ArrowUp className="me-1" /> :
                                                <ArrowDown className="me-1" />
                                            }
                                            <span className="ms-1">{stat.change}</span>
                                        </span>
                                    </div>
                                    <div className={`bg-${stat.color} rounded-3 p-3`}>
                                        <stat.icon size={24} />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>

            {/* Charts Row */}
            <Row className="mb-4">
                <Col xs={12} lg={8}>
                    <Card>
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <h5 className="mb-0">{t('pages.panel.dashboard.analytics.charts.revenue.title')}</h5>
                            <Dropdown>
                                <Dropdown.Toggle variant="outline-secondary" size="sm">
                                    {t(`pages.panel.dashboard.analytics.periods.${period}`)}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {['daily', 'weekly', 'monthly'].map((p) => (
                                        <Dropdown.Item
                                            key={p}
                                            onClick={() => setPeriod(p)}
                                        >
                                            {t(`pages.panel.dashboard.analytics.periods.${p}`)}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width="100%" height={300}>
                                <AreaChart data={revenueData}>
                                    <defs>
                                        <linearGradient id="colorRevenue" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                            <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                                        </linearGradient>
                                        <linearGradient id="colorTarget" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <Tooltip />
                                    <Legend />
                                    <Area
                                        type="monotone"
                                        dataKey="value"
                                        name={t('pages.panel.dashboard.analytics.charts.revenue.revenue')}
                                        stroke="#8884d8"
                                        fillOpacity={1}
                                        fill="url(#colorRevenue)"
                                    />
                                    <Area
                                        type="monotone"
                                        dataKey="target"
                                        name={t('pages.panel.dashboard.analytics.charts.revenue.target')}
                                        stroke="#82ca9d"
                                        fillOpacity={1}
                                        fill="url(#colorTarget)"
                                    />
                                </AreaChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} lg={4}>
                    <Card className="h-100">
                        <Card.Header>
                            <h5 className="mb-0">{t('pages.panel.dashboard.analytics.charts.traffic.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width="100%" height={300}>
                                <PieChart>
                                    <Pie
                                        data={pieData}
                                        innerRadius={60}
                                        outerRadius={80}
                                        paddingAngle={5}
                                        dataKey="value"
                                    >
                                        {pieData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={entry.color} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Visitor Stats and Timeline */}
            <Row className="mb-4">
                <Col xs={12} lg={8}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('pages.panel.dashboard.analytics.charts.visitors.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width="100%" height={300}>
                                <BarChart data={visitsData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar
                                        dataKey="visits"
                                        name={t('pages.panel.dashboard.analytics.charts.visitors.total')}
                                        fill="#8884d8"
                                    />
                                    <Bar
                                        dataKey="uniqueVisitors"
                                        name={t('pages.panel.dashboard.analytics.charts.visitors.unique')}
                                        fill="#82ca9d"
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} lg={4}>
                    <Card className="h-100">
                        <Card.Header>
                            <h5 className="mb-0">{t('pages.panel.dashboard.analytics.timeline.title')}</h5>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <ListGroup variant="flush">
                                {timelineData.map((item) => (
                                    <ListGroup.Item key={item.id} className="px-4 py-3">
                                        <div className="d-flex">
                                            <div className="me-3">
                                                <item.icon className={`text-${item.type}`} size={20} />
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="d-flex justify-content-between">
                                                    <h6 className="mb-1">{item.title}</h6>
                                                    <small className="text-muted">{item.time}</small>
                                                </div>
                                                <p className="text-muted small mb-0">{item.description}</p>
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Recent Orders Table */}
            <Row>
                <Col xs={12}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('pages.panel.dashboard.analytics.table.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <div className="ag-theme-alpine" style={{ height: 400 }}>
                                <AgGridReact
                                    rowData={rowData}
                                    columnDefs={columnDefs}
                                    pagination={true}
                                    paginationPageSize={10}
                                    defaultColDef={{
                                        flex: 1,
                                        minWidth: 100,
                                        resizable: true
                                    }}
                                    animateRows={true}
                                    domLayout='autoHeight'
                                />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Performance Metrics */}
            <Row className="mt-4">
                <Col xs={12}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('pages.panel.dashboard.analytics.performance.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col md={6}>
                                    <div className="mb-4">
                                        <div className="d-flex justify-content-between mb-2">
                                            <span>{t('pages.panel.dashboard.analytics.performance.bounce.title')}</span>
                                            <span className="text-muted">42%</span>
                                        </div>
                                        <div className="progress" style={{ height: '6px' }}>
                                            <div
                                                className="progress-bar bg-warning"
                                                role="progressbar"
                                                style={{ width: '42%' }}
                                                aria-valuenow="42"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="d-flex justify-content-between mb-2">
                                            <span>{t('pages.panel.dashboard.analytics.performance.conversion.title')}</span>
                                            <span className="text-muted">2.8%</span>
                                        </div>
                                        <div className="progress" style={{ height: '6px' }}>
                                            <div
                                                className="progress-bar bg-success"
                                                role="progressbar"
                                                style={{ width: '28%' }}
                                                aria-valuenow="28"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-4">
                                        <div className="d-flex justify-content-between mb-2">
                                            <span>{t('pages.panel.dashboard.analytics.performance.session.title')}</span>
                                            <span className="text-muted">3m 45s</span>
                                        </div>
                                        <div className="progress" style={{ height: '6px' }}>
                                            <div
                                                className="progress-bar bg-info"
                                                role="progressbar"
                                                style={{ width: '65%' }}
                                                aria-valuenow="65"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="d-flex justify-content-between mb-2">
                                            <span>{t('pages.panel.dashboard.analytics.performance.pages.title')}</span>
                                            <span className="text-muted">4.2</span>
                                        </div>
                                        <div className="progress" style={{ height: '6px' }}>
                                            <div
                                                className="progress-bar bg-primary"
                                                role="progressbar"
                                                style={{ width: '72%' }}
                                                aria-valuenow="72"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Analytics;