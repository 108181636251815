
import ProfileAdvanced from "@pages/panel/profile/Profile";
import ProfileBasic from "@pages/panel/profile/Basic";




//Dashboard
import DashboardAnalytics from "@pages/panel/dashboard/Analytics";
import EcommerceAnalytics from "@pages/panel/dashboard/Ecommerce";
import ProjectManagement from "@pages/panel/dashboard/Projects";
import Settings from "@pages/panel/profile/Settings";
import Billing from "@pages/panel/profile/Billing";
import Alerts from "@pages/panel/ui/Alerts";
import Badges from "@pages/panel/ui/Badges";
import Buttons from "@pages/panel/ui/Buttons";
import Cards from "@pages/panel/ui/Cards";
import Charts from "@pages/panel/components/Charts";
import Modals from "@pages/panel/components/Modals";
import Notifications from "@pages/panel/components/Notifications";
import BasicForms from "@pages/panel/forms/Basic";
import AdvancedForms from "@pages/panel/forms/Advanced";
import ValidatedForm from "@pages/panel/forms/Validate";
import BasicTables from "@pages/panel/tables/Basic";
import DataTables from "@pages/panel/tables/Datatables";
import Register from "@pages/auth/Register/Register";
import ForgotPassword from "@pages/auth/ForgotPassword";
import Login from "@pages/auth/Login";

const routes = [
    {
        section: "DASHBOARDS",
        items: [
            {
                label: "Analytics",
                path: "/panel/analytics",
                icon: "bi-graph-up",
                element: <DashboardAnalytics />
            },
            {
                label: "E-Commerce",
                path: "/panel/ecommerce",
                icon: "bi-cart-check",
                element: <EcommerceAnalytics/>
            },
            {
                label: "Project Management",
                path: "/panel/projects",
                icon: "bi-kanban",
                element: <ProjectManagement/>
            }
        ]
    },
    {
        section: "PAGES",
        items: [
            {
                label: "User",
                path: "/panel/user",
                icon: "bi-person",
                subItems: [
                    {
                        label: "Basic",
                        path: "/panel/profile-basic",
                        element: <ProfileBasic/>,
                        icon: "bi-person-badge"
                    },
                    {
                        label: "Advanced",
                        path: "/panel/profile-advanced",
                        element: <ProfileAdvanced/>,
                        icon: "bi-person-badge"
                    },
                    {
                        label: "Settings",
                        path: "/panel/security",
                        element:<Settings/>,
                        icon: "bi-gear"
                    },
                    {
                        label: "Billing",
                        path: "/panel/billing",
                        element:<Billing/>,
                        icon: "bi-credit-card"
                    }
                ]
            },
            {
                label: "Authentication",
                path: "/panel/auth",
                icon: "bi-shield-lock",
                subItems: [
                    {
                        label: "Sign In",
                        path: "/panel/auth/signin",
                        icon: "bi-box-arrow-in-right",
                        element:<Login/>
                    },
                    {
                        label: "Sign Up",
                        path: "/panel/auth/signup",
                        icon: "bi-person-plus",
                        element:<Register/>
                    },
                    {
                        label: "Forgot Password",
                        path: "/panel/auth/reset",
                        icon: "bi-key",
                        element:<ForgotPassword/>
                    }
                ]
            }
        ]
    },
    {
        section: "COMPONENTS",
        items: [
            {
                label: "UI Elements",
                path: "/panel/ui",
                icon: "bi-palette",
                subItems: [
                    {
                        label: "Alerts",
                        path: "/panel/ui/alerts",
                        element:<Alerts/>,
                        icon: "bi-exclamation-triangle"
                    },
                    {
                        label: "Badges",
                        path: "/panel/ui/badges",
                        icon: "bi-bookmark",
                        element:<Badges/>
                    },
                    {
                        label: "Buttons",
                        path: "/panel/ui/buttons",
                        icon: "bi-hand-index",
                        element:<Buttons/>
                    },
                    {
                        label: "Cards",
                        path: "/panel/ui/cards",
                        icon: "bi-card-text",
                        element:<Cards/>
                    }
                ]
            },
            {
                label: "Advanced UI",
                path: "/panel/advanced",
                icon: "bi-stack",
                subItems: [
                    {
                        label: "Charts",
                        path: "/panel/advanced/charts",
                        icon: "bi-bar-chart",
                        element:<Charts/>
                    },
                    {
                        label: "Modals",
                        path: "/panel/advanced/modals",
                        icon: "bi-window",
                        element:<Modals/>
                    },
                    {
                        label: "Notifications",
                        path: "/panel/advanced/notifications",
                        element: <Notifications/>,
                        icon: "bi-bell"
                    }
                ]
            }
        ]
    },
    {
        section: "FORMS & TABLES",
        items: [
            {
                label: "Forms",
                path: "/panel/forms",
                icon: "bi-input-cursor-text",
                subItems: [
                    {
                        label: "Basic Elements",
                        path: "/panel/forms/basic",
                        icon: "bi-input-cursor",
                        element:<BasicForms/>
                    },
                    {
                        label: "Advanced Elements",
                        path: "/panel/forms/advanced",
                        icon: "bi-input-cursor-text",
                        element:<AdvancedForms/>
                    },
                    {
                        label: "Validation",
                        path: "/panel/forms/validation",
                        icon: "bi-check-circle",
                        element:<ValidatedForm/>
                    }
                ]
            },
            {
                label: "Tables",
                path: "/panel/tables",
                icon: "bi-table",
                subItems: [
                    {
                        label: "Basic Tables",
                        path: "/panel/tables/basic",
                        icon: "bi-grid",
                        element:<BasicTables/>
                    },
                    {
                        label: "Data Tables",
                        path: "/panel/tables/data",
                        icon: "bi-table",
                        element:<DataTables/>
                    }
                ]
            }
        ]
    },
];

export default routes;