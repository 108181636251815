import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function ErrorPage() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div className="container-fluid min-vh-100 d-flex align-items-center justify-content-center text-white">
            <div className="text-center">
                <div className="mb-3">
                    <h4 style={{ fontSize: '7rem' }} className="text-white">404</h4>
                </div>

                <div className="mb-4">
                    <p className="fs-4 mb-0 text-white">{t('pages.error_page.message1')}</p>
                    <p className="fs-4 text-white">
                        {t('pages.error_page.message2')}
                        <a className="link text-white text-decoration-underline" href="#">
                            {t('pages.error_page.contactLink')}
                        </a>
                    </p>
                </div>

                <button className="btn btn-primary" onClick={() => navigate(-1)}>
                    {t('pages.error_page.goBack')}
                </button>
            </div>
        </div>
    );
}

export default ErrorPage;