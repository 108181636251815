import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
    Card,
    Form,
    Button,
    InputGroup,
    Image
} from 'react-bootstrap';

import { setSessionUser } from "@store/slice/userSlice";
import { setSessionAuth } from "@store/slice/authSlice";
import { setPermissions } from "@store/slice/permissionSlice";
import { useAuth } from "@context/AuthContext";
import LanguageSelector from "@components/widgets/LanguageSelector";

function Login() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { setIsReadyForRedirect } = useAuth();

    const [formData, setFormData] = useState({
        email: 'admin@orpixel.com',
        password: 'password'
    });
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    useEffect(() => {
        setIsReadyForRedirect(false);
        return () => setIsReadyForRedirect(true);
    }, [setIsReadyForRedirect]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            setIsReadyForRedirect(false);
            await new Promise(resolve => setTimeout(resolve, 1000));

            if (formData.email === "admin@orpixel.com" && formData.password === "password") {
                const sessionUser = {
                    id: 1,
                    name: "Admin",
                    email: "admin@orpixel.com",
                    status_id: 11,
                    country_id: 141,
                    role: "admin"
                };

                await dispatch(setSessionAuth({
                    token: "mock_jwt_token_123456789",
                    lang: 'es'
                }));

                await dispatch(setSessionUser(sessionUser));
                dispatch(setPermissions([
                    "view_dashboard",
                    "manage_users",
                    "manage_settings"
                ]));

                toast.success(t('pages.auth.login.success'));
                setTimeout(()=>{
                    setIsReadyForRedirect(true); // This function just redirect to Dashboard
                },3000)

            } else {
                throw new Error('Invalid credentials');
            }
        } catch (error) {
            setIsReadyForRedirect(true);
            toast.error(t('pages.auth.login.error.authFailed'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <Card className="shadow-lg border-0">
            <Card.Header className="bg-white border-0 text-center pt-4">
                <Image
                    src="/assets/img/logos/logo.png"
                    alt="Logo"
                    className="mb-4"
                    style={{ height: '48px' }}
                />
                <h4 className="text-white mb-1">{t('pages.auth.login.title')}</h4>
                <p className="text-muted small mb-3">{t('pages.auth.login.description')}</p>
                <LanguageSelector />
            </Card.Header>

            <Card.Body className="px-4 py-4">
                <Form className="js-validate" noValidate onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label className="small fw-medium">
                            {t('pages.auth.login.email.label')}
                        </Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder={t('pages.auth.login.email.placeholder')}
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <div className="d-flex justify-content-between align-items-center">
                            <Form.Label className="small fw-medium">
                                {t('pages.auth.login.password.label')}
                            </Form.Label>
                            <Link to="/forgot-password" className="small text-white">
                                {t('pages.auth.login.password.forgot')}
                            </Link>
                        </div>
                        <InputGroup>
                            <Form.Control
                                type={showPassword ? "text" : "password"}
                                name="password"
                                placeholder={t('pages.auth.login.password.placeholder')}
                                value={formData.password}
                                onChange={handleChange}
                                required
                            />
                            <Button
                                variant="outline-secondary"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                <i className={`bi-${showPassword ? 'eye-slash' : 'eye'}`}></i>
                            </Button>
                        </InputGroup>
                    </Form.Group>

                    <div className="d-grid mb-4">
                        <Button
                            type="submit"
                            variant="primary"
                            className="py-2"
                            disabled={loading}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm me-2" />
                            )}
                            {t('pages.auth.login.submit')}
                        </Button>
                    </div>

                    <div className="text-center">
                        <p className="small text-muted mb-0">
                            {t('pages.auth.login.signupPrompt')}{' '}
                            <Link to="/register" className="text-decoration-none">
                                {t('pages.auth.login.signupLink')}
                            </Link>
                        </p>
                    </div>
                </Form>
            </Card.Body>

            <Card.Footer className="bg-soft-light py-3 text-center">
                <p className="small text-muted mb-0">
                    &copy; Orpixel |{' '}
                    <Link to='/terms-and-conditions' className="text-decoration-none">
                        {t('layout.footer.copyright')}
                    </Link>
                </p>
            </Card.Footer>
        </Card>
    );
}

export default Login;