import React from 'react';
import { Container, Row, Col, Card, Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';
import {
    Plus,
    Trash,
    PencilSquare,
    Download,
    Upload,
    Send,
    Save,
    Heart,
    Star,
    Share,
    ThreeDots
} from 'react-bootstrap-icons';

const Buttons = () => {
    // Variantes de colores disponibles
    const variants = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'];

    return (
        <Container fluid className="py-4">
            {/* Basic Buttons */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Basic Buttons</h5>
                </Card.Header>
                <Card.Body>
                    <div className="d-flex flex-wrap gap-2">
                        {variants.map((variant) => (
                            <Button key={variant} variant={variant}>
                                {variant.charAt(0).toUpperCase() + variant.slice(1)}
                            </Button>
                        ))}
                    </div>
                </Card.Body>
            </Card>

            {/* Outline Buttons */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Outline Buttons</h5>
                </Card.Header>
                <Card.Body>
                    <div className="d-flex flex-wrap gap-2">
                        {variants.map((variant) => (
                            <Button key={variant} variant={`outline-${variant}`}>
                                {variant.charAt(0).toUpperCase() + variant.slice(1)}
                            </Button>
                        ))}
                    </div>
                </Card.Body>
            </Card>

            {/* Buttons with Icons */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Buttons with Icons</h5>
                </Card.Header>
                <Card.Body>
                    <Row className="g-3">
                        <Col md={6}>
                            <h6 className="mb-3">Icon Left</h6>
                            <div className="d-flex flex-wrap gap-2">
                                <Button variant="primary">
                                    <Plus className="me-2" /> Add New
                                </Button>
                                <Button variant="danger">
                                    <Trash className="me-2" /> Delete
                                </Button>
                                <Button variant="success">
                                    <PencilSquare className="me-2" /> Edit
                                </Button>
                                <Button variant="info">
                                    <Download className="me-2" /> Download
                                </Button>
                            </div>
                        </Col>
                        <Col md={6}>
                            <h6 className="mb-3">Icon Only</h6>
                            <div className="d-flex flex-wrap gap-2">
                                <Button variant="primary" className="d-inline-flex align-items-center justify-content-center" style={{ width: '40px', height: '40px', padding: 0 }}>
                                    <Plus />
                                </Button>
                                <Button variant="danger" className="d-inline-flex align-items-center justify-content-center" style={{ width: '40px', height: '40px', padding: 0 }}>
                                    <Trash />
                                </Button>
                                <Button variant="success" className="d-inline-flex align-items-center justify-content-center" style={{ width: '40px', height: '40px', padding: 0 }}>
                                    <Save />
                                </Button>
                                <Button variant="info" className="d-inline-flex align-items-center justify-content-center" style={{ width: '40px', height: '40px', padding: 0 }}>
                                    <Share />
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {/* Button Sizes */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Button Sizes</h5>
                </Card.Header>
                <Card.Body>
                    <div className="d-flex flex-wrap gap-2 align-items-center">
                        <Button variant="primary" size="lg">Large Button</Button>
                        <Button variant="primary">Default Button</Button>
                        <Button variant="primary" size="sm">Small Button</Button>
                    </div>
                </Card.Body>
            </Card>

            {/* Button States */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Button States</h5>
                </Card.Header>
                <Card.Body>
                    <Row className="g-3">
                        <Col md={6}>
                            <h6 className="mb-3">Active State</h6>
                            <div className="d-flex flex-wrap gap-2">
                                <Button variant="primary" active>Active Primary</Button>
                                <Button variant="success" active>Active Success</Button>
                            </div>
                        </Col>
                        <Col md={6}>
                            <h6 className="mb-3">Disabled State</h6>
                            <div className="d-flex flex-wrap gap-2">
                                <Button variant="primary" disabled>Disabled Primary</Button>
                                <Button variant="success" disabled>Disabled Success</Button>
                            </div>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {/* Button Groups */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Button Groups</h5>
                </Card.Header>
                <Card.Body>
                    <Row className="g-3">
                        <Col md={6}>
                            <h6 className="mb-3">Basic Group</h6>
                            <ButtonGroup>
                                <Button variant="primary">Left</Button>
                                <Button variant="primary">Middle</Button>
                                <Button variant="primary">Right</Button>
                            </ButtonGroup>
                        </Col>
                        <Col md={6}>
                            <h6 className="mb-3">Toolbar</h6>
                            <ButtonToolbar>
                                <ButtonGroup className="me-2">
                                    <Button variant="outline-primary"><Plus /></Button>
                                    <Button variant="outline-primary"><PencilSquare /></Button>
                                    <Button variant="outline-primary"><Trash /></Button>
                                </ButtonGroup>
                                <ButtonGroup>
                                    <Button variant="outline-secondary"><Upload /></Button>
                                    <Button variant="outline-secondary"><Download /></Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {/* Block Buttons */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Block Buttons</h5>
                </Card.Header>
                <Card.Body>
                    <div className="d-grid gap-2">
                        <Button variant="primary" size="lg">Block Level Button</Button>
                        <Button variant="secondary">Block Level Button</Button>
                    </div>
                </Card.Body>
            </Card>

            {/* Loading Buttons */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Loading Buttons</h5>
                </Card.Header>
                <Card.Body>
                    <div className="d-flex flex-wrap gap-2">
                        <Button variant="primary" disabled>
                            <span className="spinner-border spinner-border-sm me-2" />
                            Loading...
                        </Button>
                        <Button variant="success" disabled>
                            <span className="spinner-grow spinner-grow-sm me-2" />
                            Loading...
                        </Button>
                        <Button variant="warning" disabled>
                            <span className="spinner-border spinner-border-sm" />
                        </Button>
                    </div>
                </Card.Body>
            </Card>

            {/* Social Buttons */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Social Buttons</h5>
                </Card.Header>
                <Card.Body>
                    <div className="d-flex flex-wrap gap-2">
                        <Button variant="outline-primary" className="d-inline-flex align-items-center">
                            <i className="bi bi-facebook me-2"></i> Facebook
                        </Button>
                        <Button variant="outline-info" className="d-inline-flex align-items-center">
                            <i className="bi bi-twitter me-2"></i> Twitter
                        </Button>
                        <Button variant="outline-danger" className="d-inline-flex align-items-center">
                            <i className="bi bi-google me-2"></i> Google
                        </Button>
                        <Button variant="outline-dark" className="d-inline-flex align-items-center">
                            <i className="bi bi-github me-2"></i> Github
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default Buttons;