import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button, InputGroup, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    Calendar3,
    Upload,
    X,
    Plus,
    ColorPicker,
    Phone,
    CreditCard,
    Hash,
    GeoAlt
} from 'react-bootstrap-icons';

const AdvancedForms = () => {
    const { t } = useTranslation();

    // Estados para manejar los diferentes valores
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [tags, setTags] = useState([]);
    const [currentTag, setCurrentTag] = useState('');
    const [creditCard, setCreditCard] = useState('');
    const [phone, setPhone] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [validationErrors, setValidationErrors] = useState({});

    // Manejador de archivos
    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setSelectedFiles(prev => [...prev, ...files]);
    };

    const removeFile = (index) => {
        setSelectedFiles(prev => prev.filter((_, i) => i !== index));
    };

    // Manejador de tags
    const handleTagAdd = (e) => {
        e.preventDefault();
        if (currentTag.trim() && !tags.includes(currentTag.trim())) {
            setTags(prev => [...prev, currentTag.trim()]);
            setCurrentTag('');
        }
    };

    const removeTag = (tagToRemove) => {
        setTags(prev => prev.filter(tag => tag !== tagToRemove));
    };

    // Formatear tarjeta de crédito
    const handleCreditCardChange = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const formattedValue = value.replace(/(\d{4})/g, '$1 ').trim();
        setCreditCard(formattedValue);
    };

    // Formatear teléfono
    const handlePhoneChange = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        const match = value.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
            const formatted = !match[2] ? match[1]
                : `(${match[1]}) ${match[2]}${match[3] ? `-${match[3]}` : ''}`;
            setPhone(formatted);
        }
    };

    // Validar código postal
    const handlePostalCodeChange = (e) => {
        const value = e.target.value.replace(/[^\d-]/g, '');
        setPostalCode(value);

        if (value && !/^\d{5}(-\d{4})?$/.test(value)) {
            setValidationErrors(prev => ({
                ...prev,
                postalCode: t('forms.advanced.validation.postalCode')
            }));
        } else {
            setValidationErrors(prev => {
                const { postalCode, ...rest } = prev;
                return rest;
            });
        }
    };

    return (
        <Container fluid className="py-4">
            <Row className="g-4">
                {/* Subida de Archivos */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('forms.advanced.fileUpload.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Form.Group>
                                <div className="dropzone-container border rounded-3 p-4 text-center">
                                    <Upload className="display-4 text-muted mb-3" />
                                    <p className="mb-2">{t('forms.advanced.fileUpload.dragDrop')}</p>
                                    <div>
                                        <Button variant="outline-primary" size="sm">
                                            {t('forms.advanced.fileUpload.browse')}
                                            <Form.Control
                                                type="file"
                                                multiple
                                                className="position-absolute opacity-0"
                                                style={{ top: 0, left: 0, width: '100%', height: '100%' }}
                                                onChange={handleFileChange}
                                            />
                                        </Button>
                                    </div>
                                </div>

                                {selectedFiles.length > 0 && (
                                    <div className="mt-3">
                                        <h6>{t('forms.advanced.fileUpload.selectedFiles')}</h6>
                                        <div className="list-group">
                                            {selectedFiles.map((file, index) => (
                                                <div key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <small className="text-muted me-2">{file.name}</small>
                                                        <Badge bg="info">{(file.size / 1024).toFixed(2)} KB</Badge>
                                                    </div>
                                                    <Button
                                                        variant="link"
                                                        className="text-danger p-0"
                                                        onClick={() => removeFile(index)}
                                                    >
                                                        <X />
                                                    </Button>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </Form.Group>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Tags Input */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('forms.advanced.tags.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Form onSubmit={handleTagAdd}>
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="text"
                                        placeholder={t('forms.advanced.tags.placeholder')}
                                        value={currentTag}
                                        onChange={(e) => setCurrentTag(e.target.value)}
                                    />
                                    <Button variant="primary" type="submit">
                                        <Plus />
                                    </Button>
                                </InputGroup>
                            </Form>

                            <div className="d-flex flex-wrap gap-2">
                                {tags.map((tag, index) => (
                                    <Badge
                                        key={index}
                                        bg="primary"
                                        className="d-flex align-items-center py-2 px-3"
                                    >
                                        {tag}
                                        <Button
                                            variant="link"
                                            className="text-white p-0 ms-2"
                                            onClick={() => removeTag(tag)}
                                        >
                                            <X size={14} />
                                        </Button>
                                    </Badge>
                                ))}
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Campos Formateados */}
                <Col md={12}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('forms.advanced.formatted.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row className="g-3">
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label>{t('forms.advanced.formatted.creditCard')}</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <CreditCard />
                                            </InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                value={creditCard}
                                                onChange={handleCreditCardChange}
                                                placeholder="1234 5678 9012 3456"
                                                maxLength={19}
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>

                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label>{t('forms.advanced.formatted.phone')}</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <Phone />
                                            </InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                value={phone}
                                                onChange={handlePhoneChange}
                                                placeholder="(123) 456-7890"
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>

                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label>{t('forms.advanced.formatted.postalCode')}</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <Hash />
                                            </InputGroup.Text>
                                            <Form.Control
                                                type="text"
                                                value={postalCode}
                                                onChange={handlePostalCodeChange}
                                                placeholder="12345"
                                                isInvalid={!!validationErrors.postalCode}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {validationErrors.postalCode}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Campos de Fecha/Hora */}
                <Col md={12}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('forms.advanced.dateTime.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row className="g-3">
                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label>{t('forms.advanced.dateTime.date')}</Form.Label>
                                        <InputGroup>
                                            <InputGroup.Text>
                                                <Calendar3 />
                                            </InputGroup.Text>
                                            <Form.Control
                                                type="date"
                                            />
                                        </InputGroup>
                                    </Form.Group>
                                </Col>

                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label>{t('forms.advanced.dateTime.time')}</Form.Label>
                                        <Form.Control
                                            type="time"
                                        />
                                    </Form.Group>
                                </Col>

                                <Col md={4}>
                                    <Form.Group>
                                        <Form.Label>{t('forms.advanced.dateTime.dateTime')}</Form.Label>
                                        <Form.Control
                                            type="datetime-local"
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default AdvancedForms;