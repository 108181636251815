import React from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    LineChart, Line,
    AreaChart, Area,
    BarChart, Bar,
    PieChart, Pie,
    ScatterChart, Scatter,
    ComposedChart,
    XAxis, YAxis, CartesianGrid,
    Tooltip, Legend,
    ResponsiveContainer,
    Cell
} from 'recharts';

const Charts = () => {
    const { t } = useTranslation();

    // Datos para las gráficas
    const monthlyData = [
        { month: 'Jan', sales: 4200, expenses: 3800, profit: 400 },
        { month: 'Feb', sales: 5100, expenses: 4300, profit: 800 },
        { month: 'Mar', sales: 3800, expenses: 3200, profit: 600 },
        { month: 'Apr', sales: 4800, expenses: 4100, profit: 700 },
        { month: 'May', sales: 5500, expenses: 4500, profit: 1000 },
        { month: 'Jun', sales: 6000, expenses: 5100, profit: 900 },
    ];

    const pieData = [
        { name: 'Desktop', value: 45 },
        { name: 'Mobile', value: 35 },
        { name: 'Tablet', value: 20 }
    ];

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    return (
        <Container fluid className="py-4">
            <Row className="g-4">
                {/* Line Chart */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('charts.lineChart.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width="100%" height={400}>
                                <LineChart data={monthlyData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="month" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line
                                        type="monotone"
                                        dataKey="sales"
                                        name={t('charts.common.sales')}
                                        stroke="#4CAF50"
                                        activeDot={{ r: 8 }}
                                    />
                                    <Line
                                        type="monotone"
                                        dataKey="expenses"
                                        name={t('charts.common.expenses')}
                                        stroke="#f44336"
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Area Chart */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('charts.areaChart.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width="100%" height={400}>
                                <AreaChart data={monthlyData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="month" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Area
                                        type="monotone"
                                        dataKey="sales"
                                        name={t('charts.common.sales')}
                                        stroke="#8884d8"
                                        fill="#8884d8"
                                        fillOpacity={0.3}
                                    />
                                </AreaChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Bar Chart */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('charts.barChart.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart data={monthlyData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="month" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar
                                        dataKey="profit"
                                        name={t('charts.common.profit')}
                                        fill="#2196F3"
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Pie Chart */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('charts.pieChart.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width="100%" height={400}>
                                <PieChart>
                                    <Pie
                                        data={pieData}
                                        cx="50%"
                                        cy="50%"
                                        labelLine={true}
                                        label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                                        outerRadius={150}
                                        fill="#8884d8"
                                        dataKey="value"
                                    >
                                        {pieData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Scatter Chart */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('charts.scatterChart.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width="100%" height={400}>
                                <ScatterChart>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis
                                        dataKey="expenses"
                                        name={t('charts.common.expenses')}
                                        type="number"
                                    />
                                    <YAxis
                                        dataKey="sales"
                                        name={t('charts.common.sales')}
                                        type="number"
                                    />
                                    <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                                    <Scatter
                                        name={t('charts.common.salesVsExpenses')}
                                        data={monthlyData}
                                        fill="#8884d8"
                                    />
                                </ScatterChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Composed Chart */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('charts.mixedChart.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width="100%" height={400}>
                                <ComposedChart data={monthlyData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="month" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar
                                        dataKey="sales"
                                        name={t('charts.common.sales')}
                                        barSize={20}
                                        fill="#413ea0"
                                    />
                                    <Line
                                        type="monotone"
                                        dataKey="profit"
                                        name={t('charts.common.profit')}
                                        stroke="#ff7300"
                                    />
                                </ComposedChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Donut Chart */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('charts.donutChart.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width="100%" height={400}>
                                <PieChart>
                                    <Pie
                                        data={pieData}
                                        cx="50%"
                                        cy="50%"
                                        innerRadius={60}
                                        outerRadius={150}
                                        fill="#8884d8"
                                        dataKey="value"
                                        label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                                    >
                                        {pieData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                    <Legend />
                                </PieChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Stacked Bar Chart */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('charts.stackedChart.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <ResponsiveContainer width="100%" height={400}>
                                <BarChart data={monthlyData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="month" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Bar
                                        dataKey="sales"
                                        name={t('charts.common.sales')}
                                        stackId="a"
                                        fill="#8884d8"
                                    />
                                    <Bar
                                        dataKey="expenses"
                                        name={t('charts.common.expenses')}
                                        stackId="a"
                                        fill="#82ca9d"
                                    />
                                </BarChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Charts;