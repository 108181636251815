import React, { useState } from 'react';
import { Card, Row, Col, Form, Button, Badge, ListGroup, Tab, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    Person, Envelope, Phone, GeoAlt, Calendar3,
    Facebook, Twitter, Linkedin, Github,
    PencilSquare, Key, Bell
} from 'react-bootstrap-icons';

const Profile = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState('overview');

    return (
        <div className="p-4">
            {/* Banner y Foto de Perfil */}
            <Card className="border-0 mb-4">
                <div
                    className="position-relative"
                    style={{
                        height: '200px',
                        background: 'linear-gradient(45deg, #3a8ef6, #6f42c1)',
                        borderRadius: '0.5rem 0.5rem 0 0'
                    }}
                >
                    <div
                        className="position-absolute"
                        style={{
                            bottom: '-50px',
                            left: '50px'
                        }}
                    >
                        <img
                            src="/api/placeholder/120/120"
                            alt="Profile"
                            className="rounded-circle border border-4 border-white shadow"
                            style={{ width: '100px', height: '100px' }}
                        />
                    </div>
                </div>
                <Card.Body className="pt-5 pb-3">
                    <div className="d-flex justify-content-between align-items-start ms-5">
                        <div>
                            <h4 className="mb-1">Alex Johnson</h4>
                            <p className="text-muted mb-2">Senior Product Designer</p>
                            <div className="d-flex gap-2">
                                <Badge bg="primary">UI/UX</Badge>
                                <Badge bg="info">Product</Badge>
                                <Badge bg="success">Design</Badge>
                            </div>
                        </div>
                        <Button variant="outline-primary">
                            <PencilSquare className="me-2" />
                            Edit Profile
                        </Button>
                    </div>
                </Card.Body>
            </Card>

            <Row>
                <Col lg={3}>
                    {/* Información de Contacto */}
                    <Card className="mb-4">
                        <Card.Body>
                            <h6 className="mb-3">Contact Information</h6>
                            <ListGroup variant="flush">
                                <ListGroup.Item className="px-0 py-2">
                                    <div className="d-flex align-items-center">
                                        <Envelope className="text-muted me-2" />
                                        <div>
                                            <small className="text-muted d-block">Email</small>
                                            <span>alex.johnson@example.com</span>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item className="px-0 py-2">
                                    <div className="d-flex align-items-center">
                                        <Phone className="text-muted me-2" />
                                        <div>
                                            <small className="text-muted d-block">Phone</small>
                                            <span>+1 (555) 123-4567</span>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item className="px-0 py-2">
                                    <div className="d-flex align-items-center">
                                        <GeoAlt className="text-muted me-2" />
                                        <div>
                                            <small className="text-muted d-block">Location</small>
                                            <span>San Francisco, CA</span>
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>

                    {/* Redes Sociales */}
                    <Card className="mb-4">
                        <Card.Body>
                            <h6 className="mb-3">Social Profiles</h6>
                            <ListGroup variant="flush">
                                <ListGroup.Item className="px-0 py-2 d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <Facebook className="text-primary me-2" />
                                        <span>Facebook</span>
                                    </div>
                                    <Button variant="outline-primary" size="sm">Connect</Button>
                                </ListGroup.Item>
                                <ListGroup.Item className="px-0 py-2 d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <Twitter className="text-info me-2" />
                                        <span>Twitter</span>
                                    </div>
                                    <Button variant="outline-info" size="sm">Connect</Button>
                                </ListGroup.Item>
                                <ListGroup.Item className="px-0 py-2 d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center">
                                        <Linkedin className="text-primary me-2" />
                                        <span>LinkedIn</span>
                                    </div>
                                    <Badge bg="success">Connected</Badge>
                                </ListGroup.Item>
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>

                <Col lg={9}>
                    <Card>
                        <Card.Body>
                            <Tab.Container activeKey={activeTab} onSelect={setActiveTab}>
                                <Nav variant="tabs" className="mb-4">
                                    <Nav.Item>
                                        <Nav.Link eventKey="overview">Overview</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="projects">Projects</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="teams">Teams</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="activity">Activity</Nav.Link>
                                    </Nav.Item>
                                </Nav>

                                <Tab.Content>
                                    <Tab.Pane eventKey="overview">
                                        {/* About Me */}
                                        <div className="mb-4">
                                            <h5 className="mb-3">About Me</h5>
                                            <p>
                                                I'm a senior product designer with over 8 years of experience in creating user-centered digital products.
                                                I specialize in UI/UX design, product strategy, and design systems.
                                            </p>
                                        </div>

                                        {/* Skills */}
                                        <div className="mb-4">
                                            <h5 className="mb-3">Skills</h5>
                                            <div className="d-flex flex-wrap gap-2">
                                                {[
                                                    'UI Design', 'UX Research', 'Figma', 'Sketch',
                                                    'Design Systems', 'Prototyping', 'User Testing',
                                                    'Product Strategy', 'Team Leadership'
                                                ].map((skill, index) => (
                                                    <Badge
                                                        key={index}
                                                        bg="light"
                                                        text="dark"
                                                        className="px-3 py-2"
                                                    >
                                                        {skill}
                                                    </Badge>
                                                ))}
                                            </div>
                                        </div>

                                        {/* Experience */}
                                        <div>
                                            <h5 className="mb-3">Experience</h5>
                                            <ListGroup variant="flush">
                                                {[
                                                    {
                                                        role: 'Senior Product Designer',
                                                        company: 'TechCorp',
                                                        period: '2020 - Present',
                                                        description: 'Leading the design team in creating innovative digital products.'
                                                    },
                                                    {
                                                        role: 'Product Designer',
                                                        company: 'DesignHub',
                                                        period: '2018 - 2020',
                                                        description: 'Worked on multiple client projects focusing on user experience.'
                                                    },
                                                    {
                                                        role: 'UI Designer',
                                                        company: 'CreativeLab',
                                                        period: '2016 - 2018',
                                                        description: 'Designed user interfaces for web and mobile applications.'
                                                    }
                                                ].map((exp, index) => (
                                                    <ListGroup.Item key={index} className="px-0">
                                                        <div className="d-flex justify-content-between mb-1">
                                                            <h6 className="mb-0">{exp.role}</h6>
                                                            <span className="text-muted">{exp.period}</span>
                                                        </div>
                                                        <p className="text-muted mb-0">{exp.company}</p>
                                                        <small>{exp.description}</small>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                        </div>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="projects">
                                        <Row className="g-4">
                                            {[1, 2, 3, 4].map((project) => (
                                                <Col md={6} key={project}>
                                                    <Card>
                                                        <Card.Body>
                                                            <div className="d-flex justify-content-between mb-3">
                                                                <h6 className="mb-0">Project Name #{project}</h6>
                                                                <Badge bg={project % 2 === 0 ? 'success' : 'warning'}>
                                                                    {project % 2 === 0 ? 'Completed' : 'In Progress'}
                                                                </Badge>
                                                            </div>
                                                            <p className="text-muted small">
                                                                Brief description of the project and its objectives.
                                                            </p>
                                                            <div className="d-flex align-items-center">
                                                                <div className="d-flex align-items-center me-3">
                                                                    <Calendar3 className="text-muted me-1" />
                                                                    <small className="text-muted">Due: 2024-03-{project * 5}</small>
                                                                </div>
                                                                <div className="d-flex align-items-center">
                                                                    <Person className="text-muted me-1" />
                                                                    <small className="text-muted">Team: {project * 2} members</small>
                                                                </div>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="teams">
                                        <ListGroup>
                                            {[
                                                { name: 'Design Team', role: 'Team Lead', members: 8 },
                                                { name: 'Product Development', role: 'Member', members: 12 },
                                                { name: 'Research Group', role: 'Contributor', members: 6 }
                                            ].map((team, index) => (
                                                <ListGroup.Item
                                                    key={index}
                                                    className="d-flex justify-content-between align-items-center"
                                                >
                                                    <div>
                                                        <h6 className="mb-1">{team.name}</h6>
                                                        <Badge bg="secondary">{team.role}</Badge>
                                                    </div>
                                                    <div className="text-end">
                                                        <small className="text-muted d-block">
                                                            {team.members} members
                                                        </small>
                                                        <Button variant="link" size="sm" className="p-0">
                                                            View Team
                                                        </Button>
                                                    </div>
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="activity">
                                        <ListGroup variant="flush">
                                            {[
                                                {
                                                    action: 'Completed project deliverables',
                                                    project: 'UI Redesign',
                                                    time: '2 hours ago'
                                                },
                                                {
                                                    action: 'Submitted new design mockups',
                                                    project: 'Mobile App',
                                                    time: '5 hours ago'
                                                },
                                                {
                                                    action: 'Team meeting',
                                                    project: 'Product Review',
                                                    time: 'Yesterday'
                                                }
                                            ].map((activity, index) => (
                                                <ListGroup.Item key={index} className="px-0">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p className="mb-1">{activity.action}</p>
                                                            <Badge bg="light" text="dark">
                                                                {activity.project}
                                                            </Badge>
                                                        </div>
                                                        <small className="text-muted">
                                                            {activity.time}
                                                        </small>
                                                    </div>
                                                </ListGroup.Item>
                                            ))}
                                        </ListGroup>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default Profile;