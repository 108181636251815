import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

const LanguageSelector = () => {
    const { t, i18n } = useTranslation();
    const dropdownButtonRef = useRef(null);
    const bootstrapDropdownRef = useRef(null);

    const languages = [
        { code: 'en', name: t('layout.header.languages.en'), flag: 'us' },
        { code: 'es', name: t('layout.header.languages.es'), flag: 'es' },
    ];

    // Initialize with saved language or browser preference
    useEffect(() => {
        const savedLanguage = localStorage.getItem('preferredLanguage');
        const browserLanguage = navigator.language.split('-')[0];
        const defaultLanguage = savedLanguage || (languages.some(lang => lang.code === browserLanguage) ? browserLanguage : 'en');

        if (i18n.language !== defaultLanguage) {
            i18n.changeLanguage(defaultLanguage);
        }
    }, []);

    useEffect(() => {
        const initDropdown = async () => {
            try {
                const bootstrap = await import('bootstrap');
                bootstrapDropdownRef.current = new bootstrap.Dropdown(dropdownButtonRef.current);
            } catch (error) {
                console.error('Error initializing language dropdown:', error);
            }
        };

        initDropdown();

        return () => {
            if (bootstrapDropdownRef.current && bootstrapDropdownRef.current.dispose) {
                bootstrapDropdownRef.current.dispose();
            }
        };
    }, []);

    const changeLanguage = async (lng) => {
        try {
            await i18n.changeLanguage(lng);
            localStorage.setItem('preferredLanguage', lng);

            // Opcional: Recargar los recursos de traducción si es necesario
            // await i18n.reloadResources();

            // Cerrar el dropdown
            if (bootstrapDropdownRef.current) {
                bootstrapDropdownRef.current.hide();
            }

            // Opcional: Disparar un evento personalizado para notificar el cambio de idioma
            window.dispatchEvent(new CustomEvent('languageChanged', { detail: lng }));
        } catch (error) {
            console.error('Error changing language:', error);
        }
    };

    const currentLanguage = languages.find(lang => lang.code === i18n.language) || languages[0];

    return (
        <div className="btn-group">
            <Button
                ref={dropdownButtonRef}
                type="button"
                className="btn btn-dark btn-sm dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <span className="d-flex align-items-center">
                    <img
                        className="avatar avatar-xss avatar-circle me-2"
                        src={`/assets/img/flags/1x1/${currentLanguage.flag}.svg`}
                        alt={currentLanguage.name}
                        width="16"
                    />
                    <span>{currentLanguage.code.toUpperCase()}</span>
                </span>
            </Button>
            <div className="dropdown-menu dropdown-menu-end">
                {languages.map((language) => (
                    <button
                        key={language.code}
                        className={`dropdown-item d-flex align-items-center ${
                            i18n.language === language.code ? 'active' : ''
                        }`}
                        onClick={() => changeLanguage(language.code)}
                    >
                        <img
                            className="avatar avatar-xss avatar-circle me-2"
                            src={`/assets/img/flags/1x1/${language.flag}.svg`}
                            alt={language.name}
                            width="16"
                        />
                        <span className="text-white">{language.name}</span>
                    </button>
                ))}
            </div>
        </div>
    );
};

export default LanguageSelector;