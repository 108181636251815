import React from 'react';
import { Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SidebarMenuItem from './SidebarMenuItem';
import routes from '@/navigation/private';

const SidebarMenu = () => {
    const theme = useSelector((state) => state.theme);
    const isCompact = theme.layout === 'compact';

    return (
        <div className="sidebar-menu">
            {routes.map((section, index) => {
                const visibleItems = section.items.filter(item => !item.hidden);

                if (visibleItems.length === 0) return null;

                return (
                    <div key={index} className="sidebar-section">
                        {!isCompact && (
                            <span className="text-cap small fw-bold text-muted mb-2 mt-4 mx-2 d-block">
                                {section.section}
                            </span>
                        )}
                        <Nav className="nav-tabs nav-vertical mb-4">
                            {visibleItems.map((item, itemIndex) => (
                                <SidebarMenuItem
                                    key={itemIndex}
                                    path={item.path}
                                    icon={item.icon}
                                    label={item.label}
                                    subItems={item.subItems?.filter(subItem => !subItem.hidden)}
                                />
                            ))}
                        </Nav>
                        {index < routes.length - 1 && <hr className="neon-divider" />}
                    </div>
                );
            })}
        </div>
    );
};

export default SidebarMenu;