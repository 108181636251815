import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import {
    Form,
    Button,
    InputGroup,
    Card,
    Image
} from 'react-bootstrap';


import Select from "@components/elements/Select";

/**
 * Register Component
 * Handles new user registration process with form validation
 */
function Register() {
    const { t } = useTranslation();

    // Form state initialization
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        country_id: '',
        password_confirmation: '',
        privacyAccepted: false
    });
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    // Form event handlers
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };


    const handleCountryChange = (value) => {
        setFormData(prev => ({
            ...prev,
            country_id: Number(value)
        }));
    };

    return (
        <Card className="shadow-lg border-0">
            <Card.Header className="bg-white border-0 text-center pt-4">
                <Image
                    src="/assets/img/logos/logo.png"
                    alt="Logo"
                    className="mb-4"
                    style={{ height: '48px' }}
                />
                <h4 className="text-white mb-1">{t('pages.auth.signup.title')}</h4>
                <p className="text-muted small mb-3">{t('pages.auth.signup.description')}</p>
            </Card.Header>

            <Card.Body className="px-4 py-4">
                <Form noValidate >
                    <Form.Group className="mb-3">
                        <Form.Label className="small fw-medium">
                            {t('pages.auth.signup.name.label')}
                        </Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            placeholder={t('pages.auth.signup.name.placeholder')}
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className="small fw-medium">
                            {t('pages.auth.signup.email.label')}
                        </Form.Label>
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder={t('pages.auth.signup.email.placeholder')}
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className="small fw-medium">
                            {t('pages.auth.signup.password.label')}
                        </Form.Label>
                        <InputGroup>
                            <Form.Control
                                type={showPassword ? "text" : "password"}
                                name="password"
                                placeholder={t('pages.auth.signup.password.placeholder')}
                                value={formData.password}
                                onChange={handleChange}
                                required
                                minLength="8"
                            />
                            <Button
                                variant="outline-secondary"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                <i className={`bi-${showPassword ? 'eye-slash' : 'eye'}`}></i>
                            </Button>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label className="small fw-medium">
                            {t('pages.auth.signup.confirmPassword.label')}
                        </Form.Label>
                        <InputGroup>
                            <Form.Control
                                type={showConfirmPassword ? "text" : "password"}
                                name="password_confirmation"
                                placeholder={t('pages.auth.signup.confirmPassword.placeholder')}
                                value={formData.password_confirmation}
                                onChange={handleChange}
                                required
                                minLength="8"
                            />
                            <Button
                                variant="outline-secondary"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                                <i className={`bi-${showConfirmPassword ? 'eye-slash' : 'eye'}`}></i>
                            </Button>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Label className="small fw-medium">
                            {t('pages.auth.signup.country.label')}
                        </Form.Label>
                        <Select
                            mode={'standard'}
                            renderOptions={(option) => (
                                <span className="d-flex align-items-center">
                                    <img
                                        src={`/assets/img/flags/1x1/${option.code}.svg`}
                                        alt={option.name}
                                        className="me-2 img-thumbnail p-1 avatar avatar-sm avatar-circle"
                                        style={{ width: '30px', height: '30px' }}
                                    />
                                    <span>{option.name}</span>
                                </span>
                            )}

                            selected={formData.country_id}
                            onChange={handleCountryChange}
                        />
                    </Form.Group>

                    <Form.Group className="mb-4">
                        <Form.Check
                            type="checkbox"
                            id="privacyAccepted"
                            name="privacyAccepted"
                            checked={formData.privacyAccepted}
                            onChange={handleChange}
                            label={
                                <span className="small">
                                    {t('pages.auth.signup.privacy.label')}{' '}
                                    <Link to="/privacy-policy" target="_blank" className="text-decoration-none">
                                        {t('pages.auth.signup.privacy.link')}
                                    </Link>
                                </span>
                            }
                            required
                        />
                    </Form.Group>

                    <div className="d-grid mb-4">
                        <Button
                            type="submit"
                            variant="primary"
                            className="py-2"
                            disabled={loading}
                        >
                            {loading && (
                                <span className="spinner-border spinner-border-sm me-2" />
                            )}
                            {t('pages.auth.signup.submit')}
                        </Button>
                    </div>

                    <div className="text-center">
                        <p className="small text-muted mb-0">
                            {t('pages.auth.signup.loginPrompt')}{' '}
                            <Link to="/login" className="text-decoration-none">
                                {t('pages.auth.signup.loginLink')}
                            </Link>
                        </p>
                    </div>
                </Form>
            </Card.Body>

            <Card.Footer className="bg-soft-light py-3 text-center">
                <p className="small text-muted mb-0">
                    &copy; Wecodex Solutions |{' '}
                    <Link to='/terms-and-conditions' className="text-decoration-none">
                        {t('layout.footer.copyright')}
                    </Link>
                </p>
            </Card.Footer>
        </Card>
    );
}

export default Register;