import React, {useState, useEffect} from 'react';
import {Container} from 'react-bootstrap';
import {Outlet} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import ThemeControl from './components/ThemeControl';
import './styles/base.css'

const SIDEBAR_STATE_KEY = 'sidebarState';

const Layout = () => {
    const [sidebarOpen, setSidebarOpen] = useState(() => {
        const savedState = localStorage.getItem(SIDEBAR_STATE_KEY);
        return savedState ? JSON.parse(savedState) : true;
    });
    const [isOpenSearch, setIsOpenSearch] = useState(false);

    const theme = useSelector((state) => state.theme);

    useEffect(() => {
        localStorage.setItem(SIDEBAR_STATE_KEY, JSON.stringify(sidebarOpen));
    }, [sidebarOpen]);

    const toggleSidebar = () => setSidebarOpen(!sidebarOpen);
    const toggleSearch = () => setIsOpenSearch(!isOpenSearch);

    const backgroundStyles = theme.background.type === 'image'
        ? {
            backgroundImage: `url(${theme.background.value})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            opacity: theme.background.opacity,
        }
        : {
            background: theme.background.value,
            opacity: theme.background.opacity,
        };

    let mainLayout = theme.layout === 'boxed' ? 'px-2' : 'p-4';
    let containerLayout = theme.layout === 'boxed' ? 'p-4' : 'p-0';

    return (
        <div className={`min-vh-100 bg-dark ${!sidebarOpen ? 'sidebar-closed' : ''}`}>
            {/* Capa de fondo */}
            <div className="position-fixed w-100 h-100" style={backgroundStyles} />

            {/* Contenido principal */}
            <div className={`layout-${theme.layout}`}>
                <Header
                    sidebarOpen={sidebarOpen}
                    toggleSidebar={toggleSidebar}
                    toggleSearch={toggleSearch}
                />

                <Container fluid className={containerLayout}>
                    <div className="d-flex">
                        <Sidebar
                            isOpen={sidebarOpen}
                            isOpenSearch={isOpenSearch}
                        />

                        <main className={`flex-grow-1 ${mainLayout}`}>
                            <Outlet />
                        </main>
                    </div>
                </Container>

                <Footer />
                <ThemeControl />
            </div>

            {/* Overlay para móviles */}
            {sidebarOpen && window.innerWidth < 992 && (
                <div
                    className="sidebar-overlay"
                    onClick={toggleSidebar}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        backdropFilter: 'blur(2px)',
                        zIndex: 1015
                    }}
                />
            )}
        </div>
    );
};

export default Layout;