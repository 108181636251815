import React from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '@store/slice/userSlice';
import Avatar from '@components/widgets/Avatar';
import SidebarMenu from './SidebarMenu';
import FavoriteApps from './FavoriteApps';
import Icon from "@components/elements/Icon";
import {Nav} from "react-bootstrap";

const Sidebar = ({ isOpen, isOpenSearch }) => {
    const user = useSelector(selectUser);
    const theme = useSelector((state) => state.theme);
    const isCompact = theme.layout === 'compact';

    return (
        <div className={`sidebar-wrapper ${!isOpen ? 'collapsed' : ''} ${isCompact ? 'compact' : ''}`}>
            <div className='navbar-expand-lg'>
                <div id='sidebarNav' className='collapse navbar-collapse navbar-vertical show'>
                    <div className='card  flex-grow-1 mb-5'>
                        <div className='p-2'>
                            {/* Profile Section - Ajustado para modo compacto */}
                            <div className="profile-container border bg-soft-primary border-bottom-shadow border-2"
                                 style={{borderRadius: isCompact ? '10px' : '20px'}}>
                                <div className={`d-flex ${isCompact ? 'justify-content-center' : 'align-items-center gap-2'} p-3`}>
                                    <div className='avatar-wrapper position-relative'>
                                        <Avatar
                                            name={user.name}
                                            size={isCompact ? 40 : 45}
                                            className="border-bottom-shadow"
                                        />
                                        {!isCompact && (
                                            <img
                                                className='avatar-status'
                                                src='/assets/svg/illustrations/top-vendor.svg'
                                                alt='Verified'
                                                style={{
                                                    position: 'absolute',
                                                    bottom: "0",
                                                    right: "0",
                                                    width: "20px",
                                                    height: "20px"
                                                }}
                                            />
                                        )}
                                    </div>
                                    {!isCompact && (
                                        <div className="user-info">
                                            <h6 className="text-white mb-0">{user.name}</h6>
                                            <p className="small text-white-50 mb-0">{user.email}</p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Search Bar - Solo visible en modo normal */}
                            {!isCompact && (
                                <div className="search-container mt-3">
                                    <form className='position-relative m-0'>
                                        <div className='input-group input-group-merge'>
                                            <div className='input-group-prepend input-group-text bg-dark rounded-start'>
                                                <Icon name='bi-search' className="text-white" />

                                            </div>
                                            <input
                                                type='search'
                                                className='form-control form-control-sm bg-dark text-white'
                                                placeholder='Buscas un módulo?'
                                                aria-label='Search modules'
                                            />
                                        </div>
                                    </form>
                                </div>
                            )}

                            {/* Menu Principal */}
                            <div className={`sidebar-menu ${isCompact ? 'mt-4' : 'mt-3'}`}>
                                <SidebarMenu />
                            </div>

                            {/* Tools Section - Solo visible en modo normal */}
                            {!isCompact && (
                                <div className="tools-section mt-4">
                                    <span className='text-cap small fw-bold text-muted mb-2 d-block'>
                                        HERRAMIENTAS
                                    </span>
                                    <FavoriteApps
                                        includeDescription={false}
                                        textAlign={false}
                                        colSize='col-6'
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;