import React, { useState } from 'react';
import { Card, Row, Col, Form, Button, Nav, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    Bell,
    Globe,
    Shield,
    Moon,
    Envelope,
    Person,
    Gear,
    Terminal
} from 'react-bootstrap-icons';

const Settings = () => {
    const { t } = useTranslation();
    const [settings, setSettings] = useState({
        profile: {
            emailVisibility: 'public',
            activityStatus: true,
            profileDiscovery: true,
            twoFactorAuth: false
        },
        notifications: {
            pushNotifications: true,
            emailDigest: 'daily',
            productUpdates: true,
            securityAlerts: true,
            marketingEmails: false
        },
        appearance: {
            theme: 'system',
            density: 'comfortable',
            codeFont: 'mono',
            reducedMotion: false
        },
        accessibility: {
            screenReader: false,
            keyboardNav: true,
            highContrast: false
        }
    });

    const handleSettingChange = (category, setting, value) => {
        setSettings(prev => ({
            ...prev,
            [category]: {
                ...prev[category],
                [setting]: value
            }
        }));
    };

    const handleSave = () => {
        console.log('Settings saved:', settings);
        // Aquí iría la lógica para guardar
    };

    return (
        <div className="settings-container">
            <Card>
                <Card.Body className="p-0">
                    <Tab.Container defaultActiveKey="profile">
                        <Row className="g-0">
                            {/* Sidebar de navegación */}
                            <Col md={3} className="border-end">
                                <div className="p-4">
                                    <h4 className="mb-4 text-white">{t('pages.panel.settings.title')}</h4>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="profile" className="d-flex align-items-center gap-2">
                                                <Person /> {t('pages.panel.settings.tabs.profile')}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="notifications" className="d-flex align-items-center gap-2">
                                                <Bell /> {t('pages.panel.settings.tabs.notifications')}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="appearance" className="d-flex align-items-center gap-2">
                                                <Moon /> {t('pages.panel.settings.tabs.appearance')}
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="accessibility" className="d-flex align-items-center gap-2">
                                                <Gear /> {t('pages.panel.settings.tabs.accessibility')}
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </Col>

                            {/* Contenido principal */}
                            <Col md={9}>
                                <Tab.Content className="p-4">
                                    {/* Sección de Perfil */}
                                    <Tab.Pane eventKey="profile">
                                        <div className="mb-4">
                                            <h5 className="mb-4">{t('pages.panel.settings.profile.title')}</h5>

                                            <Form.Group className="mb-4">
                                                <Form.Label>{t('pages.panel.settings.profile.emailVisibility')}</Form.Label>
                                                <Form.Select
                                                    value={settings.profile.emailVisibility}
                                                    onChange={(e) => handleSettingChange('profile', 'emailVisibility', e.target.value)}
                                                    className="w-full md:w-1/2"
                                                >
                                                    <option value="public">Public</option>
                                                    <option value="private">Private</option>
                                                    <option value="contacts">Contacts Only</option>
                                                </Form.Select>
                                            </Form.Group>

                                            <div className="d-flex flex-column gap-4">
                                                <Form.Check
                                                    type="switch"
                                                    id="activity-status"
                                                    label={t('pages.panel.settings.profile.activityStatus')}
                                                    checked={settings.profile.activityStatus}
                                                    onChange={(e) => handleSettingChange('profile', 'activityStatus', e.target.checked)}
                                                />
                                                <Form.Check
                                                    type="switch"
                                                    id="profile-discovery"
                                                    label={t('pages.panel.settings.profile.profileDiscovery')}
                                                    checked={settings.profile.profileDiscovery}
                                                    onChange={(e) => handleSettingChange('profile', 'profileDiscovery', e.target.checked)}
                                                />
                                                <Form.Check
                                                    type="switch"
                                                    id="two-factor"
                                                    label={t('pages.panel.settings.profile.twoFactorAuth')}
                                                    checked={settings.profile.twoFactorAuth}
                                                    onChange={(e) => handleSettingChange('profile', 'twoFactorAuth', e.target.checked)}
                                                />
                                            </div>
                                        </div>
                                    </Tab.Pane>

                                    {/* Sección de Notificaciones */}
                                    <Tab.Pane eventKey="notifications">
                                        <div className="mb-4">
                                            <h5 className="mb-4">{t('pages.panel.settings.notifications.title')}</h5>

                                            <Form.Group className="mb-4">
                                                <Form.Label>{t('pages.panel.settings.notifications.emailDigest')}</Form.Label>
                                                <Form.Select
                                                    value={settings.notifications.emailDigest}
                                                    onChange={(e) => handleSettingChange('notifications', 'emailDigest', e.target.value)}
                                                    className="w-full md:w-1/2"
                                                >
                                                    <option value="daily">Daily</option>
                                                    <option value="weekly">Weekly</option>
                                                    <option value="never">Never</option>
                                                </Form.Select>
                                            </Form.Group>

                                            <div className="d-flex flex-column gap-4">
                                                {Object.entries(settings.notifications)
                                                    .filter(([key]) => key !== 'emailDigest')
                                                    .map(([key, value]) => (
                                                        <Form.Check
                                                            key={key}
                                                            type="switch"
                                                            id={`notification-${key}`}
                                                            label={t(`pages.panel.settings.notifications.${key}`)}
                                                            checked={value}
                                                            onChange={(e) => handleSettingChange('notifications', key, e.target.checked)}
                                                        />
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </Tab.Pane>

                                    {/* Sección de Apariencia */}
                                    <Tab.Pane eventKey="appearance">
                                        <div className="mb-4">
                                            <h5 className="mb-4">{t('pages.panel.settings.appearance.title')}</h5>

                                            <Form.Group className="mb-4">
                                                <Form.Label>{t('pages.panel.settings.appearance.theme')}</Form.Label>
                                                <Form.Select
                                                    value={settings.appearance.theme}
                                                    onChange={(e) => handleSettingChange('appearance', 'theme', e.target.value)}
                                                    className="w-full md:w-1/2"
                                                >
                                                    <option value="system">System</option>
                                                    <option value="light">Light</option>
                                                    <option value="dark">Dark</option>
                                                </Form.Select>
                                            </Form.Group>

                                            <Form.Group className="mb-4">
                                                <Form.Label>{t('pages.panel.settings.appearance.density')}</Form.Label>
                                                <Form.Select
                                                    value={settings.appearance.density}
                                                    onChange={(e) => handleSettingChange('appearance', 'density', e.target.value)}
                                                    className="w-full md:w-1/2"
                                                >
                                                    <option value="comfortable">Comfortable</option>
                                                    <option value="compact">Compact</option>
                                                </Form.Select>
                                            </Form.Group>

                                            <div className="d-flex flex-column gap-4">
                                                <Form.Check
                                                    type="switch"
                                                    id="reduced-motion"
                                                    label={t('pages.panel.settings.appearance.reducedMotion')}
                                                    checked={settings.appearance.reducedMotion}
                                                    onChange={(e) => handleSettingChange('appearance', 'reducedMotion', e.target.checked)}
                                                />
                                            </div>
                                        </div>
                                    </Tab.Pane>

                                    {/* Sección de Accesibilidad */}
                                    <Tab.Pane eventKey="accessibility">
                                        <div className="mb-4">
                                            <h5 className="mb-4">{t('pages.panel.settings.accessibility.title')}</h5>

                                            <div className="d-flex flex-column gap-4">
                                                {Object.entries(settings.accessibility).map(([key, value]) => (
                                                    <Form.Check
                                                        key={key}
                                                        type="switch"
                                                        id={`accessibility-${key}`}
                                                        label={t(`pages.panel.settings.accessibility.${key}`)}
                                                        checked={value}
                                                        onChange={(e) => handleSettingChange('accessibility', key, e.target.checked)}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>


                            <Col md={12} className="border-end p-4">

                            <div className="d-flex justify-content-end gap-2 mt-4">
                                    <Button variant="outline-secondary">
                                        {t('pages.panel.settings.buttons.cancel')}
                                    </Button>
                                    <Button variant="primary" onClick={handleSave}>
                                        {t('pages.panel.settings.buttons.save')}
                                    </Button>
                                </div>
                            </Col>
                        </Row>



                    </Tab.Container>
                </Card.Body>
            </Card>

            {/* Botones de acción */}

        </div>
    );
};

export default Settings;