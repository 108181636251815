import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';

import privateRoutes from '@/navigation/private';
import publicRoutes from '@/navigation/public';

import AuthLayout from '@components/layouts/auth/AuthLayout';
import Layout from '@components/layouts/panel/Layout';
import ErrorPage from "@pages/ErrorPage";

import { selectIsLoggedIn, selectToken } from './store/slice/authSlice';
import { selectUser } from './store/slice/userSlice';
import { AuthProvider, useAuth } from "./context/AuthContext";

const AuthWrapper = ({children}) => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const token = useSelector(selectToken);
    const user = useSelector(selectUser);
    const location = useLocation();
    const { isReadyForRedirect } = useAuth();

    if (location.pathname.startsWith('/panel/analytics')) {
        if (!isLoggedIn || !token || !user) {
            return <Navigate to="/login" replace/>;
        }
    }

    if (isReadyForRedirect && (location.pathname.startsWith('/login') ||
        location.pathname.startsWith('/register') ||
        location.pathname.startsWith('/forgot-password'))) {
        if (isLoggedIn && token && user) {
            return <Navigate to="/panel/analytics" replace/>;
        }
    }

    return children;
};

const ProtectedRoute = ({children}) => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const token = useSelector(selectToken);
    const user = useSelector(selectUser);

    if (!isLoggedIn || !token || !user) {
        return <Navigate to="/login" replace/>;
    }

    return children;
};

function App() {
    const generatePrivateRoutes = () => {
        const allRoutes = [];

        privateRoutes.forEach(section => {
            section.items.forEach(item => {
                if (item.subItems) {
                    item.subItems.forEach(subItem => {
                        if (subItem.element) {
                            allRoutes.push(
                                <Route
                                    key={subItem.path}
                                    path={subItem.path}
                                    element={
                                        <ProtectedRoute>
                                            {subItem.element}
                                        </ProtectedRoute>
                                    }
                                />
                            );
                        }
                    });
                } else if (item.element) {
                    allRoutes.push(
                        <Route
                            key={item.path}
                            path={item.path}
                            element={
                                <ProtectedRoute>
                                    {item.element}
                                </ProtectedRoute>
                            }
                        />
                    );
                }
            });
        });

        return allRoutes;
    };

    const generatePublicRoutes = () => {
        return publicRoutes.map(route => (
            <Route
                key={route.path}
                path={route.path}
                element={route.element}
            />
        ));
    };

    return (
        <HelmetProvider>
            <AuthProvider>
                <AuthWrapper>
                    <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                        limit={3}
                    />
                    <Routes>
                        <Route element={<AuthLayout />}>
                            {generatePublicRoutes()}
                        </Route>
                        <Route element={<Layout />}>
                            {generatePrivateRoutes()}
                        </Route>
                        <Route path="*" element={<ErrorPage />} />
                    </Routes>
                </AuthWrapper>
            </AuthProvider>
        </HelmetProvider>
    );
}

export default App;