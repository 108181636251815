import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser, setSessionUser } from '@store/slice/userSlice';
import api from '@services/api';
import Select from '@components/elements/Select';
import { toast } from 'react-toastify';
import {
    Card,
    Form,
    Button,
    Alert,
    Container,
    Row,
    Col,
    InputGroup,
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap';
import { ExclamationTriangleFill, QuestionCircle } from 'react-bootstrap-icons';


const Basic = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const user = useSelector(selectUser);

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [avatar, setAvatar] = useState(user?.avatar || '/assets/img/160x160/img9.jpg');
    const [formData, setFormData] = useState({
        name: user?.name || '',
        email: user?.email || '',
        phone: user?.phone || '',
        country_id: Number(user?.country_id) || '',
        gender: user?.gender || 'other',
    });

    useEffect(() => {
        if (user) {
            setFormData({
                name: user.name || '',
                email: user.email || '',
                phone: user.phone || '',
                country_id: Number(user.country_id) || '',
                gender: user.gender || 'other'
            });
            if (user.avatar) {
                setAvatar(user.avatar);
            }
        }
    }, [user]);

    const handleDeleteAvatar = () => {
        setAvatar('/assets/img/160x160/img1.jpg');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            setError(null);
            setSuccessMessage('');

            toast.success('Profile updated');
        } catch (err) {

        } finally {
            setIsLoading(false);
        }
    };

    const handleCountryChange = (value) => {
        setFormData(prev => ({
            ...prev,
            country_id: Number(value)
        }));
    };

    if (!user) {
        return (
            <Container className="text-center p-5">
                {t('pages.panel.profile.messages.loading')}
            </Container>
        );
    }

    return (
        <Container>
            <Row className="mb-5">
                <Col lg={12}>
                    <div className="d-grid gap-3 gap-lg-5">
                        {/* Basic Information Card */}
                        <Card>
                            <Card.Header className="border-bottom">
                                <Card.Title>{t('pages.panel.profile.basic.title')}</Card.Title>
                            </Card.Header>

                            <Card.Body>
                                <Form>
                                    <Alert variant="warning" className="alert alert-outline-warning">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0">
                                                <ExclamationTriangleFill />
                                            </div>
                                            <div className="flex-grow-1 ms-2">
                                                <strong>Information:</strong> Alert with icon
                                            </div>
                                        </div>
                                    </Alert>

                                    <Form.Group as={Row} className="mb-4">
                                        <Form.Label column sm={3} className="form-label">
                                            {t('pages.panel.profile.basic.fullName.label')}
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={
                                                    <Tooltip>
                                                        {t('pages.panel.profile.basic.fullName.tooltip')}
                                                    </Tooltip>
                                                }
                                            >
                                                <QuestionCircle className="text-body ms-1" />
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Form.Control
                                                type="text"
                                                value={formData.name}
                                                onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-4">
                                        <Form.Label column sm={3}>
                                            {t('pages.panel.profile.basic.email.label')}
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Form.Control
                                                type="email"
                                                value={formData.email}
                                                disabled
                                            />
                                        </Col>
                                    </Form.Group>

                                    <Form.Group as={Row} className="mb-4">
                                        <Form.Label column sm={3}>
                                            {t('pages.panel.profile.basic.phone.label')}
                                            <span className="text-muted ms-1">
                        {t('pages.panel.profile.basic.phone.optional')}
                      </span>
                                        </Form.Label>
                                        <Col sm={4}>
                                            <Form.Control
                                                type="tel"
                                                value={formData.phone}
                                                onChange={e => setFormData(prev => ({ ...prev, phone: e.target.value }))}
                                            />
                                        </Col>

                                    </Form.Group>
                                </Form>
                            </Card.Body>

                            <Card.Footer className="pt-0">
                                <div className="d-flex justify-content-end gap-3">
                                    <Button variant="light">
                                        {t('pages.panel.profile.buttons.cancel')}
                                    </Button>
                                    <Button
                                        variant="success"
                                        onClick={()=>handleSubmit}
                                        disabled={isLoading}
                                    >
                                        {t('pages.panel.profile.buttons.save')}
                                    </Button>
                                </div>
                            </Card.Footer>
                        </Card>

                        {/* Delete Account Card */}
                        <Card>
                            <Card.Header className="border-bottom">
                                <Card.Title>{t('pages.panel.profile.delete.title')}</Card.Title>
                            </Card.Header>

                            <Card.Body>
                                <Card.Text>{t('pages.panel.profile.delete.description')}</Card.Text>

                                <Form.Group className="mb-4">
                                    <Form.Check
                                        type="checkbox"
                                        id="deleteAccountCheckbox"
                                        label={t('pages.panel.profile.delete.confirm')}
                                    />
                                </Form.Group>

                                <div className="d-flex justify-content-end">
                                    <Button variant="danger">
                                        {t('pages.panel.profile.delete.button')}
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Basic;