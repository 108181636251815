import React, { useState, useEffect } from 'react';
import { Button, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '@/components/elements/Icon';
import {
    toggleThemeMode,
    updatePrimaryColor,
    updateSecondaryColor,
    updateBackground,
    updateCardsBackground,
    updateCardsOpacity,
    updateCardStyle,
    updateLayout,
    THEME_COLORS,
    BACKGROUNDS
} from '@/store/slice/themeSlice';

// Definición de las secciones del tema
const THEME_SECTIONS = [
    {
        id: 'theme',
        label: 'Tema',
        icon: 'palette2',
        description: 'Modo claro/oscuro y colores'
    },
    {
        id: 'layout',
        label: 'Layout',
        icon: 'grid-1x2',
        description: 'Configuración del layout principal'
    },
    {
        id: 'cards',
        label: 'Cards',
        icon: 'card-text',
        description: 'Personalización de tarjetas'
    },
    {
        id: 'background',
        label: 'Background',
        icon: 'image',
        description: 'Fondos y patrones'
    }
];

// Opciones de layout
const LAYOUT_OPTIONS = [
    {
        id: 'default',
        label: 'Default',
        icon: 'grid',
        description: 'Layout clásico con sidebar'
    },
    {
        id: 'compact',
        label: 'Compact',
        icon: 'layout-sidebar-inset',
        description: 'Sidebar minimizada con iconos'
    },
    {
        id: 'wide',
        label: 'Wide',
        icon: 'layout-three-columns',
        description: 'Layout a ancho completo'
    },
    {
        id: 'boxed',
        label: 'Boxed',
        icon: 'box',
        description: 'Layout centrado en caja'
    }
];

// Estilos de tarjetas
const CARD_STYLES = [
    {
        id: 'default',
        label: 'Default',
        icon: 'square',
        description: 'Estilo básico',
        preview: 'bg-dark'
    },
    {
        id: 'floating',
        label: 'Floating',
        icon: 'layers',
        description: 'Con sombra flotante',
        preview: 'shadow-lg'
    },
    {
        id: 'bordered',
        label: 'Bordered',
        icon: 'bounding-box',
        description: 'Con bordes definidos',
        preview: 'border border-2'
    },
    {
        id: 'glass',
        label: 'Glass',
        icon: 'window',
        description: 'Efecto cristal',
        preview: 'bg-opacity-25 backdrop-blur'
    }
];

const ThemeControl = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentSection, setCurrentSection] = useState(null);
    const [backgroundTab, setBackgroundTab] = useState('images');
    const dispatch = useDispatch();
    const theme = useSelector((state) => state.theme);

    // Efecto para actualizar las variables CSS cuando cambian los colores
    useEffect(() => {
        const root = document.documentElement;
        // Actualizar colores primarios
        root.style.setProperty('--bs-primary', theme.primaryColor);
        root.style.setProperty('--primary-color', theme.primaryColor);

        // Convertir color hex a RGB y actualizar
        const primaryRgb = hexToRgb(theme.primaryColor);
        if (primaryRgb) {
            const rgbString = `${primaryRgb.r}, ${primaryRgb.g}, ${primaryRgb.b}`;
            root.style.setProperty('--bs-primary-rgb', rgbString);
            root.style.setProperty('--primary-rgb', rgbString);
        }

        // Actualizar colores secundarios
        root.style.setProperty('--bs-secondary', theme.secondaryColor);
        root.style.setProperty('--secondary-color', theme.secondaryColor);

        const secondaryRgb = hexToRgb(theme.secondaryColor);
        if (secondaryRgb) {
            const rgbString = `${secondaryRgb.r}, ${secondaryRgb.g}, ${secondaryRgb.b}`;
            root.style.setProperty('--bs-secondary-rgb', rgbString);
            root.style.setProperty('--secondary-rgb', rgbString);
        }
    }, [theme.primaryColor, theme.secondaryColor]);

    // Efecto para aplicar el modo de tema
    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme.mode);
    }, [theme.mode]);

    // Efecto para aplicar el layout
    useEffect(() => {
        document.documentElement.setAttribute('data-layout', theme.layout);
    }, [theme.layout]);

    // Función auxiliar para convertir hex a RGB
    const hexToRgb = (hex) => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : null;
    };

    // Render de la sección de tema
    const renderThemeSection = () => (
        <div className="p-3">
            {/* Theme Mode Toggle */}
            <div className="mb-4">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <label className="form-label mb-0">Modo del Tema</label>
                    <Button
                        variant={theme.mode === 'dark' ? 'light' : 'dark'}
                        size="sm"
                        onClick={() => dispatch(toggleThemeMode())}
                    >
                        <Icon name={theme.mode === 'dark' ? 'sun' : 'moon'} className="me-2" />
                        {theme.mode === 'dark' ? 'Modo Claro' : 'Modo Oscuro'}
                    </Button>
                </div>
            </div>

            {/* Color Selection */}
            <div className="mb-4">
                <label className="form-label d-block mb-2">Color Primario</label>
                <div className="row g-2">
                    {Object.entries(THEME_COLORS.primary).map(([name, color]) => (
                        <div key={name} className="col-3">
                            <OverlayTrigger placement="top" overlay={<Tooltip>{name}</Tooltip>}>
                                <button
                                    className={`color-option rounded-circle border-0 ${
                                        theme.primaryColor === color ? 'active' : ''
                                    }`}
                                    style={{
                                        backgroundColor: color,
                                        width: '40px',
                                        height: '40px',
                                        cursor: 'pointer',
                                        position: 'relative'
                                    }}
                                    onClick={() => dispatch(updatePrimaryColor(color))}
                                >
                                    {theme.primaryColor === color && (
                                        <Icon
                                            name="check"
                                            className="text-white position-absolute"
                                            style={{
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)'
                                            }}
                                        />
                                    )}
                                </button>
                            </OverlayTrigger>
                        </div>
                    ))}
                </div>
            </div>

            <div className="mb-4">
                <label className="form-label d-block mb-2">Color Secundario</label>
                <div className="row g-2">
                    {Object.entries(THEME_COLORS.secondary).map(([name, color]) => (
                        <div key={name} className="col-3">
                            <OverlayTrigger placement="top" overlay={<Tooltip>{name}</Tooltip>}>
                                <button
                                    className={`color-option rounded-circle border-0 ${
                                        theme.secondaryColor === color ? 'active' : ''
                                    }`}
                                    style={{
                                        backgroundColor: color,
                                        width: '40px',
                                        height: '40px',
                                        cursor: 'pointer',
                                        position: 'relative'
                                    }}
                                    onClick={() => dispatch(updateSecondaryColor(color))}
                                >
                                    {theme.secondaryColor === color && (
                                        <Icon
                                            name="check"
                                            className="text-white position-absolute"
                                            style={{
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)'
                                            }}
                                        />
                                    )}
                                </button>
                            </OverlayTrigger>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    // Render de la sección de layout
    const renderLayoutSection = () => (
        <div className="p-3">
            <div className="mb-4">
                <label className="form-label d-block mb-2">Tipo de Layout</label>
                <div className="row g-2">
                    {LAYOUT_OPTIONS.map((layout) => (
                        <div key={layout.id} className="col-6">
                            <div
                                className={`layout-option p-3 rounded cursor-pointer ${
                                    theme.layout === layout.id ? 'bg-primary' : 'bg-secondary bg-opacity-10'
                                }`}
                                onClick={() => dispatch(updateLayout(layout.id))}
                            >
                                <div className="d-flex align-items-center mb-2">
                                    <Icon name={layout.icon} className="me-2" />
                                    <span className="small fw-bold">{layout.label}</span>
                                </div>
                                <p className="small mb-0 text-muted">
                                    {layout.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );

    // Render de la sección de cards
    const renderCardsSection = () => (
        <div className="p-3">
            <div className="mb-4">
                <label className="form-label d-block mb-2">Estilo de Cards</label>
                <div className="row g-2">
                    {CARD_STYLES.map((style) => (
                        <div key={style.id} className="col-6">
                            <div
                                className={`card-style-option p-3 rounded cursor-pointer ${
                                    theme.cardStyle === style.id ? 'bg-primary' : 'bg-secondary bg-opacity-10'
                                }`}
                                onClick={() => dispatch(updateCardStyle(style.id))}
                            >
                                <div className="d-flex align-items-center mb-2">
                                    <Icon name={style.icon} className="me-2" />
                                    <span className="small fw-bold">{style.label}</span>
                                </div>
                                <p className="small mb-0 text-muted">
                                    {style.description}
                                </p>
                                <div className={`mt-2 rounded p-2 ${style.preview}`}>
                                    <div className="bg-secondary bg-opacity-10" style={{ height: '20px' }}></div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <Form.Group className="mb-3">
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <Form.Label className="mb-0">Opacidad de Cards</Form.Label>
                    <span className="text-muted small">
                        {Math.round(theme.cardsOpacity * 100)}%
                    </span>
                </div>
                <Form.Range
                    min={0}
                    max={1}
                    step={0.01}
                    value={theme.cardsOpacity}
                    onChange={(e) => dispatch(updateCardsOpacity(parseFloat(e.target.value)))}
                />
            </Form.Group>

            {theme.cardStyle === 'glass' && (
                <Form.Group className="mb-3">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <Form.Label className="mb-0">Intensidad del Blur</Form.Label>
                        <span className="text-muted small">
                            {Math.round(theme.cardsBackground.blur)}px
                        </span>
                    </div>
                    <Form.Range
                        min={0}
                        max={20}
                        step={1}
                        value={theme.cardsBackground.blur}
                        onChange={(e) => dispatch(updateCardsBackground({ blur: parseInt(e.target.value) }))}
                    />
                </Form.Group>
            )}
        </div>
    );

    // Render de la sección de background
    const renderBackgroundSection = () => {
        const renderBackgroundOptions = (options, type) => (
            <div className="row g-2">
                {options.map((option, index) => (
                    <div key={index} className="col-4">
                        <div
                            className={`theme-option-card ${theme.background.value === option.value ? 'active' : ''}`}
                            style={{
                                height: '60px',
                                cursor: 'pointer',
                                ...(type === 'image'
                                    ? {
                                        backgroundImage: `url(${option.value})`,
                                        backgroundSize: 'cover',
                                        backgroundPosition: 'center'
                                    }
                                    : { background: option.value })
                            }}
                            onClick={() => dispatch(updateBackground({ type, value: option.value }))}
                            title={option.label}
                        />
                    </div>
                ))}
            </div>
        );

        return (
            <div className="p-3">
                <div className="mb-3">
                    <div className="btn-group w-100 mb-3">
                        <Button
                            variant={backgroundTab === 'images' ? 'primary' : 'dark'}
                            onClick={() => setBackgroundTab('images')}
                        >
                            <Icon name="image" className="me-2" />
                            Imágenes
                        </Button>
                        <Button
                            variant={backgroundTab === 'gradients' ? 'primary' : 'dark'}
                            onClick={() => setBackgroundTab('gradients')}
                        >
                            <Icon name="brush" className="me-2" />
                            Degradados
                        </Button>
                        <Button
                            variant={backgroundTab === 'solid' ? 'primary' : 'dark'}
                            onClick={() => setBackgroundTab('solid')}
                        >
                            <Icon name="circle-fill" className="me-2" />
                            Sólidos
                        </Button>
                    </div>

                    {backgroundTab === 'images' && renderBackgroundOptions(BACKGROUNDS.images, 'image')}
                    {backgroundTab === 'gradients' && renderBackgroundOptions(BACKGROUNDS.gradients, 'gradient')}
                    {backgroundTab === 'solid' && renderBackgroundOptions(BACKGROUNDS.solid, 'solid')}
                </div>

                <Form.Group className="mb-3">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <Form.Label className="mb-0">Opacidad del Fondo</Form.Label>
                        <span className="text-muted small">
                            {Math.round(theme.background.opacity * 100)}%
                        </span>
                    </div>
                    <Form.Range
                        min={0}
                        max={1}
                        step={0.01}
                        value={theme.background.opacity}
                        onChange={(e) => dispatch(updateBackground({ opacity: parseFloat(e.target.value) }))}
                    />
                </Form.Group>
            </div>
        );
    };

    // Render del contenido principal
    const renderContent = () => {
        if (!currentSection) {
            return (
                <div className="p-3">
                    <h6 className="text-muted small mb-3">SELECCIONA UNA SECCIÓN</h6>
                    <div className="row g-2">
                        {THEME_SECTIONS.map((section) => (
                            <div key={section.id} className="col-12">
                                <Button
                                    variant="dark"
                                    className="w-100 text-start p-3 border-0 bg-opacity-25 hover-opacity-75"
                                    onClick={() => setCurrentSection(section.id)}
                                >
                                    <div className="d-flex align-items-center">
                                        <div className="theme-section-icon me-3">
                                            <Icon name={section.icon} size={24} />
                                        </div>
                                        <div>
                                            <h6 className="mb-1">{section.label}</h6>
                                            <small className="text-muted">{section.description}</small>
                                        </div>
                                        <Icon name="chevron-right" className="ms-auto" />
                                    </div>
                                </Button>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

        const currentSectionData = THEME_SECTIONS.find(s => s.id === currentSection);

        return (
            <>
                <div className="p-3 border-bottom border-secondary d-flex align-items-center">
                    <Button
                        variant="link"
                        className="text-white p-0 me-3"
                        onClick={() => setCurrentSection(null)}
                    >
                        <Icon name="arrow-left" size={20} />
                    </Button>
                    <h6 className="mb-0">{currentSectionData?.label}</h6>
                </div>

                {currentSection === 'theme' && renderThemeSection()}
                {currentSection === 'layout' && renderLayoutSection()}
                {currentSection === 'cards' && renderCardsSection()}
                {currentSection === 'background' && renderBackgroundSection()}
            </>
        );
    };

    return (
        <div className="theme-control">
            {/* Botón flotante */}
            <div className="position-fixed bottom-0 end-0 mb-4 me-4" style={{ zIndex: 1050 }}>
                <Button
                    variant="primary"
                    className="rounded-circle p-3 shadow-lg d-flex align-items-center justify-content-center"
                    onClick={() => setIsOpen(!isOpen)}
                    style={{ width: '56px', height: '56px' }}
                >
                    <Icon name={isOpen ? "x-lg" : "palette-fill"} size={24} />
                </Button>

                {isOpen && (
                    <div
                        className="position-absolute bottom-100 end-0 mb-3 bg-dark rounded-3 shadow-lg text-white"
                        style={{
                            width: '320px',
                            maxHeight: '80vh',
                            overflowY: 'auto'
                        }}
                    >
                        {renderContent()}
                    </div>
                )}
            </div>

            {/* Overlay cuando está abierto */}
            {isOpen && (
                <div
                    className="position-fixed top-0 start-0 w-100 h-100"
                    style={{
                        backgroundColor: 'rgba(0,0,0,0.2)',
                        backdropFilter: 'blur(2px)',
                        zIndex: 1040
                    }}
                    onClick={() => setIsOpen(false)}
                />
            )}
        </div>
    );
};

export default ThemeControl;