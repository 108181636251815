// components/elements/Icon.jsx
import React from 'react';
import * as BootstrapIcons from 'react-bootstrap-icons';

const Icon = ({ name, className, size = 18, ...props }) => {
    // Función para convertir nombre de ícono de bootstrap a componente
    const getIconComponent = (iconName) => {
        if (!iconName) return null;

        // Eliminar el prefijo 'bi-' si existe
        const cleanName = iconName.replace('bi-', '');

        // Convertir kebab-case a PascalCase
        const componentName = cleanName
            .split('-')
            .map(part => part.charAt(0).toUpperCase() + part.slice(1))
            .join('');

        return BootstrapIcons[componentName];
    };

    const IconComponent = getIconComponent(name);

    if (!IconComponent) {
        console.warn(`Icon '${name}' not found in react-bootstrap-icons`);
        return null;
    }

    return (
        <IconComponent
            size={size}
            className={className}
            {...props}
        />
    );
};

export default Icon;