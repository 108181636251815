import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { selectUser } from '@store/slice/userSlice';
import Avatar from '@components/widgets/Avatar';
import FavoriteApps from './FavoriteApps';
import { removeSessionAuth } from '@store/slice/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LanguageSelector from "@components/widgets/LanguageSelector";
import { Navbar, Container, Row, Col } from "react-bootstrap";
import {
    List,
    BellFill,
    ChevronUp,
    ChevronDown,
    Person,
    Gear,
    ClockHistory,
    QuestionCircle,
    BoxArrowRight,
    InfoCircleFill,
    CheckCircleFill,
    ExclamationCircleFill
} from 'react-bootstrap-icons';
import Icon from "@components/elements/Icon";

const Header = ({ sidebarOpen, toggleSidebar, toggleSearch }) => {

    const { t } = useTranslation();
    const user = useSelector(selectUser);
    const [showNotifications, setShowNotifications] = useState(false);
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const notificationsRef = useRef(null);
    const profileRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleLogout = async () => {
        try {
            await dispatch(removeSessionAuth()).unwrap();
            // Después de eliminar la sesión, redirigir al login
            navigate('/login');
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const notifications = [
        {
            id: 1,
            type: 'info',
            title: t('components.layout.header.notifications.types.update'),
            message: t('components.layout.header.notifications.messages.update'),
            time: t('components.layout.header.notifications.time.recent'),
            icon: <InfoCircleFill className="text-primary" />
        },
        {
            id: 2,
            type: 'success',
            title: t('components.layout.header.notifications.types.project'),
            message: t('components.layout.header.notifications.messages.project'),
            time: t('components.layout.header.notifications.time.hour'),
            icon: <CheckCircleFill className="text-success" />
        },
        {
            id: 3,
            type: 'warning',
            title: t('components.layout.header.notifications.types.storage'),
            message: t('components.layout.header.notifications.messages.storage'),
            time: t('components.layout.header.notifications.time.hours'),
            icon: <ExclamationCircleFill className="text-warning" />
        }
    ];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (notificationsRef.current && !notificationsRef.current.contains(event.target)) {
                setShowNotifications(false);
            }
            if (profileRef.current && !profileRef.current.contains(event.target)) {
                setShowProfileMenu(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <Navbar className='navbar-dark border-bottom  border-black bg-dark border-bottom-shadow'
                style={{ backgroundImage: "url(/assets/svg/components/wave-pattern-light.svg)" }}>
            <Container fluid className='p-0'>
                <Row className='w-100 m-0 p-0 ps-5'>
                    {/* Logo Section */}
                    <Col md={2} className="p-0" style={{ minWidth: "300px" }}>
                        <div className='d-flex mx-1 mt-1 justify-content-between'>
                            <img src='/assets/img/logos/logo.png'
                                 style={{ height: "45px", width: "auto" }}
                                 alt='Logo' />
                            <a className='pt-2' onClick={toggleSidebar}>
                                {sidebarOpen ? (
                                    <Icon name="bi-layout-sidebar-inset" className="text-white" />
                                ) : (
                                    <Icon name="bi-layout-sidebar" className="text-white" />
                                )}
                            </a>
                        </div>
                    </Col>

                    {/* Navigation Section */}
                    <Col md={9}>
                        <div className='d-flex justify-content-between'>
                            {/* Tools Section */}
                            <div className=''>
                                <ul className='nav nav-segment p-0 m-0 mt-2 nav-sm'>
                                </ul>
                            </div>

                            {/* Notifications and Profile */}
                            <div className='d-flex align-items-center gap-3 pe-5'>
                                {/* LanguageSelector i18n */}
                                <LanguageSelector />

                                {/* Notifications */}
                                <div className='position-relative' ref={notificationsRef}>
                                    <button
                                        className='btn btn-link p-0 position-relative'
                                        onClick={() => setShowNotifications(!showNotifications)}
                                    >
                                        <BellFill className='text-white fs-5' />
                                        <span className='position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger'>
                                            {notifications.length}
                                        </span>
                                    </button>

                                    {/* Notifications Dropdown */}
                                    {showNotifications && (
                                        <div className='dropdown-menu dropdown-menu-end show py-0'
                                             style={{
                                                 width: '320px',
                                                 marginTop: '0.5rem',
                                                 boxShadow: '0 0.5rem 1rem rgba(0,0,0,0.15)',
                                                 border: '1px solid rgba(0,0,0,0.1)',
                                                 borderRadius: '0.5rem',
                                                 position: 'absolute',
                                                 right: 0,
                                                 top: '100%',
                                                 zIndex: 1000
                                             }}>
                                            <div className=' rounded-top p-3 border-bottom d-flex justify-content-between align-items-center'>
                                                <h6 className='mb-0 fw-bold'>{t('components.layout.header.notifications.title')}</h6>
                                                <span className='badge bg-primary rounded-pill'>
                                                    {notifications.length} {t('components.layout.header.notifications.new')}
                                                </span>
                                            </div>
                                            <div style={{ maxHeight: '350px', overflowY: 'auto', overflowX: 'hidden' }}>
                                                {notifications.map(notification => (
                                                    <div key={notification.id}
                                                         className='dropdown-item border-bottom p-3'
                                                         style={{ backgroundColor: 'transparent' }}>
                                                        <div className='d-flex align-items-start'>
                                                            <div className='flex-shrink-0 me-3'>
                                                                {notification.icon}
                                                            </div>
                                                            <div className='flex-grow-1 text-truncate'>
                                                                <h6 className='mb-1 fw-semibold text-truncate'>{notification.title}</h6>
                                                                <p className='mb-1  text-muted small text-wrap'>{notification.message}</p>
                                                                <small className='text-muted'>{notification.time}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='p-3 text-center border-top  rounded-bottom'>
                                                <Link to="/panel/notifications"
                                                      className='text-primary text-decoration-none fw-semibold'>
                                                    {t('components.layout.header.notifications.viewAll')}
                                                </Link>
                                            </div>
                                        </div>
                                    )}
                                </div>

                                {/* User Profile */}
                                <div className='position-relative' ref={profileRef}>
                                    <div
                                        className='d-flex align-items-center gap-2 p-2'
                                        onClick={() => setShowProfileMenu(!showProfileMenu)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <Avatar name={user.name} size={32} />
                                        <span className='text-white d-none d-sm-inline small'>
                                            {user.name}
                                        </span>
                                        {showProfileMenu ? <ChevronUp className="text-white" /> : <ChevronDown className="text-white" />}
                                    </div>

                                    {/* Profile Dropdown */}
                                    {showProfileMenu && (
                                        <div className='dropdown-menu dropdown-menu-end show py-0'
                                             style={{
                                                 minWidth: '240px',
                                                 marginTop: '0.5rem',
                                                 boxShadow: '0 0.5rem 1rem rgba(0,0,0,0.15)',
                                                 border: '1px solid rgba(0,0,0,0.1)',
                                                 borderRadius: '0.5rem',
                                                 position: 'absolute',
                                                 right: 0,
                                                 top: '100%',
                                                 zIndex: 1000
                                             }}>
                                            <div className=' p-3 border-bottom'>
                                                <div className='d-flex align-items-center'>
                                                    <Avatar name={user.name} size={35} />
                                                    <div className='ms-3'>
                                                        <h6 className='mb-1 fw-bold'>{user.name}</h6>
                                                        <p className='text-muted small mb-0'>{user.email}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='py-2'>
                                                <Link to="panel/profile-basic" className='dropdown-item px-4 py-2'>
                                                    <Person className='me-2' />
                                                    {t('components.layout.header.profile.menu.profile')}
                                                </Link>
                                                <Link to="/panel/security" className='dropdown-item px-4 py-2'>
                                                    <Gear className='me-2' />
                                                    {t('components.layout.header.profile.menu.settings')}
                                                </Link>
                                                <Link to="#" className='dropdown-item px-4 py-2'>
                                                    <ClockHistory className='me-2' />
                                                    {t('components.layout.header.profile.menu.activity')}
                                                </Link>
                                            </div>

                                            <div className='border-top py-2'>
                                                <Link to="#" className='dropdown-item px-4 py-2'>
                                                    <QuestionCircle className='me-2' />
                                                    {t('components.layout.header.profile.menu.help')}
                                                </Link>
                                                <button
                                                    onClick={handleLogout}
                                                    className='dropdown-item px-4 py-2 text-danger'
                                                    style={{ border: 'none', background: 'none', width: '100%', textAlign: 'left' }}
                                                >
                                                    <BoxArrowRight className='me-2' />
                                                    {t('components.layout.header.profile.menu.logout')}
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </Navbar>
    );
};

export default Header;