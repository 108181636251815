import React from 'react';
import { Container, Row, Col, Alert, Button, Card } from 'react-bootstrap';
import {
    InfoCircle,
    CheckCircle,
    ExclamationTriangle,
    XCircle,
    Bell,
    ShieldCheck,
    Lightning,
    Stars
} from 'react-bootstrap-icons';

const Alerts = () => {
    // Alert types configuration
    const basicAlerts = [
        { variant: 'primary', icon: <InfoCircle />, message: 'This is a primary alert — check it out!' },
        { variant: 'secondary', icon: <Bell />, message: 'This is a secondary alert — check it out!' },
        { variant: 'success', icon: <CheckCircle />, message: 'This is a success alert — check it out!' },
        { variant: 'danger', icon: <XCircle />, message: 'This is a danger alert — check it out!' },
        { variant: 'warning', icon: <ExclamationTriangle />, message: 'This is a warning alert — check it out!' },
        { variant: 'info', icon: <InfoCircle />, message: 'This is an info alert — check it out!' },
        { variant: 'light', icon: <Stars />, message: 'This is a light alert — check it out!' },
        { variant: 'dark', icon: <Lightning />, message: 'This is a dark alert — check it out!' }
    ];

    const alertsWithHeading = [
        {
            variant: 'success',
            icon: <ShieldCheck size={24} />,
            heading: 'Success Heading',
            message: 'Your action has been completed successfully. You can now continue with your work.',
            link: '#'
        },
        {
            variant: 'danger',
            icon: <XCircle size={24} />,
            heading: 'Error Heading',
            message: 'There was a problem with your submission. Please check the errors below.',
            link: '#'
        }
    ];

    return (
        <Container fluid className="py-4">
            {/* Basic Alerts */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Basic Alerts</h5>
                </Card.Header>
                <Card.Body>
                    <Row className="g-3">
                        {basicAlerts.map((alert, index) => (
                            <Col md={6} key={index}>
                                <Alert variant={alert.variant} className="d-flex align-items-center">
                                    {alert.icon && <span className="me-2">{alert.icon}</span>}
                                    {alert.message}
                                </Alert>
                            </Col>
                        ))}
                    </Row>
                </Card.Body>
            </Card>

            {/* Outline Alerts */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Outline Alerts</h5>
                </Card.Header>
                <Card.Body>
                    <Row className="g-3">
                        {basicAlerts.map((alert, index) => (
                            <Col md={6} key={index}>
                                <Alert
                                    variant={alert.variant}
                                    className="d-flex align-items-center border border-2 bg-transparent"
                                >
                                    {alert.icon && <span className="me-2">{alert.icon}</span>}
                                    {alert.message}
                                </Alert>
                            </Col>
                        ))}
                    </Row>
                </Card.Body>
            </Card>

            {/* Alerts with Headings */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Alerts with Headings</h5>
                </Card.Header>
                <Card.Body>
                    <Row className="g-3">
                        {alertsWithHeading.map((alert, index) => (
                            <Col md={6} key={index}>
                                <Alert variant={alert.variant}>
                                    <div className="d-flex">
                                        {alert.icon && (
                                            <div className="me-3">
                                                {alert.icon}
                                            </div>
                                        )}
                                        <div>
                                            <Alert.Heading className="mb-1">
                                                {alert.heading}
                                            </Alert.Heading>
                                            <p className="mb-2">{alert.message}</p>
                                            <hr />
                                            <div className="mb-0">
                                                <Alert.Link href={alert.link}>
                                                    Learn more about this alert
                                                </Alert.Link>
                                            </div>
                                        </div>
                                    </div>
                                </Alert>
                            </Col>
                        ))}
                    </Row>
                </Card.Body>
            </Card>

            {/* Dismissible Alerts */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Dismissible Alerts</h5>
                </Card.Header>
                <Card.Body>
                    <Row className="g-3">
                        {basicAlerts.slice(0, 4).map((alert, index) => (
                            <Col md={6} key={index}>
                                <Alert
                                    variant={alert.variant}
                                    dismissible
                                    className="d-flex align-items-center"
                                >
                                    {alert.icon && <span className="me-2">{alert.icon}</span>}
                                    {alert.message}
                                </Alert>
                            </Col>
                        ))}
                    </Row>
                </Card.Body>
            </Card>

            {/* Alerts with Actions */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Alerts with Actions</h5>
                </Card.Header>
                <Card.Body>
                    <Row className="g-3">
                        <Col md={6}>
                            <Alert variant="primary">
                                <div className="d-flex align-items-center">
                                    <InfoCircle size={24} className="me-3" />
                                    <div className="flex-grow-1">
                                        <h6 className="alert-heading mb-1">Update Available</h6>
                                        <p className="mb-0">A new software update is available for download.</p>
                                    </div>
                                    <Button variant="primary" size="sm" className="ms-3">
                                        Update Now
                                    </Button>
                                </div>
                            </Alert>
                        </Col>
                        <Col md={6}>
                            <Alert variant="warning">
                                <div className="d-flex align-items-center">
                                    <ExclamationTriangle size={24} className="me-3" />
                                    <div className="flex-grow-1">
                                        <h6 className="alert-heading mb-1">Storage Warning</h6>
                                        <p className="mb-0">You're running out of storage space.</p>
                                    </div>
                                    <div className="ms-3">
                                        <Button variant="warning" size="sm" className="mb-1 w-100">
                                            Upgrade Plan
                                        </Button>
                                        <Button variant="light" size="sm" className="w-100">
                                            Dismiss
                                        </Button>
                                    </div>
                                </div>
                            </Alert>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {/* Modern Alerts */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Modern Alerts</h5>
                </Card.Header>
                <Card.Body>
                    <Row className="g-3">
                        {basicAlerts.slice(0, 4).map((alert, index) => (
                            <Col md={6} key={index}>
                                <Alert
                                    variant={alert.variant}
                                    className="d-flex align-items-center shadow-sm border-0"
                                >
                                    <div className={`alert-modern-icon bg-${alert.variant} bg-opacity-25 rounded-circle p-2 me-3`}>
                                        {alert.icon}
                                    </div>
                                    <div className="flex-grow-1">
                                        <h6 className="alert-heading mb-1">
                                            {alert.variant.charAt(0).toUpperCase() + alert.variant.slice(1)} Alert
                                        </h6>
                                        <p className="mb-0 opacity-75">{alert.message}</p>
                                    </div>
                                </Alert>
                            </Col>
                        ))}
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default Alerts;