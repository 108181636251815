import React from 'react';
import { Container, Row, Col, Card, Button, Badge, ProgressBar } from 'react-bootstrap';
import {
    ThreeDotsVertical,
    Heart,
    ChatDots,
    Share,
    Download,
    Calendar3,
    Person,
    Star,
    StarFill,
    Clock
} from 'react-bootstrap-icons';

const Cards = () => {
    return (
        <Container fluid className="py-4">
            {/* Basic Cards */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Basic Cards</h5>
                </Card.Header>
                <Card.Body>
                    <Row className="g-4">
                        {/* Simple Card */}
                        <Col md={4}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Simple Card</Card.Title>
                                    <Card.Text>
                                        Some quick example text to build on the card title and make up the
                                        bulk of the card's content.
                                    </Card.Text>
                                    <Button variant="primary">Go somewhere</Button>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Card with Header */}
                        <Col md={4}>
                            <Card>
                                <Card.Header className="d-flex justify-content-between align-items-center">
                                    <h6 className="mb-0">Card with Header</h6>
                                    <ThreeDotsVertical />
                                </Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        Some quick example text to build on the card title and make up the
                                        bulk of the card's content.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Card with Footer */}
                        <Col md={4}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Card with Footer</Card.Title>
                                    <Card.Text>
                                        Some quick example text to build on the card title and make up the
                                        bulk of the card's content.
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer className="text-muted">
                                    Last updated 3 mins ago
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {/* Cards with Images */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Cards with Images</h5>
                </Card.Header>
                <Card.Body>
                    <Row className="g-4">
                        {/* Image Top */}
                        <Col md={4}>
                            <Card>
                                <Card.Img variant="top" src="/api/placeholder/400/200" />
                                <Card.Body>
                                    <Card.Title>Card with Image Top</Card.Title>
                                    <Card.Text>
                                        Some quick example text to build on the card title.
                                    </Card.Text>
                                    <Button variant="primary">Read More</Button>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Image Overlay */}
                        <Col md={4}>
                            <Card className="text-white">
                                <Card.Img src="/api/placeholder/400/300" alt="Card image" />
                                <Card.ImgOverlay>
                                    <Card.Title>Image Overlay</Card.Title>
                                    <Card.Text>
                                        This is a wider card with supporting text below.
                                    </Card.Text>
                                    <Card.Text>Last updated 3 mins ago</Card.Text>
                                </Card.ImgOverlay>
                            </Card>
                        </Col>

                        {/* Horizontal Card */}
                        <Col md={4}>
                            <Card>
                                <Row className="g-0">
                                    <Col md={4}>
                                        <Card.Img
                                            src="/api/placeholder/200/300"
                                            className="h-100"
                                            style={{ objectFit: 'cover' }}
                                        />
                                    </Col>
                                    <Col md={8}>
                                        <Card.Body>
                                            <Card.Title>Horizontal Card</Card.Title>
                                            <Card.Text>
                                                This is a wider card with supporting text.
                                            </Card.Text>
                                        </Card.Body>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {/* Interactive Cards */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Interactive Cards</h5>
                </Card.Header>
                <Card.Body>
                    <Row className="g-4">
                        {/* Social Card */}
                        <Col md={4}>
                            <Card>
                                <Card.Body>
                                    <div className="d-flex align-items-center mb-3">
                                        <img
                                            src="/api/placeholder/40/40"
                                            className="rounded-circle me-2"
                                            alt="avatar"
                                        />
                                        <div>
                                            <h6 className="mb-0">John Doe</h6>
                                            <small className="text-muted">@johndoe</small>
                                        </div>
                                    </div>
                                    <Card.Text>
                                        This is a sample social media post with interactive elements.
                                    </Card.Text>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <Button variant="outline-danger" size="sm">
                                            <Heart className="me-1" /> 45
                                        </Button>
                                        <Button variant="outline-primary" size="sm">
                                            <ChatDots className="me-1" /> 23
                                        </Button>
                                        <Button variant="outline-secondary" size="sm">
                                            <Share />
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Progress Card */}
                        <Col md={4}>
                            <Card>
                                <Card.Body>
                                    <Card.Title className="d-flex justify-content-between">
                                        <span>Project Status</span>
                                        <Badge bg="success">Active</Badge>
                                    </Card.Title>
                                    <Card.Text>Project completion status and details.</Card.Text>
                                    <div className="mb-3">
                                        <div className="d-flex justify-content-between mb-1">
                                            <span>Progress</span>
                                            <span>75%</span>
                                        </div>
                                        <ProgressBar now={75} />
                                    </div>
                                    <div className="d-flex justify-content-between text-muted">
                                        <small><Calendar3 className="me-1" /> Due: Dec 20</small>
                                        <small><Person className="me-1" /> Team: 5</small>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>

                        {/* Rating Card */}
                        <Col md={4}>
                            <Card>
                                <Card.Body>
                                    <Card.Title>Product Rating</Card.Title>
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="me-2">
                                            <StarFill className="text-warning" />
                                            <StarFill className="text-warning" />
                                            <StarFill className="text-warning" />
                                            <StarFill className="text-warning" />
                                            <Star className="text-warning" />
                                        </div>
                                        <span className="text-muted">(4.0)</span>
                                    </div>
                                    <Card.Text>
                                        Based on 234 reviews
                                    </Card.Text>
                                    <Button variant="primary" className="w-100">
                                        Write a Review
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            {/* Stats Cards */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Stats Cards</h5>
                </Card.Header>
                <Card.Body>
                    <Row className="g-4">
                        <Col md={3}>
                            <Card className="bg-primary text-white">
                                <Card.Body>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h6 className="text-white-50">Total Sales</h6>
                                            <h3 className="mb-0">$24,500</h3>
                                        </div>
                                        <div className="text-white-50">
                                            <Download size={24} />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="bg-success text-white">
                                <Card.Body>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h6 className="text-white-50">New Users</h6>
                                            <h3 className="mb-0">1,250</h3>
                                        </div>
                                        <div className="text-white-50">
                                            <Person size={24} />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="bg-warning text-white">
                                <Card.Body>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h6 className="text-white-50">Orders</h6>
                                            <h3 className="mb-0">450</h3>
                                        </div>
                                        <div className="text-white-50">
                                            <Clock size={24} />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={3}>
                            <Card className="bg-danger text-white">
                                <Card.Body>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h6 className="text-white-50">Revenue</h6>
                                            <h3 className="mb-0">$15,900</h3>
                                        </div>
                                        <div className="text-white-50">
                                            <Download size={24} />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default Cards;