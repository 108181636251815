// Footer.jsx
import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    const footerLinks = [
        { text: 'Terms', path: '/terms' },
        { text: 'Privacy', path: '/privacy' },
        { text: 'Support', path: '/support' }
    ];

    return null
};
export default Footer;