import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    Form,
    Button,
    Card,
    Container, Image
} from 'react-bootstrap';

function ForgotPassword() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
    };

    if (isSubmitted) {
        return (
            <Card className="shadow-lg border-0">
                <Card.Body className="p-4 text-center">
                    <div className="mb-4">
                        <Image
                            src="/assets/img/logos/logo.png"
                            alt="Logo"
                            className="mb-4"
                            style={{ height: '48px' }}
                        />
                    </div>
                    <h1 className="h4">{t('pages.auth.forgot.success.title')}</h1>
                    <p>{t('pages.auth.forgot.success.description', { email })}</p>
                    <div className="mt-4">
                        <Link to="/login">
                            <Button variant="primary">
                                {t('pages.auth.forgot.backToLogin')}
                            </Button>
                        </Link>
                    </div>
                </Card.Body>
            </Card>
        );
    }

    return (
        <Card className="shadow-lg border-0">
            <Card.Header className="bg-white border-0 text-center pt-4">
                <h1 className="h2">{t('pages.auth.forgot.title')}</h1>
                <p className="text-muted">{t('pages.auth.forgot.description')}</p>
            </Card.Header>

            <Card.Body className="p-4">
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label className="small fw-medium">
                            {t('pages.auth.forgot.email.label')}
                        </Form.Label>
                        <Form.Control
                            type="email"
                            size="lg"
                            name="email"
                            id="forgotEmail"
                            placeholder={t('pages.auth.forgot.email.placeholder')}
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <Form.Control.Feedback type="invalid">
                            {t('pages.auth.forgot.email.error')}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <div className="d-grid gap-3">
                        <Button type="submit" size="lg">
                            {t('pages.auth.forgot.submit')}
                        </Button>
                        <Link to="/login" className="btn btn-link">
                            {t('pages.auth.forgot.backToLogin')}
                        </Link>
                    </div>
                </Form>
            </Card.Body>

            <Card.Footer className="bg-soft-light py-3 text-center">
                <p className="small text-muted mb-0">
                    &copy; Orpixel |{' '}
                    <Link to='/terms-and-conditions' className="text-decoration-none">
                        {t('layout.footer.copyright')}
                    </Link>
                </p>
            </Card.Footer>
        </Card>
    );
}

export default ForgotPassword;