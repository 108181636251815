import React, { useState } from 'react';
import { Container, Row, Col, Card, Button, Modal, Form, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    BoxArrowUpRight,
    ExclamationTriangle,
    CheckCircle,
    XCircle,
    Trash,
    Upload,
    Download,
    InfoCircle
} from 'react-bootstrap-icons';

const Modals = () => {
    const { t } = useTranslation();

    // Estados para controlar la visibilidad de los modales
    const [showBasic, setShowBasic] = useState(false);
    const [showSizes, setShowSizes] = useState({ sm: false, lg: false, xl: false });
    const [showScrollable, setShowScrollable] = useState(false);
    const [showCentered, setShowCentered] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);

    // Contenido largo para el modal scrollable
    const longText = Array(20).fill("This is some placeholder content to show a vertically scrollable modal.").join(" ");

    return (
        <Container fluid className="py-4">
            <Row className="g-4">
                {/* Basic Modal */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('modals.basic.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <div className="d-grid gap-2">
                                <Button variant="primary" onClick={() => setShowBasic(true)}>
                                    {t('modals.basic.btnText')}
                                </Button>

                                <Modal show={showBasic} onHide={() => setShowBasic(false)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>{t('modals.basic.modalTitle')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {t('modals.basic.modalContent')}
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => setShowBasic(false)}>
                                            {t('modals.common.close')}
                                        </Button>
                                        <Button variant="primary">
                                            {t('modals.common.save')}
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Modal Sizes */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('modals.sizes.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <div className="d-grid gap-2">
                                <Button variant="primary" onClick={() => setShowSizes({ ...showSizes, sm: true })}>
                                    {t('modals.sizes.small')}
                                </Button>
                                <Button variant="primary" onClick={() => setShowSizes({ ...showSizes, lg: true })}>
                                    {t('modals.sizes.large')}
                                </Button>
                                <Button variant="primary" onClick={() => setShowSizes({ ...showSizes, xl: true })}>
                                    {t('modals.sizes.extraLarge')}
                                </Button>

                                {/* Small Modal */}
                                <Modal size="sm" show={showSizes.sm} onHide={() => setShowSizes({ ...showSizes, sm: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>{t('modals.sizes.smallTitle')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {t('modals.sizes.content')}
                                    </Modal.Body>
                                </Modal>

                                {/* Large Modal */}
                                <Modal size="lg" show={showSizes.lg} onHide={() => setShowSizes({ ...showSizes, lg: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>{t('modals.sizes.largeTitle')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {t('modals.sizes.content')}
                                    </Modal.Body>
                                </Modal>

                                {/* Extra Large Modal */}
                                <Modal size="xl" show={showSizes.xl} onHide={() => setShowSizes({ ...showSizes, xl: false })}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>{t('modals.sizes.extraLargeTitle')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {t('modals.sizes.content')}
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Scrollable Modal */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('modals.scrollable.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Button variant="primary" onClick={() => setShowScrollable(true)}>
                                {t('modals.scrollable.btnText')}
                            </Button>

                            <Modal show={showScrollable} onHide={() => setShowScrollable(false)} scrollable>
                                <Modal.Header closeButton>
                                    <Modal.Title>{t('modals.scrollable.modalTitle')}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {longText}
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setShowScrollable(false)}>
                                        {t('modals.common.close')}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Centered Modal */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('modals.centered.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Button variant="primary" onClick={() => setShowCentered(true)}>
                                {t('modals.centered.btnText')}
                            </Button>

                            <Modal show={showCentered} onHide={() => setShowCentered(false)} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title>{t('modals.centered.modalTitle')}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className="text-center py-4">
                                    <InfoCircle size={50} className="text-info mb-3" />
                                    <p className="mb-0">{t('modals.centered.modalContent')}</p>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setShowCentered(false)}>
                                        {t('modals.common.close')}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Confirmation Modal */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('modals.confirmation.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Button variant="danger" onClick={() => setShowConfirm(true)}>
                                <Trash className="me-2" />
                                {t('modals.confirmation.btnText')}
                            </Button>

                            <Modal show={showConfirm} onHide={() => setShowConfirm(false)} centered>
                                <Modal.Header closeButton className="border-bottom-0">
                                    <Modal.Title className="text-danger">
                                        <ExclamationTriangle className="me-2" />
                                        {t('modals.confirmation.modalTitle')}
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    {t('modals.confirmation.modalContent')}
                                </Modal.Body>
                                <Modal.Footer className="border-top-0">
                                    <Button variant="light" onClick={() => setShowConfirm(false)}>
                                        {t('modals.common.cancel')}
                                    </Button>
                                    <Button variant="danger">
                                        {t('modals.confirmation.confirm')}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Form Modal */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('modals.form.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Button variant="primary" onClick={() => setShowForm(true)}>
                                <BoxArrowUpRight className="me-2" />
                                {t('modals.form.btnText')}
                            </Button>

                            <Modal show={showForm} onHide={() => setShowForm(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>{t('modals.form.modalTitle')}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('modals.form.emailLabel')}</Form.Label>
                                            <Form.Control type="email" placeholder={t('modals.form.emailPlaceholder')} />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>{t('modals.form.passwordLabel')}</Form.Label>
                                            <Form.Control type="password" placeholder={t('modals.form.passwordPlaceholder')} />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Check
                                                type="checkbox"
                                                label={t('modals.form.rememberMe')}
                                            />
                                        </Form.Group>
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setShowForm(false)}>
                                        {t('modals.common.close')}
                                    </Button>
                                    <Button variant="primary">
                                        {t('modals.form.submit')}
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Success Modal */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('modals.success.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Button variant="success" onClick={() => setShowSuccess(true)}>
                                <CheckCircle className="me-2" />
                                {t('modals.success.btnText')}
                            </Button>

                            <Modal show={showSuccess} onHide={() => setShowSuccess(false)} centered>
                                <Modal.Body className="text-center p-4">
                                    <CheckCircle className="text-success mb-3" size={50} />
                                    <h4 className="mb-3">{t('modals.success.modalTitle')}</h4>
                                    <p className="mb-4">{t('modals.success.modalContent')}</p>
                                    <Button variant="success" onClick={() => setShowSuccess(false)}>
                                        {t('modals.success.continue')}
                                    </Button>
                                </Modal.Body>
                            </Modal>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Error Modal */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('modals.error.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Button variant="danger" onClick={() => setShowError(true)}>
                                <XCircle className="me-2" />
                                {t('modals.error.btnText')}
                            </Button>

                            <Modal show={showError} onHide={() => setShowError(false)} centered>
                                <Modal.Body className="text-center p-4">
                                    <XCircle className="text-danger mb-3" size={50} />
                                    <h4 className="mb-3">{t('modals.error.modalTitle')}</h4>
                                    <p className="mb-4">{t('modals.error.modalContent')}</p>
                                    <div className="d-grid gap-2">
                                        <Button variant="outline-danger" onClick={() => setShowError(false)}>
                                            {t('modals.error.tryAgain')}
                                        </Button>
                                        <Button variant="light" onClick={() => setShowError(false)}>
                                            {t('modals.common.cancel')}
                                        </Button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Modals;