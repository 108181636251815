
import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../store/slice/authSlice';
import userReducer from '../store/slice/userSlice';
import permissionReducer from "./slice/permissionSlice";
import themeReducer from "./slice/themeSlice";

const middlewareList = [];

export const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        permissions: permissionReducer,
        theme: themeReducer

    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }).concat(middlewareList),

});
