import React from 'react';
import { Container, Row, Col, Card, Table, Badge } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    CheckCircleFill,
    XCircleFill,
    ClockFill,
    ArrowUp,
    ArrowDown
} from 'react-bootstrap-icons';

const BasicTables = () => {
    const { t } = useTranslation();

    return (
        <Container fluid className="py-4">
            <Row className="g-4">
                {/* Tabla de Transacciones */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('tables.transactions.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Table hover>
                                <thead>
                                <tr>
                                    <th>{t('tables.transactions.type')}</th>
                                    <th>{t('tables.transactions.amount')}</th>
                                    <th>{t('tables.transactions.status')}</th>
                                    <th>{t('tables.transactions.date')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <ArrowUp className="text-danger me-2" />
                                        {t('tables.transactions.withdrawal')}
                                    </td>
                                    <td className="text-danger">-$250.00</td>
                                    <td><Badge bg="success">Completado</Badge></td>
                                    <td>2024-03-15</td>
                                </tr>
                                <tr>
                                    <td>
                                        <ArrowDown className="text-success me-2" />
                                        {t('tables.transactions.deposit')}
                                    </td>
                                    <td className="text-success">+$1,000.00</td>
                                    <td><Badge bg="success">Completado</Badge></td>
                                    <td>2024-03-14</td>
                                </tr>
                                <tr>
                                    <td>
                                        <ArrowUp className="text-danger me-2" />
                                        {t('tables.transactions.payment')}
                                    </td>
                                    <td className="text-danger">-$84.99</td>
                                    <td><Badge bg="warning">Pendiente</Badge></td>
                                    <td>2024-03-14</td>
                                </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Tabla de Estado de Servidores */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('tables.servers.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Table bordered>
                                <thead className="table-dark">
                                <tr>
                                    <th>{t('tables.servers.name')}</th>
                                    <th>{t('tables.servers.status')}</th>
                                    <th>{t('tables.servers.uptime')}</th>
                                    <th>{t('tables.servers.load')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Server-01</td>
                                    <td>
                                        <CheckCircleFill className="text-success me-2" />
                                        Online
                                    </td>
                                    <td>99.9%</td>
                                    <td>
                                        <div className="progress" style={{ height: '6px' }}>
                                            <div className="progress-bar bg-success" style={{ width: '25%' }}></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Server-02</td>
                                    <td>
                                        <CheckCircleFill className="text-success me-2" />
                                        Online
                                    </td>
                                    <td>98.5%</td>
                                    <td>
                                        <div className="progress" style={{ height: '6px' }}>
                                            <div className="progress-bar bg-warning" style={{ width: '65%' }}></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Server-03</td>
                                    <td>
                                        <XCircleFill className="text-danger me-2" />
                                        Offline
                                    </td>
                                    <td>85.2%</td>
                                    <td>
                                        <div className="progress" style={{ height: '6px' }}>
                                            <div className="progress-bar bg-danger" style={{ width: '90%' }}></div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Tabla de Tareas de Proyecto */}
                <Col md={12}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('tables.tasks.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Table striped responsive>
                                <thead>
                                <tr>
                                    <th>{t('tables.tasks.task')}</th>
                                    <th>{t('tables.tasks.assignee')}</th>
                                    <th>{t('tables.tasks.priority')}</th>
                                    <th>{t('tables.tasks.deadline')}</th>
                                    <th>{t('tables.tasks.progress')}</th>
                                    <th>{t('tables.tasks.status')}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>Diseño de Dashboard</td>
                                    <td>
                                        <img
                                            src="/api/placeholder/32/32"
                                            alt="user"
                                            className="rounded-circle me-2"
                                            width="32"
                                        />
                                        John Doe
                                    </td>
                                    <td><Badge bg="danger">Alta</Badge></td>
                                    <td>2024-03-20</td>
                                    <td>
                                        <div className="progress" style={{ height: '6px' }}>
                                            <div className="progress-bar" style={{ width: '70%' }}></div>
                                        </div>
                                    </td>
                                    <td>
                                        <ClockFill className="text-warning me-2" />
                                        En Progreso
                                    </td>
                                </tr>
                                <tr>
                                    <td>Implementación API</td>
                                    <td>
                                        <img
                                            src="/api/placeholder/32/32"
                                            alt="user"
                                            className="rounded-circle me-2"
                                            width="32"
                                        />
                                        Jane Smith
                                    </td>
                                    <td><Badge bg="warning">Media</Badge></td>
                                    <td>2024-03-25</td>
                                    <td>
                                        <div className="progress" style={{ height: '6px' }}>
                                            <div className="progress-bar" style={{ width: '45%' }}></div>
                                        </div>
                                    </td>
                                    <td>
                                        <ClockFill className="text-warning me-2" />
                                        En Progreso
                                    </td>
                                </tr>
                                <tr>
                                    <td>Testing</td>
                                    <td>
                                        <img
                                            src="/api/placeholder/32/32"
                                            alt="user"
                                            className="rounded-circle me-2"
                                            width="32"
                                        />
                                        Bob Johnson
                                    </td>
                                    <td><Badge bg="success">Baja</Badge></td>
                                    <td>2024-03-30</td>
                                    <td>
                                        <div className="progress" style={{ height: '6px' }}>
                                            <div className="progress-bar" style={{ width: '20%' }}></div>
                                        </div>
                                    </td>
                                    <td>
                                        <CheckCircleFill className="text-success me-2" />
                                        Iniciado
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default BasicTables;