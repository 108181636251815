import React, { useState } from 'react';
import { Card, Row, Col, Button, Badge, Table, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    CreditCard,
    Check2Circle,
    Clock,
    Download,
    Plus,
    Trash
} from 'react-bootstrap-icons';

const Billing = () => {
    const { t } = useTranslation();
    const [selectedPlan, setSelectedPlan] = useState('professional');

    const plans = [
        {
            id: 'free',
            name: 'Free',
            price: '0',
            features: ['5 Projects', '2 Users', '10GB Storage', 'Basic Support'],
            current: false
        },
        {
            id: 'professional',
            name: 'Professional',
            price: '29',
            features: ['15 Projects', '5 Users', '50GB Storage', 'Priority Support', 'Advanced Analytics'],
            current: true
        },
        {
            id: 'enterprise',
            name: 'Enterprise',
            price: '99',
            features: ['Unlimited Projects', 'Unlimited Users', '500GB Storage', '24/7 Support', 'Custom Features'],
            current: false
        }
    ];

    const paymentMethods = [
        {
            id: 1,
            type: 'visa',
            last4: '4242',
            expiry: '04/24',
            isDefault: true
        },
        {
            id: 2,
            type: 'mastercard',
            last4: '5555',
            expiry: '08/25',
            isDefault: false
        }
    ];

    const billingHistory = [
        {
            id: 'INV-2024-001',
            date: '2024-03-01',
            amount: 29.00,
            status: 'paid',
            description: 'Professional Plan - March 2024'
        },
        {
            id: 'INV-2024-002',
            date: '2024-02-01',
            amount: 29.00,
            status: 'paid',
            description: 'Professional Plan - February 2024'
        },
        {
            id: 'INV-2024-003',
            date: '2024-01-01',
            amount: 29.00,
            status: 'paid',
            description: 'Professional Plan - January 2024'
        }
    ];

    const handlePlanChange = (planId) => {
        setSelectedPlan(planId);
    };

    return (
        <div className="billing-container">
            {/* Current Plan Section */}
            <Card className="mb-4">
                <Card.Header className="border-bottom">
                    <h5 className="mb-0">{t('pages.panel.billing.currentPlan.title')}</h5>
                </Card.Header>
                <Card.Body>
                    <div className="d-flex justify-content-between align-items-center mb-4">
                        <div>
                            <h6 className="mb-2">{t('pages.panel.billing.currentPlan.professional')}</h6>
                            <p className="text-muted mb-0">
                                {t('pages.panel.billing.currentPlan.renewal')} <strong>April 1, 2024</strong>
                            </p>
                        </div>
                        <Button variant="outline-primary">
                            {t('pages.panel.billing.currentPlan.changePlan')}
                        </Button>
                    </div>

                    <div className="progress" style={{ height: '4px' }}>
                        <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            style={{ width: '75%' }}
                            aria-valuenow="75"
                            aria-valuemin="0"
                            aria-valuemax="100"
                        />
                    </div>

                    <div className="d-flex justify-content-between mt-1">
                        <small>11.25 GB of 15 GB used</small>
                        <small className="text-success">75%</small>
                    </div>
                </Card.Body>
            </Card>

            {/* Payment Methods Section */}
            <Card className="mb-4">
                <Card.Header className="border-bottom d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">{t('pages.panel.billing.paymentMethods.title')}</h5>
                    <Button variant="primary" size="sm">
                        <Plus className="me-2" />
                        {t('pages.panel.billing.paymentMethods.addNew')}
                    </Button>
                </Card.Header>
                <Card.Body>
                    <div className="d-flex flex-column gap-3">
                        {paymentMethods.map((method) => (
                            <div key={method.id} className="border rounded p-3 d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <img
                                        src={`/assets/images/payment/${method.type}.svg`}
                                        alt={method.type}
                                        className="me-3"
                                        height="24"
                                    />
                                    <div>
                                        <h6 className="mb-0">
                                            {method.type.charAt(0).toUpperCase() + method.type.slice(1)} ending in {method.last4}
                                        </h6>
                                        <small className="text-muted">Expires {method.expiry}</small>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center gap-2">
                                    {method.isDefault && (
                                        <Badge bg="success" className="me-2">Default</Badge>
                                    )}
                                    <Button variant="link" className="text-danger p-0">
                                        <Trash />
                                    </Button>
                                </div>
                            </div>
                        ))}
                    </div>
                </Card.Body>
            </Card>

            {/* Billing History Section */}
            <Card>
                <Card.Header className="border-bottom">
                    <h5 className="mb-0">{t('pages.panel.billing.history.title')}</h5>
                </Card.Header>
                <Card.Body>
                    <Table responsive className="align-middle">
                        <thead>
                        <tr>
                            <th>{t('pages.panel.billing.history.invoice')}</th>
                            <th>{t('pages.panel.billing.history.date')}</th>
                            <th>{t('pages.panel.billing.history.description')}</th>
                            <th>{t('pages.panel.billing.history.amount')}</th>
                            <th>{t('pages.panel.billing.history.status')}</th>
                            <th className="text-end">{t('pages.panel.billing.history.download')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {billingHistory.map((invoice) => (
                            <tr key={invoice.id}>
                                <td>{invoice.id}</td>
                                <td>{new Date(invoice.date).toLocaleDateString()}</td>
                                <td>{invoice.description}</td>
                                <td>${invoice.amount.toFixed(2)}</td>
                                <td>
                                    <Badge bg={invoice.status === 'paid' ? 'success' : 'warning'}>
                                        {invoice.status.charAt(0).toUpperCase() + invoice.status.slice(1)}
                                    </Badge>
                                </td>
                                <td className="text-end">
                                    <Button variant="link" className="p-0">
                                        <Download />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </div>
    );
};

export default Billing;