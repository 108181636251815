// src/routes/publicRoutes.js
import Login from "@pages/auth/Login";
import Register from "@pages/auth/Register/Register";
import {Navigate} from "react-router";
import React from "react";
import ForgotPassword from "@pages/auth/ForgotPassword";

const routes = [
    {

        path: '/',
        element: <Navigate to="/login" replace/>
    },
    {
        path: "/login",
        element: <Login/>
    },
    {
        path: "/register",
        element: <Register/>
    },
    {
        path: "/forgot-password",
        element: <ForgotPassword/>
    },

];

export default routes;