import React from 'react';
import { Container, Row, Col, Card, Badge } from 'react-bootstrap';
import {
    Bell,
    Envelope,
    Star,
    Check,
    X,
    Clock,
    ArrowUp,
    ArrowDown
} from 'react-bootstrap-icons';

const Badges = () => {
    // Array con las variantes de colores disponibles
    const variants = ['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark'];

    // Configuración de badges con íconos
    const iconBadges = [
        { icon: <Bell className="me-1" />, text: 'Notifications', variant: 'primary' },
        { icon: <Check className="me-1" />, text: 'Complete', variant: 'success' },
        { icon: <X className="me-1" />, text: 'Rejected', variant: 'danger' },
        { icon: <Clock className="me-1" />, text: 'Pending', variant: 'warning' }
    ];

    // Estados para notificaciones
    const statusBadges = [
        { text: 'Active', variant: 'success' },
        { text: 'Pending', variant: 'warning' },
        { text: 'Inactive', variant: 'secondary' },
        { text: 'Blocked', variant: 'danger' }
    ];

    // Badges con contador
    const counterBadges = [
        { text: 'Messages', count: 5, variant: 'primary' },
        { text: 'Updates', count: 12, variant: 'info' },
        { text: 'Tasks', count: 3, variant: 'success' },
        { text: 'Alerts', count: 8, variant: 'danger' }
    ];

    return (
        <Container fluid className="py-4">
            {/* Basic Badges */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Basic Badges</h5>
                </Card.Header>
                <Card.Body>
                    <div className="d-flex flex-wrap gap-2">
                        {variants.map((variant) => (
                            <Badge key={variant} bg={variant}>
                                {variant.charAt(0).toUpperCase() + variant.slice(1)}
                            </Badge>
                        ))}
                    </div>
                </Card.Body>
            </Card>

            {/* Outline Badges */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Outline Badges</h5>
                </Card.Header>
                <Card.Body>
                    <div className="d-flex flex-wrap gap-2">
                        {variants.map((variant) => (
                            <Badge
                                key={variant}
                                className={`border border-${variant} text-${variant} bg-transparent`}
                            >
                                {variant.charAt(0).toUpperCase() + variant.slice(1)}
                            </Badge>
                        ))}
                    </div>
                </Card.Body>
            </Card>

            {/* Pill Badges */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Pill Badges</h5>
                </Card.Header>
                <Card.Body>
                    <div className="d-flex flex-wrap gap-2">
                        {variants.map((variant) => (
                            <Badge
                                key={variant}
                                pill
                                bg={variant}
                            >
                                {variant.charAt(0).toUpperCase() + variant.slice(1)}
                            </Badge>
                        ))}
                    </div>
                </Card.Body>
            </Card>

            {/* Badges with Icons */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Badges with Icons</h5>
                </Card.Header>
                <Card.Body>
                    <div className="d-flex flex-wrap gap-2">
                        {iconBadges.map((badge, index) => (
                            <Badge key={index} bg={badge.variant} className="d-inline-flex align-items-center">
                                {badge.icon}
                                {badge.text}
                            </Badge>
                        ))}
                    </div>
                </Card.Body>
            </Card>

            {/* Status Indicators */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Status Indicators</h5>
                </Card.Header>
                <Card.Body>
                    <div className="d-flex flex-wrap gap-2">
                        {statusBadges.map((status, index) => (
                            <Badge
                                key={index}
                                bg={status.variant}
                                className="position-relative d-inline-flex align-items-center ps-4"
                            >
                                <span
                                    className={`position-absolute start-0 ms-2 rounded-circle bg-white`}
                                    style={{ width: '6px', height: '6px' }}
                                ></span>
                                {status.text}
                            </Badge>
                        ))}
                    </div>
                </Card.Body>
            </Card>

            {/* Counter Badges */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Counter Badges</h5>
                </Card.Header>
                <Card.Body>
                    <Row className="g-3">
                        {counterBadges.map((counter, index) => (
                            <Col key={index} md={3}>
                                <div className="d-flex align-items-center">
                                    <span className="me-2">{counter.text}</span>
                                    <Badge bg={counter.variant} pill>
                                        {counter.count}
                                    </Badge>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Card.Body>
            </Card>

            {/* Trend Indicators */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Trend Indicators</h5>
                </Card.Header>
                <Card.Body>
                    <div className="d-flex flex-wrap gap-3">
                        <div className="d-flex align-items-center">
                            <Badge bg="success" className="d-inline-flex align-items-center me-2">
                                <ArrowUp className="me-1" />
                                +22%
                            </Badge>
                            <span className="text-muted">vs last month</span>
                        </div>
                        <div className="d-flex align-items-center">
                            <Badge bg="danger" className="d-inline-flex align-items-center me-2">
                                <ArrowDown className="me-1" />
                                -8%
                            </Badge>
                            <span className="text-muted">vs last week</span>
                        </div>
                    </div>
                </Card.Body>
            </Card>

            {/* Size Variations */}
            <Card className="mb-4">
                <Card.Header>
                    <h5 className="mb-0">Size Variations</h5>
                </Card.Header>
                <Card.Body>
                    <div className="d-flex flex-wrap gap-2 align-items-center">
                        <Badge bg="primary" className="badge-sm">Small</Badge>
                        <Badge bg="primary">Default</Badge>
                        <Badge bg="primary" className="badge-lg">Large</Badge>
                    </div>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default Badges;