import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Button, Dropdown, Badge, Form, InputGroup, ListGroup, ProgressBar } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import { useTranslation } from 'react-i18next';


import {
    AreaChart, Area, LineChart, Line, BarChart, Bar, PieChart, Pie,
    XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, ComposedChart
} from 'recharts';

import {
    CurrencyDollar, BagCheck, Cart4, GraphUpArrow,
    ArrowUp, ArrowDown, Search, Calendar3, Filter,Clock,
    SortDown, Award, PeopleFill, StarFill, GearFill, Truck
} from 'react-bootstrap-icons';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

const EcommerceDashboard = () => {
    const { t } = useTranslation();
    const [dateRange, setDateRange] = useState('weekly');
    const [filterView, setFilterView] = useState('all');

    // Custom Styles for Charts
    const gradientOffset = () => {
        return 0.95;
    };

    const salesData = [
        { date: '2024-01', revenue: 84500, orders: 1250, customers: 890, profit: 28900 },
        { date: '2024-02', revenue: 92000, orders: 1380, customers: 920, profit: 31500 },
        { date: '2024-03', revenue: 88700, orders: 1290, customers: 905, profit: 29800 },
        { date: '2024-04', revenue: 103500, orders: 1520, customers: 1050, profit: 35200 },
        { date: '2024-05', revenue: 97800, orders: 1420, customers: 980, profit: 33100 },
        { date: '2024-06', revenue: 115200, orders: 1680, customers: 1180, profit: 39500 }
    ];

    const categoryPerformance = [
        { name: t('pages.ecommerce.categories.electronics'), sales: 548, growth: 15.8, revenue: 82500 },
        { name: t('pages.ecommerce.categories.fashion'), sales: 423, growth: 12.3, revenue: 65800 },
        { name: t('pages.ecommerce.categories.home'), sales: 387, growth: 8.7, revenue: 54200 },
        { name: t('pages.ecommerce.categories.beauty'), sales: 289, growth: 18.2, revenue: 43600 },
        { name: t('pages.ecommerce.categories.sports'), sales: 245, growth: 5.4, revenue: 38900 }
    ];

    const bestSellingProducts = [
        {
            id: 1,
            name: t('pages.ecommerce.products.smartphone'),
            category: t('pages.ecommerce.categories.electronics'),
            sales: 245,
            revenue: 122500,
            rating: 4.8,
            stock: 89
        },
        {
            id: 2,
            name: t('pages.ecommerce.products.laptop'),
            category: t('pages.ecommerce.categories.electronics'),
            sales: 189,
            revenue: 236250,
            rating: 4.9,
            stock: 45
        },
        // ... más productos
    ];

    const columnDefs = [
        {
            headerName: t('pages.ecommerce.grid.product'),
            field: 'name',
            sortable: true,
            filter: true,
            cellRenderer: params => (
                <div className="d-flex align-items-center">
                    <div className="product-icon me-2">
                        <Award className="text-primary" size={20} />
                    </div>
                    <div>
                        <div>{params.value}</div>
                        <small className="text-muted">{params.data.category}</small>
                    </div>
                </div>
            )
        },
        {
            headerName: t('pages.ecommerce.grid.sales'),
            field: 'sales',
            sortable: true,
            filter: true,
            cellRenderer: params => (
                <div className="d-flex align-items-center justify-content-between">
                    <strong>{params.value}</strong>
                    <ProgressBar
                        now={params.value / 3}
                        variant="info"
                        style={{ width: '60%', height: '6px' }}
                    />
                </div>
            )
        },
        {
            headerName: t('pages.ecommerce.grid.revenue'),
            field: 'revenue',
            sortable: true,
            filter: true,
            cellRenderer: params => (
                <div className="text-end">
                    <div className="fw-bold">${params.value.toLocaleString()}</div>
                    <small className="text-success">
                        <ArrowUp size={12} /> 12.5%
                    </small>
                </div>
            )
        },
        {
            headerName: t('pages.ecommerce.grid.rating'),
            field: 'rating',
            sortable: true,
            filter: true,
            cellRenderer: params => (
                <div className="d-flex align-items-center">
                    <StarFill className="text-warning me-1" size={14} />
                    <span>{params.value}</span>
                </div>
            )
        },
        {
            headerName: t('pages.ecommerce.grid.stock'),
            field: 'stock',
            sortable: true,
            filter: true,
            cellRenderer: params => {
                const stockStatus = params.value < 50 ? 'danger' : params.value < 100 ? 'warning' : 'success';
                return (
                    <Badge bg={stockStatus}>
                        {params.value} {t('pages.ecommerce.grid.units')}
                    </Badge>
                );
            }
        }
    ];

    const kpiCards = [
        {
            title: t('pages.ecommerce.kpi.revenue'),
            value: '$581,900',
            increase: '+15.3%',
            icon: CurrencyDollar,
            color: 'primary',
            chart: (
                <ResponsiveContainer width="100%" height={50}>
                    <AreaChart data={salesData}>
                        <defs>
                            <linearGradient id="colorRevenue" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#0d6efd" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#0d6efd" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <Area
                            type="monotone"
                            dataKey="revenue"
                            stroke="#0d6efd"
                            fillOpacity={1}
                            fill="url(#colorRevenue)"
                        />
                    </AreaChart>
                </ResponsiveContainer>
            )
        },
        // ... otros KPIs similares
    ];

    return (
        <div className="p-4">
            {/* Header con filtros */}
            {/* Header Stats y Filtros */}
            <Row className="mb-4">
                <Col xs={12}>
                    <Card className="border-0 shadow-sm">
                        <Card.Body className="p-lg-4">
                            <Row className="align-items-center">
                                {/* Stats rápidos */}
                                <Col lg={8}>
                                    <div className="d-flex align-items-center mb-4">
                                        <h2 className="mb-0 me-3">{t('pages.ecommerce.dashboard.title')}</h2>
                                        <Badge bg="primary" pill className="px-3 py-2">
                                            <Clock className="me-1" /> {t('pages.ecommerce.dashboard.live')}
                                        </Badge>
                                    </div>
                                    <Row className="g-4">
                                        {[
                                            {
                                                label: t('pages.ecommerce.kpi.today'),
                                                value: '$12,426',
                                                change: '+12.5%',
                                                icon: <CurrencyDollar className="text-primary" size={24} />
                                            },
                                            {
                                                label: t('pages.ecommerce.kpi.orders'),
                                                value: '436',
                                                change: '+8.2%',
                                                icon: <Cart4 className="text-success" size={24} />
                                            },
                                            {
                                                label: t('pages.ecommerce.kpi.conversion'),
                                                value: '3.45%',
                                                change: '-2.4%',
                                                icon: <GraphUpArrow className="text-warning" size={24} />
                                            },
                                            {
                                                label: t('pages.ecommerce.kpi.visitors'),
                                                value: '12.5k',
                                                change: '+11.2%',
                                                icon: <PeopleFill className="text-info" size={24} />
                                            }
                                        ].map((stat, index) => (
                                            <Col key={index} md={3}>
                                                <div className="border rounded p-3 h-100">
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <small className="text-muted text-uppercase fw-semibold">{stat.label}</small>
                                                        {stat.icon}
                                                    </div>
                                                    <div className="d-flex align-items-baseline">
                                                        <h4 className="mb-0 me-2 fw-semibold">{stat.value}</h4>
                                                        <span className={`badge ${stat.change.includes('+') ? 'text-success' : 'text-danger'}`}>
                                                {stat.change}
                                            </span>
                                                    </div>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                                {/* Filtros y Acciones */}
                                <Col lg={4}>
                                    <div className="d-flex flex-column gap-3 justify-content-lg-end mt-4 mt-lg-0">
                                        <InputGroup>
                                            <InputGroup.Text className="bg-white">
                                                <Search />
                                            </InputGroup.Text>
                                            <Form.Control
                                                placeholder={t('pages.ecommerce.dashboard.search')}
                                                className="border-start-0"
                                            />
                                        </InputGroup>
                                        <div className="d-flex gap-2">
                                            <Dropdown className="flex-grow-1">
                                                <Dropdown.Toggle variant="light" className="w-100 d-flex align-items-center justify-content-between">
                                        <span>
                                            <Calendar3 className="me-2" />
                                            {t(`pages.ecommerce.periods.${dateRange}`)}
                                        </span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {['daily', 'weekly', 'monthly', 'yearly'].map((period) => (
                                                        <Dropdown.Item
                                                            key={period}
                                                            active={dateRange === period}
                                                            onClick={() => setDateRange(period)}
                                                        >
                                                            {t(`pages.ecommerce.periods.${period}`)}
                                                        </Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Button variant="primary" className="px-4">
                                                <ArrowDown className="me-2" />
                                                {t('pages.ecommerce.dashboard.export')}
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Main Charts */}
            <Row className="g-4 mb-4">
                <Col xl={8}>
                    <Card className="h-100">
                        <Card.Body>
                            <div className="d-flex justify-content-between mb-4">
                                <div>
                                    <h5 className="mb-1">{t('pages.ecommerce.charts.sales.title')}</h5>
                                    <p className="text-muted mb-0">{t('pages.ecommerce.charts.sales.subtitle')}</p>
                                </div>
                                <div className="d-flex gap-2">
                                    <Button
                                        variant="outline-primary"
                                        size="sm"
                                        active={filterView === 'revenue'}
                                        onClick={() => setFilterView('revenue')}
                                    >
                                        {t('pages.ecommerce.charts.sales.revenue')}
                                    </Button>
                                    <Button
                                        variant="outline-primary"
                                        size="sm"
                                        active={filterView === 'orders'}
                                        onClick={() => setFilterView('orders')}
                                    >
                                        {t('pages.ecommerce.charts.sales.orders')}
                                    </Button>
                                </div>
                            </div>
                            <ResponsiveContainer width="100%" height={350}>
                                <ComposedChart data={salesData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" />
                                    <YAxis yAxisId="left" />
                                    <YAxis yAxisId="right" orientation="right" />
                                    <Tooltip />
                                    <Legend />
                                    <Bar
                                        yAxisId="left"
                                        dataKey={filterView === 'revenue' ? 'revenue' : 'orders'}
                                        fill="#0d6efd"
                                    />
                                    <Line
                                        yAxisId="right"
                                        type="monotone"
                                        dataKey="profit"
                                        stroke="#198754"
                                    />
                                </ComposedChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={4}>
                    <Card className="h-100">
                        <Card.Body>
                            <h5 className="mb-4">{t('pages.ecommerce.charts.categories.title')}</h5>
                            <ResponsiveContainer width="100%" height={350}>
                                <BarChart
                                    data={categoryPerformance}
                                    layout="vertical"
                                    margin={{ top: 0, right: 0, left: 40, bottom: 0 }}
                                >
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis type="number" />
                                    <YAxis dataKey="name" type="category" />
                                    <Tooltip />
                                    <Bar dataKey="sales" fill="#0d6efd">
                                        {categoryPerformance.map((entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={`hsl(216, 98%, ${60 + (index * 5)}%)`}
                                            />
                                        ))}
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>


            {/* Payment Methods Analysis */}
            <Row className="mt-4">
                <Col lg={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('pages.ecommerce.payments.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <ListGroup variant="flush">
                                {[
                                    { method: 'Credit Card', amount: '$15,483', percentage: 45, color: 'primary' },
                                    { method: 'PayPal', amount: '$12,480', percentage: 36, color: 'info' },
                                    { method: 'Bank Transfer', amount: '$4,125', percentage: 12, color: 'success' },
                                    { method: 'Others', amount: '$2,415', percentage: 7, color: 'warning' }
                                ].map((payment, index) => (
                                    <ListGroup.Item key={index} className="py-3 px-0">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <h6 className="mb-0">{payment.method}</h6>
                                                <small className="text-muted">{payment.amount}</small>
                                            </div>
                                            <div className="d-flex align-items-center gap-3">
                                                <span className="text-muted">{payment.percentage}%</span>
                                                <ProgressBar
                                                    variant={payment.color}
                                                    now={payment.percentage}
                                                    style={{ width: '100px', height: '6px' }}
                                                />
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>

                <Col lg={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('pages.ecommerce.inventory.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <div className="mb-4">
                                <ResponsiveContainer width="100%" height={200}>
                                    <PieChart>
                                        <Pie
                                            data={[
                                                { name: 'In Stock', value: 65, color: '#28a745' },
                                                { name: 'Low Stock', value: 25, color: '#ffc107' },
                                                { name: 'Out of Stock', value: 10, color: '#dc3545' }
                                            ]}
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={60}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            paddingAngle={5}
                                            dataKey="value"
                                        >
                                            {data => data.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={entry.color} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                        <Legend />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                            <ListGroup variant="flush">
                                {[
                                    { label: t('pages.ecommerce.inventory.lowStock'), count: 12, status: 'warning' },
                                    { label: t('pages.ecommerce.inventory.outOfStock'), count: 4, status: 'danger' },
                                    { label: t('pages.ecommerce.inventory.toBeShipped'), count: 38, status: 'info' }
                                ].map((item, index) => (
                                    <ListGroup.Item key={index} className="d-flex justify-content-between align-items-center px-0">
                                        <div className="d-flex align-items-center">
                                            <div className={`bg-${item.status} rounded-circle me-2`} style={{ width: '8px', height: '8px' }} />
                                            <span>{item.label}</span>
                                        </div>
                                        <Badge bg={item.status} pill>
                                            {item.count}
                                        </Badge>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Recent Orders */}
            <Row className="mt-4">
                <Col xs={12}>
                    <Card>
                        <Card.Header className="d-flex justify-content-between align-items-center">
                            <h5 className="mb-0">{t('pages.ecommerce.orders.recentOrders')}</h5>
                            <div>
                                <Badge bg="success" className="me-2">
                                    {t('pages.ecommerce.orders.todayOrders')}: 24
                                </Badge>
                                <Button variant="outline-primary" size="sm">
                                    {t('pages.ecommerce.orders.viewAll')}
                                </Button>
                            </div>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <ListGroup variant="flush">
                                {[
                                    {
                                        id: '#ORD-001',
                                        customer: 'John Doe',
                                        items: 3,
                                        total: '$456.00',
                                        status: 'Processing',
                                        time: '2 hours ago',
                                        statusColor: 'warning'
                                    },
                                    {
                                        id: '#ORD-002',
                                        customer: 'Jane Smith',
                                        items: 1,
                                        total: '$125.00',
                                        status: 'Completed',
                                        time: '3 hours ago',
                                        statusColor: 'success'
                                    },
                                    {
                                        id: '#ORD-003',
                                        customer: 'Robert Johnson',
                                        items: 5,
                                        total: '$895.00',
                                        status: 'Pending',
                                        time: '5 hours ago',
                                        statusColor: 'info'
                                    }
                                ].map((order, index) => (
                                    <ListGroup.Item key={index} className="py-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-items-center">
                                                <div className={`bg-${order.statusColor} bg-opacity-10 p-2 rounded me-3`}>
                                                    <BagCheck className={`text-${order.statusColor}`} size={20} />
                                                </div>
                                                <div>
                                                    <div className="d-flex align-items-center">
                                                        <h6 className="mb-0 me-2">{order.id}</h6>
                                                        <Badge bg={order.statusColor} pill>
                                                            {order.status}
                                                        </Badge>
                                                    </div>
                                                    <small className="text-muted">
                                                        {order.customer} · {order.items} {t('pages.ecommerce.orders.items')}
                                                    </small>
                                                </div>
                                            </div>
                                            <div className="text-end">
                                                <h6 className="mb-1">{order.total}</h6>
                                                <small className="text-muted">{order.time}</small>
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* Shipping Analytics */}
            <Row className="mt-4">
                <Col lg={8}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('pages.ecommerce.shipping.analytics')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                {[
                                    { title: t('pages.ecommerce.shipping.delivered'), count: 856, change: '+12.5%', color: 'success' },
                                    { title: t('pages.ecommerce.shipping.inTransit'), count: 124, change: '+5.2%', color: 'info' },
                                    { title: t('pages.ecommerce.shipping.delayed'), count: 23, change: '-2.4%', color: 'danger' }
                                ].map((stat, index) => (
                                    <Col md={4} key={index}>
                                        <div className="text-center mb-4">
                                            <div className={`bg-${stat.color} bg-opacity-10 rounded-circle p-3 d-inline-block mb-3`}>
                                                <Truck className={`text-${stat.color}`} size={24} />
                                            </div>
                                            <h3 className="mb-1">{stat.count}</h3>
                                            <p className="mb-0 text-muted">{stat.title}</p>
                                            <small className={`text-${stat.change.includes('+') ? 'success' : 'danger'}`}>
                                                {stat.change}
                                            </small>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                            <ResponsiveContainer width="100%" height={300}>
                                <LineChart data={[
                                    { month: 'Jan', delivered: 450, inTransit: 87, delayed: 12 },
                                    { month: 'Feb', delivered: 520, inTransit: 95, delayed: 15 },
                                    { month: 'Mar', delivered: 480, inTransit: 110, delayed: 18 },
                                    { month: 'Apr', delivered: 580, inTransit: 125, delayed: 20 }
                                ]}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="month" />
                                    <YAxis />
                                    <Tooltip />
                                    <Legend />
                                    <Line type="monotone" dataKey="delivered" stroke="#28a745" />
                                    <Line type="monotone" dataKey="inTransit" stroke="#17a2b8" />
                                    <Line type="monotone" dataKey="delayed" stroke="#dc3545" />
                                </LineChart>
                            </ResponsiveContainer>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={4}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('pages.ecommerce.shipping.returnRequests')}</h5>
                        </Card.Header>
                        <Card.Body className="p-0">
                            <ListGroup variant="flush">
                                {[
                                    { id: 'RET-001', reason: 'Wrong Size', status: 'Pending', time: '2h ago' },
                                    { id: 'RET-002', reason: 'Damaged Item', status: 'Approved', time: '5h ago' },
                                    { id: 'RET-003', reason: 'Wrong Color', status: 'Processing', time: '1d ago' }
                                ].map((request, index) => (
                                    <ListGroup.Item key={index} className="py-3">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <h6 className="mb-1">{request.id}</h6>
                                                <small className="text-muted">{request.reason}</small>
                                            </div>
                                            <div className="text-end">
                                                <Badge bg={
                                                    request.status === 'Approved' ? 'success' :
                                                        request.status === 'Processing' ? 'info' : 'warning'
                                                } className="mb-2">
                                                    {request.status}
                                                </Badge>
                                                <br />
                                                <small className="text-muted">{request.time}</small>
                                            </div>
                                        </div>
                                    </ListGroup.Item>
                                ))}
                            </ListGroup>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default EcommerceDashboard;