import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
    CheckCircle,
    ExclamationTriangle,
    XCircle,
    InfoCircle,
    Bell
} from 'react-bootstrap-icons';

const Notifications = () => {
    const { t } = useTranslation();

    // Configuración base de las notificaciones
    const toastConfig = {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };

    // Función para mostrar notificación básica
    const showBasicToast = () => {
        toast('🚀 ¡Notificación básica!', toastConfig);
    };

    // Función para mostrar notificación de éxito
    const showSuccessToast = () => {
        toast.success(
            <div className="d-flex">
                <CheckCircle className="me-2 flex-shrink-0" />
                <div>
                    <h6 className="mb-0 fw-bold">¡Operación Exitosa!</h6>
                    <p className="mb-0">Los cambios han sido guardados correctamente.</p>
                </div>
            </div>,
            toastConfig
        );
    };

    // Función para mostrar notificación de error
    const showErrorToast = () => {
        toast.error(
            <div className="d-flex">
                <XCircle className="me-2 flex-shrink-0" />
                <div>
                    <h6 className="mb-0 fw-bold">Error</h6>
                    <p className="mb-0">No se pudo completar la operación.</p>
                </div>
            </div>,
            toastConfig
        );
    };

    // Función para mostrar notificación de advertencia
    const showWarningToast = () => {
        toast.warning(
            <div className="d-flex">
                <ExclamationTriangle className="me-2 flex-shrink-0" />
                <div>
                    <h6 className="mb-0 fw-bold">Advertencia</h6>
                    <p className="mb-0">Espacio de almacenamiento bajo.</p>
                </div>
            </div>,
            toastConfig
        );
    };

    // Función para mostrar notificación de info
    const showInfoToast = () => {
        toast.info(
            <div className="d-flex">
                <InfoCircle className="me-2 flex-shrink-0" />
                <div>
                    <h6 className="mb-0 fw-bold">Información</h6>
                    <p className="mb-0">Nueva actualización disponible.</p>
                </div>
            </div>,
            toastConfig
        );
    };

    // Función para mostrar notificación con progreso
    const showProgressToast = () => {
        const toastId = toast.loading(
            <div className="d-flex">
                <div className="spinner-border spinner-border-sm me-2" role="status">
                    <span className="visually-hidden">Cargando...</span>
                </div>
                <div>
                    <h6 className="mb-0 fw-bold">Procesando</h6>
                    <p className="mb-0">Por favor espere...</p>
                </div>
            </div>,
            { ...toastConfig, autoClose: false }
        );

        // Simular proceso
        setTimeout(() => {
            toast.update(toastId, {
                render:
                    <div className="d-flex">
                        <CheckCircle className="me-2 flex-shrink-0" />
                        <div>
                            <h6 className="mb-0 fw-bold">¡Completado!</h6>
                            <p className="mb-0">Proceso finalizado con éxito.</p>
                        </div>
                    </div>,
                type: "success",
                isLoading: false,
                autoClose: 5000
            });
        }, 3000);
    };

    // Función para mostrar notificación con acciones
    const showActionToast = () => {
        toast(
            <div className="d-flex flex-column">
                <div className="d-flex mb-2">
                    <Bell className="me-2 flex-shrink-0" />
                    <div>
                        <h6 className="mb-0 fw-bold">Nueva solicitud</h6>
                        <p className="mb-2">Juan ha solicitado acceso al proyecto.</p>
                    </div>
                </div>
                <div className="d-flex gap-2">
                    <Button size="sm" variant="success" className="flex-grow-1">
                        Aprobar
                    </Button>
                    <Button size="sm" variant="danger" className="flex-grow-1">
                        Rechazar
                    </Button>
                </div>
            </div>,
            { ...toastConfig, autoClose: false }
        );
    };

    return (
        <Container fluid className="py-4">
            <Row className="g-4">
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">Notificaciones Básicas</h5>
                        </Card.Header>
                        <Card.Body>
                            <div className="d-grid gap-2">
                                <Button variant="primary" onClick={showBasicToast}>
                                    Notificación Básica
                                </Button>
                                <Button variant="success" onClick={showSuccessToast}>
                                    <CheckCircle className="me-2" />
                                    Notificación de Éxito
                                </Button>
                                <Button variant="danger" onClick={showErrorToast}>
                                    <XCircle className="me-2" />
                                    Notificación de Error
                                </Button>
                                <Button variant="warning" onClick={showWarningToast}>
                                    <ExclamationTriangle className="me-2" />
                                    Notificación de Advertencia
                                </Button>
                                <Button variant="info" onClick={showInfoToast}>
                                    <InfoCircle className="me-2" />
                                    Notificación de Info
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>

                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">Notificaciones Avanzadas</h5>
                        </Card.Header>
                        <Card.Body>
                            <div className="d-grid gap-2">
                                <Button variant="primary" onClick={showProgressToast}>
                                    Notificación con Progreso
                                </Button>
                                <Button variant="primary" onClick={showActionToast}>
                                    Notificación con Acciones
                                </Button>
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Notifications;