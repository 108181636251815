import React, { useState } from 'react';
import { Container, Row, Col, Card, Form, Button, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    Person,
    Envelope,
    Lock,
    Eye,
    EyeSlash,
    Calendar3,
    Search,
    TelephoneFill,
    GeoAlt
} from 'react-bootstrap-icons';

const BasicForms = () => {
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);

    return (
        <Container fluid className="py-4">
            <Row className="g-4">
                {/* Inputs Básicos */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('forms.basic.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <Row className="g-3">
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>{t('forms.basic.firstName')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={t('forms.basic.firstNamePlaceholder')}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>{t('forms.basic.lastName')}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder={t('forms.basic.lastNamePlaceholder')}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group>
                                            <Form.Label>{t('forms.basic.email')}</Form.Label>
                                            <Form.Control
                                                type="email"
                                                placeholder={t('forms.basic.emailPlaceholder')}
                                            />
                                            <Form.Text className="text-muted">
                                                {t('forms.basic.emailHelp')}
                                            </Form.Text>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Input Groups */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('forms.inputGroups.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <Row className="g-3">
                                    <Col md={12}>
                                        <Form.Group>
                                            <Form.Label>{t('forms.inputGroups.username')}</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <Person />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={t('forms.inputGroups.usernamePlaceholder')}
                                                />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group>
                                            <Form.Label>{t('forms.inputGroups.password')}</Form.Label>
                                            <InputGroup>
                                                <InputGroup.Text>
                                                    <Lock />
                                                </InputGroup.Text>
                                                <Form.Control
                                                    type={showPassword ? "text" : "password"}
                                                    placeholder={t('forms.inputGroups.passwordPlaceholder')}
                                                />
                                                <Button
                                                    variant="outline-secondary"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                >
                                                    {showPassword ? <EyeSlash /> : <Eye />}
                                                </Button>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group>
                                            <Form.Label>{t('forms.inputGroups.search')}</Form.Label>
                                            <InputGroup>
                                                <Form.Control
                                                    type="text"
                                                    placeholder={t('forms.inputGroups.searchPlaceholder')}
                                                />
                                                <Button variant="primary">
                                                    <Search />
                                                </Button>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Selects y Radios */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('forms.selectsRadios.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <Row className="g-3">
                                    <Col md={12}>
                                        <Form.Group>
                                            <Form.Label>{t('forms.selectsRadios.country')}</Form.Label>
                                            <Form.Select>
                                                <option>{t('forms.selectsRadios.selectCountry')}</option>
                                                <option value="us">United States</option>
                                                <option value="uk">United Kingdom</option>
                                                <option value="ca">Canada</option>
                                                <option value="au">Australia</option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Label>{t('forms.selectsRadios.gender')}</Form.Label>
                                        <div className="d-flex gap-4">
                                            <Form.Check
                                                type="radio"
                                                name="gender"
                                                label={t('forms.selectsRadios.male')}
                                                id="gender-male"
                                            />
                                            <Form.Check
                                                type="radio"
                                                name="gender"
                                                label={t('forms.selectsRadios.female')}
                                                id="gender-female"
                                            />
                                            <Form.Check
                                                type="radio"
                                                name="gender"
                                                label={t('forms.selectsRadios.other')}
                                                id="gender-other"
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Checkboxes y Switches */}
                <Col md={6}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('forms.checkboxes.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <Row className="g-3">
                                    <Col md={12}>
                                        <Form.Group>
                                            <Form.Label>{t('forms.checkboxes.interests')}</Form.Label>
                                            <div className="d-flex flex-column gap-2">
                                                <Form.Check
                                                    type="checkbox"
                                                    label={t('forms.checkboxes.sports')}
                                                    id="interest-sports"
                                                />
                                                <Form.Check
                                                    type="checkbox"
                                                    label={t('forms.checkboxes.music')}
                                                    id="interest-music"
                                                />
                                                <Form.Check
                                                    type="checkbox"
                                                    label={t('forms.checkboxes.reading')}
                                                    id="interest-reading"
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group>
                                            <Form.Label>{t('forms.checkboxes.preferences')}</Form.Label>
                                            <div className="d-flex flex-column gap-2">
                                                <Form.Check
                                                    type="switch"
                                                    label={t('forms.checkboxes.notifications')}
                                                    id="pref-notifications"
                                                />
                                                <Form.Check
                                                    type="switch"
                                                    label={t('forms.checkboxes.newsletter')}
                                                    id="pref-newsletter"
                                                />
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>

                {/* Textarea y Rangos */}
                <Col md={12}>
                    <Card>
                        <Card.Header>
                            <h5 className="mb-0">{t('forms.textareaRange.title')}</h5>
                        </Card.Header>
                        <Card.Body>
                            <Form>
                                <Row className="g-3">
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>{t('forms.textareaRange.description')}</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={4}
                                                placeholder={t('forms.textareaRange.descriptionPlaceholder')}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>
                                                {t('forms.textareaRange.experience')}
                                                <span className="ms-2 text-muted">50%</span>
                                            </Form.Label>
                                            <Form.Range defaultValue={50} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default BasicForms;